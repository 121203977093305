import { observable, makeObservable, action, computed, toJS } from "mobx";
import axios from "axios";
import { EncryptStorage } from "encrypt-storage";
import visa from "../assets/images/visa.svg";
import mastercard from "../assets/images/mastercard.svg";
import amex from "../assets/images/amex.svg";
import applepay from "../assets/images/apple-pay.svg";
import discover from "../assets/images/discover.svg";
import bank from "../assets/images/bank.svg";
import jcb from "../assets/images/jcb.svg";
import payabli from "../assets/images/payabli.png";
import * as Sentry from "@sentry/react";

const FileDownload = require("js-file-download");

class MainStore {
  constructor() {
    makeObservable(this);
  }

  entryPoint = "";

  @observable
  paymentResponse = null;

  @observable
  paymentResponseData = null;

  @observable
  defaultPaymentMethodActiveKey = "1";

  @observable
  switchAutopay = false;

  @observable
  credentials = [];

  @observable
  pPsr = null;

  @observable
  validationCode = null;

  @observable
  validationCodeInput = "";

  @observable
  currency = "USD";

  @observable
  sendApprovedReceipt = true;

  @observable
  sendDeclinedReceipt = true;

  @observable
  savePaymentMethodForFuture = true;

  @observable
  settingsWallet = {
    applePay: false,
  };

  validators = {
    isEmpty: function (value) {
      if (value === "" || value === null || value === undefined) {
        return true;
      } else {
        return false;
      }
    },

    isMaxLength: function (length, value) {
      if (value) {
        if (value.length > length) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    isMinLength: function (length, value) {
      if (value) {
        if (value.length >= length) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },

    stringValidator: function (validation, value) {
      if (!value) {
        return false;
      }
      switch (validation) {
        case "alpha":
          return !/^[a-zA-Z\s]*$/.test(value);
        case "alphanumericspaces":
          return !/^[a-zA-Z0-9 ]*$/.test(value);
        case "numbers":
          return !/^[0-9]*$/.test(value);
        case "text":
          return false;
        case "address":
          return false;
        case "email":
          return !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value.toLowerCase()
          );
        case "phone":
          return !/^([0-9]{10,10})$/.test(value);
        case "card":
          return !/^([0-9]{15,16})$/.test(value);
        case "routing":
          return !/^([0-9]{9,9})$/.test(value);
        case "exp":
          return !/^([0-9]{4,4})$/.test(value);
        case "cvvamex":
          return !/^([0-9]{4,4})$/.test(value);
        case "cvv":
          return !/^([0-9]{3,3})$/.test(value);
        case "zipcode":
          return !/^(?=.{0,7}$)([A-Za-z0-9])+ ?([A-Za-z0-9]){0,3}$/g.test(
            value
          );
        default:
          return String;
      }
    },
  };

  maskValidator(validation) {
    switch (validation) {
      case "alpha":
        return /^[a-zA-Z\s]*$/;
      case "numbers":
        return Number;
      case "alphanumeric":
        return /^[a-z0-9]+$/i;
      case "alphanumericspaces":
        return /^[a-zA-Z0-9 ]+$/i;
      case "text":
        return String;
      case "email":
        return /^([a-zA-Z0-9][-._]?)+@?([a-zA-Z0-9][-._]?)*(\.)?([a-zA-Z]*)$/;
      case "phone":
        return "(000) 000-0000";
      case "routing":
        return "000000000";
      case "accountnumber":
        return /^[0-9]+$/i;
      case "cardDate":
        return "00/00";
      case "cvv":
        return "000";
      case "cvvamex":
        return "0000";
      case "zipcode":
        return /^(?=.{0,7}$)([A-Za-z0-9])+ ?([A-Za-z0-9]){0,3}$/g;
      case "creditCard":
        return "0000 0000 0000 0000";
      case "creditCardAmex":
        return "0000 000000 00000";
      default:
        return String;
    }
  }

  @observable
  paymentMethod = "ach";

  @observable
  paymentPageErrors = {
    autopayStartDateError: false,
    autopayFrequencyError: false,
    autopayFinishError: false,
    notesError: false,

    paymentMethodsAchAccountHolderNameError: false,
    paymentMethodsAchAccountTypeError: false,
    paymentMethodsAchRoutingError: false,
    paymentMethodsAchAccountError: false,

    paymentMethodsCardNumberError: false,
    paymentMethodsCardExpirationDateError: false,
    paymentMethodsCardCvvError: false,
    paymentMethodsCardZipcodeError: false,
    paymentMethodsCardHolderNameError: false,

    credentialsMaxMinTicketError: false,
  };

  @observable
  loading = false;

  @observable
  billingAddressShort = null;

  @observable
  shippingAddressShort = null;

  @observable
  paymentPage = {
    subdomain: "",
    name: "",
    logo: {
      enabled: true,
      order: 0,
    },
    page: {
      header: "",
      description: "",
      enabled: true,
      order: 1,
    },
    amount: {
      categories: [],
      enabled: true,
      order: 2,
    },
    autopay: {
      header: "",
      finish: {
        untilCancelled: true,
        calendar: false,
      },
      frequency: {
        weekly: false,
        every2Weeks: false,
        every6Months: false,
        monthly: false,
        every3Months: false,
        annually: false,
      },
      startDate: "",
      frequencySelected: "",
      finishSelected: "",
      enabled: true,
      order: 3,
    },
    payor: {
      header: "",
      enabled: true,
      order: 4,
      fields: [
        {
          name: "companyName",
          required: true,
          label: "Company Name",
          validation: "alpha",
          display: true,
          Fixed: true,
          order: 0,
          width: 12,
          identifier: false,
          value: "",
        },
        {
          name: "firstName",
          required: true,
          label: "First Name",
          validation: "alpha",
          display: true,
          Fixed: false,
          order: 1,
          width: 6,
          identifier: false,
          value: "",
        },
        {
          name: "lastName",
          required: true,
          label: "Last Name",
          validation: "alpha",
          display: true,
          Fixed: false,
          order: 2,
          width: 6,
          identifier: false,
          value: "",
        },
        {
          name: "email",
          required: true,
          label: "Email",
          validation: "email",
          display: true,
          order: 3,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
        },
        {
          name: "primaryPhone",
          required: true,
          label: "Primary Phone",
          validation: "phone",
          display: true,
          order: 4,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
        },
        {
          name: "customerNumber",
          required: false,
          label: "Customer #",
          validation: "numbers",
          display: true,
          order: 5,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
        },
        {
          name: "invoiceNumber",
          required: true,
          label: "Invoice #",
          validation: "numbers",
          display: true,
          order: 5,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
        },
        {
          name: "billingAddress1",
          required: true,
          label: "Billing Address",
          validation: "address",
          display: true,
          order: 6,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
          fixedValidation: true,
        },
        {
          name: "billingAddress2",
          required: true,
          label: "Billing Address 2",
          validation: "text",
          display: false,
          onlyData: true,
          order: 6,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
        },
        {
          name: "billingCity",
          required: true,
          label: "Billing City",
          validation: "text",
          display: false,
          onlyData: true,
          order: 6,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
        },

        {
          name: "billingState",
          required: true,
          label: "Billing State",
          validation: "text",
          display: false,
          onlyData: true,
          order: 6,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
        },
        {
          name: "billingZip",
          required: true,
          label: "Billing Zip",
          validation: "text",
          display: false,
          onlyData: true,
          order: 6,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
        },
        {
          name: "billingCountry",
          required: true,
          label: "Billing Country",
          validation: "text",
          display: false,
          onlyData: true,
          order: 6,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
        },

        {
          name: "shippingAddress1",
          required: true,
          label: "Shipping Address",
          validation: "address",
          display: true,
          order: 6,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
          fixedValidation: true,
        },
        {
          name: "shippingAddress2",
          required: true,
          label: "Shipping Address 2",
          validation: "text",
          display: false,
          onlyData: true,
          order: 6,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
        },
        {
          name: "shippingCity",
          required: true,
          label: "Shipping City",
          validation: "text",
          display: false,
          onlyData: true,
          order: 6,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
        },
        {
          name: "shippingState",
          required: true,
          label: "Shipping State",
          validation: "text",
          display: false,
          onlyData: true,
          order: 6,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
        },
        {
          name: "shippingZip",
          required: true,
          label: "Shipping Zip",
          validation: "text",
          display: false,
          onlyData: true,
          order: 6,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
        },
        {
          name: "shippingCountry",
          required: true,
          label: "Shipping Country",
          validation: "text",
          display: false,
          onlyData: true,
          order: 6,
          Fixed: false,
          width: 6,
          identifier: false,
          value: "",
        },
      ],
    },
    paymentMethods: {
      header: "",
      methods: {
        visa: true,
        mastercard: true,
        discover: true,
        amex: true,
        eCheck: true,
        applePay: true,
        googlePay: true,
        payPal: true,
        bitPay: true,
      },
      enabled: true,
      allMethodsChecked: true,

      achAccountHolderName: "",
      achAccountType: "checking",
      achRouting: "",
      achAccount: "",

      cardNumber: "",
      cardExpirationDate: "",
      cardCvv: "",
      cardZipcode: "",

      order: 5,
    },
    notes: {
      header: "",
      placeholder: "",
      enabled: true,
      value: "",
      order: 6,
    },
    review: {
      header: "",
      enabled: true,
      order: 7,
    },
    messageBeforePaying: {
      label: "",
      enabled: true,
      order: 8,
    },
    paymentButton: {
      label: "Pay",
      order: 9,
    },
    contactUs: {
      header: "",
      emailLabel: "",
      phoneLabel: "",
      paymentIcons: true,
      enabled: true,
      order: 10,
    },
  };

  @observable
  applePayButton = {
    buttonType: "pay",
    buttonStyle: "black",
    language: "en-US",
  };

  @observable
  includeDomain = false;

  @observable
  paymentReceipt = {
    logo: {
      enabled: true,
      order: 0,
    },
    page: {
      header: "Payment Receipt",
      description: "",
      enabled: true,
      order: 1,
    },
    amount: {
      enabled: true,
      order: 2,
    },
    settings: {
      sendAuto: false,
      sendManual: true,
      enabled: true,
      order: 3,
      fields: [
        {
          name: "firstName",
          display: true,
          Fixed: false,
        },
        {
          name: "lastName",
          display: true,
          Fixed: false,
        },
        {
          name: "primaryPhone",
          display: true,
          Fixed: false,
        },
        {
          name: "invoiceNumber",
          display: true,
          Fixed: false,
        },
        {
          name: "miscCreated",
          display: true,
          Fixed: false,
        },
        {
          name: "notes",
          display: true,
          Fixed: false,
        },
      ],
    },
    details: {
      enabled: true,
      order: 4,
    },
    paymentInformation: {
      enabled: true,
      order: 5,
    },
    messageBeforeButton: {
      label: "",
      enabled: true,
      order: 6,
    },
    paymentButton: {
      enabled: true,
      order: 7,
      label: "Go back to Payment Page",
    },
    contactUs: {
      enabled: true,
      order: 8,
    },
  };

  maskedCardNumber(string, version) {
    if (!string) {
      return string;
    }

    switch (version) {
      case "v1":
        return string.replace("XXXXXX", "xxx xxxx xxxx ");
      case "v2":
        return string.replaceAll("X", "x");
      case "v3":
        return "**** **** ****  " + string.substr(string.length - 4);
      case "v4":
        return string.replace("XXXXXXXX", "xxx xxxx xxxx ");
      case "v5":
        return "****  " + string.substr(string.length - 4);
      default:
        return string;
    }
  }

  @observable
  paymentPageSettings = {
    color: "#10A0E3",
    paymentButton: {
      label: "Pay Now",
      size: "sm",
    },
    customCssUrl: "",
    language: "en",
    customUrl: "",
    redirectAfterApprove: false,
    redirectAfterApproveUrl: "",
    notificationEmail: "",
    allApprovedPaymentNotification: false,
    dailyBatchReportNotification: false,
    pageLogo: {
      furl: "",
    },
  };
  @observable
  isClickedPay = false;
  @action
  handleCategoryTextChangeMask(field, key, value) {
    this.paymentPage.amount.categories[field][key] = value;
  }

  @action
  setCurrentPage(page) {
    this.currentPage = page;
  }

  @action
  setPaymentResponse(response) {
    this.paymentResponse = response;
  }

  @action
  setPaymentResponseData(responseData) {
    this.paymentResponseData = responseData;
  }

  @action
  handleAutopayStartDate(date) {
    this.paymentPage.autopay.startDate = date;
  }

  @action
  handleAutopayCalendarEndDate(date) {
    this.paymentPage.autopay.finishSelected = date;
  }

  @action
  setIncludeDomain(include) {
    this.includeDomain = include;
  }

  @action
  setPaymentMethod(method) {
    this.paymentMethod = method;
  }

  @action
  setPaymentPage(paymentPage) {
    this.paymentPage = paymentPage;
  }

  @action
  setPaymentPageCategories(categories) {
    this.paymentPage.amount.categories = categories;
  }

  @action
  setPaymentSettings(pageSettings) {
    this.paymentPageSettings = pageSettings;
  }

  @action
  setPaymentPageError(field, value) {
    this.paymentPageErrors[field] = value;
  }
  @action
  setIsClickedPay(clicked) {
    this.isClickedPay = true;
  }
  @action
  setPaymentReceipt(paymentReceipt) {
    this.paymentReceipt = paymentReceipt;
  }

  @action
  setPaymentPageSubdomain(subdomain) {
    this.paymentPage.subdomain = subdomain;
  }

  @action
  setCredentials(credentials) {
    this.credentials = credentials;
  }

  @action
  setBillingAddressShort(billingAddressShort) {
    this.billingAddressShort = billingAddressShort;
  }

  @action
  setShippingAddressShort(shippingAddressShort) {
    this.shippingAddressShort = shippingAddressShort;
  }

  @action
  setIdentifier(pageId) {
    //let encryptStorage = EncryptStorage(process.env.REACT_APP_SECRET_KEY);
    //encryptStorage.setItem('pPsr', pageId);
    this.pPsr = pageId;
  }

  @action
  setValidationCode(validationCode) {
    this.validationCode = validationCode;
  }

  @action
  setValidationCodeInput(validationCode) {
    this.validationCodeInput = validationCode;
  }

  getValidationCode() {
    return this.validationCode;
  }

  getPageToken() {
    //let encryptStorage = EncryptStorage(process.env.REACT_APP_SECRET_KEY);
    //return encryptStorage.getItem('pPsr');
    return this.pPsr;
  }

  isAllowedPaymentMethod() {
    if (this.getPaymentMethod.toLowerCase() === "card") {
      let ccType = this.creditCardType(
        this.paymentPage.paymentMethods.cardNumber
      );
      ccType = ccType === "american-express" ? "amex" : ccType;
      if (
        this.paymentPage.paymentMethods.methods[ccType.toLowerCase()] === true
      ) {
        return true;
      } else {
        return false;
      }
    } else if (this.getPaymentMethod.toLowerCase() === "ach") {
      if (this.paymentPage.paymentMethods.methods.eCheck === true) {
        return true;
      } else {
        return false;
      }
    } else if (
      this.getPaymentMethod.toLowerCase() === "vcard" ||
      this.getPaymentMethod.toLowerCase() === "papercheck" ||
      this.getPaymentMethod.toLowerCase() === "digitalcheck" ||
      this.getPaymentMethod.toLowerCase() === "applepay"
    ) {
      return true;
    }

    return false;
  }

  @action
  hasPaymentPageErrors() {
    var paymentPageErrors = Object.entries(toJS(this.paymentPageErrors));
    var errors = [];
    paymentPageErrors.forEach(function (item, index) {
      if (item[1] === true) {
        errors.push(item[0]);
      }
    });
    return errors.length > 0 ? true : false;
  }

  @action
  handleTextChange(block, key, event) {
    this.paymentPage[block][key] = event.target.value;
  }

  @action
  handleTextChangeACH(field, value) {
    this.paymentPage.paymentMethods[field] = value;
  }

  @action
  handleTextPayorFieldsChange(key, value) {
    this.paymentPage.payor.fields[key].value = value;
  }

  @action
  handleTextChangeValidationCode(value) {
    this.validationCodeText = value;
  }

  @action
  handleTextChangeValidationCodeInput(value) {
    const textValueWithoutSpaces =
      typeof value === "string" ? value.replace(/\s/g, "") : value;
    this.validationCodeInput = textValueWithoutSpaces;
  }

  @action
  setPayorValue(keyValue, value, fixedInjected) {
    let payorFields = this.paymentPage.payor.fields;
    for (let key in payorFields) {
      if (payorFields[key].name === keyValue) {
        payorFields[key].value = value;
        payorFields[key].fixedInjected = fixedInjected ? fixedInjected : false;
      }
    }
    this.paymentPage.payor.fields = payorFields;
  }

  @action
  setEntryPoint(entryPoint) {
    this.entryPoint = entryPoint;
  }

  @action
  getPaymentPageFromApi(subdomain, entrypoint) {
    this.entryPoint = entrypoint;
    this.setLoading(true);
    return axios
      .get(
        process.env.REACT_APP_URL_API +
          "Paypoint/load/" +
          this.entryPoint +
          "/" +
          subdomain,
        {
          headers: {
            requestToken: process.env.REACT_APP_TOKEN,
          },
        }
      )
      .then((res) => {
        this.setPaymentPage(res.data.responseData.PageContent);
        this.setPaymentSettings(res.data.responseData.PageSettings);
        this.setPaymentReceipt(res.data.responseData.ReceiptContent);
        this.setPaymentPageSubdomain(res.data.responseData.Subdomain);
        this.setCredentials(res.data.responseData.Credentials);
        this.setIdentifier(res.data.responseData.pageIdentifier);
        this.setValidationCode(res.data.responseData.validationCode);

        this.setSwitchAutopay(this.isAutopay);
        this.setLoading(false);

        this.normalizePayorFields();

        let applePayButton = {
          buttonType: res.data.responseData.AdditionalData.applepay_buttonType || 'pay',
          buttonStyle: res.data.responseData.AdditionalData.applepay_buttonStyle || 'black',
          language: res.data.responseData.AdditionalData.applepay_language || 'en-US',
        };
        if (!["pay", "check-out", "continue", "donate", "contribute", "support", "buy", "order", "rent", "book", "tip", "top-up", "plain"].includes(applePayButton.buttonType)) {
          applePayButton.buttonType = "pay";
        }
        this.setApplePayButton(applePayButton);
      })
      .catch((error) => {
        Sentry.captureException(error);
        this.setLoading(false);
        throw error;
      });
  }

  @action
  async checkDomain(entrypoint) {
    try {
    const paypointId = await this.getPaypointID(entrypoint);
    const {data} = await axios
      .get(
        process.env.REACT_APP_URL_API +
          "PaymentMethodDomain/list?entityId=" +
          paypointId +
          "&entityType=paypoint",
        {
          headers: {
            requestToken: this.getPageToken(),
          },
        }
      )
      const applePayDomains = data.Records.filter(
        (item) => item.applePay.isEnabled
      ).map((item) => item.domainName);

      if (applePayDomains.includes(window.location.host)) {
        this.setIncludeDomain(true);
      }
      const pageIdentifier = data.Summary.pageidentifier || ''
      this.setIdentifier(pageIdentifier)
      return applePayDomains
    } catch (error) {
      Sentry.captureException(error);
      console.log('Error checking domain', error);
    }
  }

  @action
  async checkInvoiceStatus() {
    try {
      let invoiceId =
        this.paymentPage.invoices &&
        this.paymentPage.invoices.invoiceList[0] &&
        this.paymentPage.invoices.invoiceList[0].invoiceId
          ? this.paymentPage.invoices.invoiceList[0].invoiceId
          : null;
        if (!invoiceId) {
          throw new Error("Invoice ID not found");
        }
        const {data} = await axios
          .get(
            process.env.REACT_APP_URL_API +
              "Invoice/" +
              invoiceId,
            {
              headers: {
                requestToken: this.getPageToken(),
              },
            }
          )
          const newTempToken = data.pageIdentifier
          this.setIdentifier(newTempToken)
        return data.invoiceStatus
    } catch (error) {
      return null
    }
  }

  @action
  getPaypointID(entrypoint) {
    return axios
      .get(process.env.REACT_APP_URL_API + "Paypoint/basic/" + entrypoint, {
        headers: {
          requestToken: process.env.REACT_APP_TOKEN,
        },
      })
      .then((res) => {
        return res.data.responseData.Paypoint.IdPaypoint;
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });
  }

  @action
  async handleRefreshValidationCode(subdomain, entrypoint) {
    try {
      this.handleTextChangeValidationCodeInput("");
      const res = await axios.get(
        `${process.env.REACT_APP_URL_API}Paypoint/getCaptcha/${entrypoint}/${subdomain}`,
        {
          headers: {
            requestToken: process.env.REACT_APP_TOKEN,
          },
        }
      );
      const validationCode = res?.data?.responseData?.validationCode;
      const pageidentifier = res?.data?.pageIdentifier;

      if (validationCode) {
        this.setValidationCode(validationCode);
      }
      if (pageidentifier) {
        this.setPaymentResponsePageIdentifier(pageidentifier);
      }
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  @action
  getPaymentPageLinkFromApi(tokenid, entrypoint, handleLoading) {
    this.entryPoint = entrypoint;
    this.setLoading(true);
    return axios
      .get(process.env.REACT_APP_URL_API + "PaymentLink/load/" + tokenid, {
        headers: {
          requestToken: process.env.REACT_APP_TOKEN,
        },
      })
      .then((res) => {
        this.setPaymentPage(res.data.responseData.PageContent);
        this.setPaymentSettings(res.data.responseData.PageSettings);
        this.setPaymentReceipt(res.data.responseData.ReceiptContent);
        this.setPaymentPageSubdomain(res.data.responseData.Subdomain);
        this.setCredentials(res.data.responseData.Credentials);
        this.setIdentifier(res.data.responseData.pageIdentifier);
        this.setValidationCode(res.data.responseData.validationCode);

        this.setSwitchAutopay(this.isAutopay);
        if (handleLoading !== true) {
          this.setLoading(false);
        }

        this.normalizePayorFields();
        return res;
      })
      .catch((error) => {
        Sentry.captureException(error);
        this.setLoading(false);
        throw error;
      });
  }

  getBillVendorAddress() {
    let address = [];

    if (this.paymentPage?.bills?.billList[0]?.vendorAddress1) {
      address.push(this.paymentPage.bills?.billList[0].vendorAddress1);
    }
    if (this.paymentPage?.bills?.billList[0]?.vendorCity) {
      address.push(this.paymentPage.bills.billList[0].vendorCity);
    }
    if (this.paymentPage?.bills?.billList[0]?.vendorState) {
      address.push(this.paymentPage.bills.billList[0].vendorState);
    }
    if (this.paymentPage?.bills?.billList[0]?.vendorZip) {
      address.push(this.paymentPage.bills.billList[0].vendorZip);
    }

    return address.join(" ");
  }

  normalizePayorFields() {
    this.payerFields?.forEach(function (item, index) {
      if (!item.validation || item.validation === "") {
        item.validation = "text";
      }
    });
  }

  async captureTransactionOut(transId, token) {
    return axios
    .get(
      process.env.REACT_APP_URL_API +
        "MoneyOut/capture/" + transId,
      {
        headers: {
          requestToken: token,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });
  }
  
  async sendVCardLink(transId, token) {
    return axios
    .get(
      process.env.REACT_APP_URL_API +
        "MoneyOut/vcard/send-card-link/" + transId,
      {
        headers: {
          requestToken: token,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      Sentry.captureException(error);
      throw error;
    });
  }


  async schedulePaymentMoneyOut(entry) {
    let billId = this.paymentPage?.bills?.billList[0]?.billId;
    let billComents = this.paymentPage?.bills?.billList[0]?.comments || '';
    let vendorId = this.paymentPage?.bills?.billList[0]?.vendorId;
    let paymentMethod = this.getPaymentMethod.toLowerCase();
    let paymentMethodObj = {};

    switch (paymentMethod) {
      case "vcard":
        paymentMethodObj = {
          method: "vcard",
        };
        break;
      case "digitalcheck":
        paymentMethodObj = {
          //pending DIGITAL CHECK
          method: "check",
        };
        break;
      case "papercheck":
        paymentMethodObj = {
          method: "check",
        };
        break;
      case "card":
        paymentMethodObj = {
          method: "card",
          cardnumber: this.paymentPage.paymentMethods.cardNumber,
          cardexp: this.paymentPage.paymentMethods.cardExpirationDate,
          cardcvv: this.paymentPage.paymentMethods.cardCvv,
          cardzip: this.paymentPage.paymentMethods.cardZipcode,
          cardHolder: this.paymentPage.paymentMethods.cardHolderName,
        };
        break;
      case "ach":
        paymentMethodObj = {
          method: "ach",
          achHolder: this.paymentPage.paymentMethods.achAccountHolderName,
          achRouting: this.paymentPage.paymentMethods.achRouting,
          achAccount: this.paymentPage.paymentMethods.achAccount,
          achAccountType: this.paymentPage.paymentMethods.achAccountType,
        };
        break;
      case "applepay":
        paymentMethodObj = {
          method: "applepay",
        };
        break;
      default:
        paymentMethodObj = null;
    }

    if (entry && billId && vendorId && paymentMethod && paymentMethodObj) {
      return axios
        .post(
          process.env.REACT_APP_URL_API +
            "MoneyOut/authorize?forceVendorCreation=false",
          {
            entryPoint: entry,
            orderDescription: billComents,
            invoiceData: [
              {
                billId: billId,
              },
            ],
            vendorData: {
              vendorId: vendorId,
            },
            paymentMethod: paymentMethodObj,
          },
          {
            headers: { requestToken: this.getPageToken() },
          }
        )
        .then((res) => {
          return res;
        })
        .catch((error) => {
          Sentry.captureException(error);
          throw error;
        });
    }
  }

  @action
  setLoading(status) {
    this.loading = status;
  }

  @action
  setSwitchAutopay(status) {
    this.switchAutopay = status;
  }

  @action
  handleAutopayFrequency(event) {
    this.paymentPage.autopay.frequencySelected = event.target.value;
  }

  @action
  handleAutopayEndDate(date) {
    this.paymentPage.autopay.finishSelected = date;
  }

  @action
  handleAutopayEndDateAction(value) {
    this.paymentPage.autopay.finishSelected = value;
  }

  getAutopayEndDate() {
    return this.paymentPage.autopay.finishSelected;
  }

  @action
  handleCategoryOptionalPay(event) {
    var category = event.target.id.replace("amountCategoryOptionalPay", "");
    this.paymentPage.amount.categories[category].optionalPayChecked =
      !this.paymentPage.amount.categories[category].optionalPayChecked;
  }

  @action
  handleCategoryQtySubstract(event) {
    var category = event.target.id.replace("amountCategoryQtySubstract", "");
    if (this.paymentPage.amount.categories[category].quantity > 1)
      this.paymentPage.amount.categories[category].quantity--;
  }

  @action
  handleCategoryQtyAdd(event) {
    var category = event.target.id.replace("amountCategoryQtyAdd", "");
    this.paymentPage.amount.categories[category].quantity++;
  }

  @action
  handleCategoryQtyInput(event) {
    var category = event.target.id.replace("amountCategoryQtyInput", "");
    event.target.value > 1
      ? (this.paymentPage.amount.categories[category].quantity =
          event.target.value)
      : (this.paymentPage.amount.categories[category].quantity = 1);
  }

  @action
  handleItemQtySubstract(event) {
    var position = event.target.id.replace("amountItemQtySubstract", "");
    if (this.paymentPage.invoices.invoiceList[0].items[position].itemQty > 1) {
      this.paymentPage.invoices.invoiceList[0].items[position].itemQty =
        this.paymentPage.invoices.invoiceList[0].items[position].itemQty - 1;
    }
    this.handleUpdateTotalAmount();
  }

  @action
  handleItemQtyAdd(event) {
    var position = event.target.id.replace("amountItemQtyAdd", "");
    this.paymentPage.invoices.invoiceList[0].items[position].itemQty =
      this.paymentPage.invoices.invoiceList[0].items[position].itemQty + 1;
    this.handleUpdateTotalAmount();
  }

  @action
  handleItemQtyInput(event) {
    var position = event.target.id.replace("amountItemQtyInput", "");
    event.target.value > 1
      ? (this.paymentPage.invoices.invoiceList[0].items[position].itemQty =
          event.target.value)
      : (this.paymentPage.invoices.invoiceList[0].items[position].itemQty = 1);
    this.handleUpdateTotalAmount();
  }

  @action
  handleInvoiceUpdateUpValueInput(event, position) {
    if (event.value > 0) {
      this.paymentPage.invoices.invoiceList[0].items[position].itemCost =
        event.value;
      this.handleUpdateTotalAmount();
    }
  }

  @action
  handleUpdateTotalAmount() {
    let itemTotalAmount = 0,
      items = this.paymentPage.invoices.invoiceList[0].items;

    items.forEach((data, idx) => {
      items[idx].itemTotalAmount = data.itemCost * data.itemQty;
      itemTotalAmount += data.itemCost * data.itemQty;
    });

    this.paymentPage.amount.categories[0].value =
      itemTotalAmount + this.totalAmount["fee"];
  }

  @action
  handleAutopay() {
    this.paymentPage.autopay.enabled = !this.paymentPage.autopay.enabled;
  }

  @action
  setApplePayButton(settings) {
    this.applePayButton = settings
  }

  getRequiredPayorFieldsToPay() {
    const customerId = this.paymentPage?.invoices?.invoiceList?.[0]?.customerId;

    if (customerId !== undefined) {
      return {
        customerId: customerId,
      };
    } else {
      let payorFields = Object.entries(toJS(this.payerFields));
      let payorFieldsReturn = [];
      payorFieldsReturn["additionalData"] = {};
      payorFields?.forEach(function (item, index) {
        if (item[1].name.indexOf("additional_") === 0) {
          payorFieldsReturn["additionalData"][
            item[1].name.replace("additional_", "")
          ] = item[1].value;
        } else {
          payorFieldsReturn[item[1].name] = item[1].value;
        }
      });

      // To avoid long address and declined by processor
      if (payorFieldsReturn.billingAddress1) {
        payorFieldsReturn.billingAddress1 = this.billingAddressShort;
      }
      if (payorFieldsReturn.shippingAddress1) {
        payorFieldsReturn.shippingAddress1 = this.shippingAddressShort;
      }

      return Object.assign({}, payorFieldsReturn);
    }
  }

  getInvoiceData() {
    let invoiceNumber = null;
    if (this.paymentPage.invoices) {
      invoiceNumber =
        this.paymentPage.invoices &&
        this.paymentPage.invoices.invoiceList[0] &&
        this.paymentPage.invoices.invoiceList[0].invoiceNumber
          ? this.paymentPage.invoices.invoiceList[0].invoiceNumber
          : null;
    } else {
      let payorFields = Object.entries(toJS(this.payerFields));
      payorFields?.forEach(function (item, index) {
        if (item[1].name === "invoiceNumber") {
          invoiceNumber = item[1].value;
        }
      });
    }

    return invoiceNumber ? { invoiceNumber: invoiceNumber } : null;
  }

  getCategoriesFieldsToPay() {
    var categoryFields = Object.entries(toJS(this.amountCategories));
    var categoryFieldsReturn = [];
    categoryFields.forEach(function (item, index) {
      categoryFieldsReturn.push({
        amount: parseFloat(item[1].value),
        label: item[1].label,
        qty: parseInt(item[1].quantity),
      });
    });
    return categoryFieldsReturn;
  }

  luhnCheck(ccNum) {
    let arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
    let len = ccNum.length,
      bit = 1,
      sum = 0,
      val;

    while (len) {
      val = parseInt(ccNum.charAt(--len), 10);
      bit ^= 1;
      sum += bit ? arr[val] : val;
    }

    return sum && sum % 10 === 0;
  }

  isValidRouting(routing) {
    if (routing.length !== 9) {
      return false;
    }
    let checksumTotal =
      3 * parseInt(routing.charAt(0), 10) +
      7 * parseInt(routing.charAt(1), 10) +
      1 * parseInt(routing.charAt(2), 10) +
      3 * parseInt(routing.charAt(3), 10) +
      7 * parseInt(routing.charAt(4), 10) +
      1 * parseInt(routing.charAt(5), 10) +
      3 * parseInt(routing.charAt(6), 10) +
      7 * parseInt(routing.charAt(7), 10) +
      1 * parseInt(routing.charAt(8), 10);
    return checksumTotal % 10 === 0;
  }

  creditCardType(number) {
    if (!number || number === "") {
      return "unknown";
    }

    if (number.length > 14) {
      if (this.luhnCheck(number) === false) {
        return "unknown";
      }
    }

    let creditCardType = require("credit-card-type");
    let visaCards = creditCardType(number);
    if (visaCards[0]) {
      return visaCards[0].type;
    } else {
      return "unknown";
    }
  }

  getAutopayFinishString() {
    var date = this.paymentPage.autopay.finishSelected;
    if (typeof date === "string" || date instanceof String) {
      return date;
    } else {
      return this.dateStringFormat(date);
    }
  }

  dateStringFormat(date) {
    try {
      if (!(date instanceof Date)) {
        date = new Date(date);
      }
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : "0" + month;
      let day = date.getDate().toString();
      day = day.length > 1 ? day : "0" + day;
      return month + "/" + day + "/" + year;
    } catch (e) {
      return date;
    }
  }

  stringDateFormat(string) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var date = new Date(string);
    return (
      monthNames[date.getMonth()] +
      " " +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      ", " +
      date.getFullYear()
    );
  }

  getMonths() {
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  }

  stringTimeFormat(string) {
    var date = new Date(string);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  getPaymethodImg(method) {
    if (!method) {
      return (
        <img title="Checking" style={{ height: "19px" }} alt="" src={bank} />
      );
    }
    switch (method.toLowerCase()) {
      case "visa":
        return (
          <img title="Visa" style={{ height: "11px" }} alt="" src={visa} />
        );
      case "mastercard":
        return (
          <img
            title="MasterCard"
            style={{ height: "17px" }}
            alt=""
            src={mastercard}
          />
        );
      case "discover":
        return (
          <img
            title="Discover"
            style={{ height: "14px" }}
            alt=""
            src={discover}
          />
        );
      case "jcb":
        return <img title="JCB" style={{ height: "17px" }} alt="" src={jcb} />;
      case "amex":
      case "american-express":
        return (
          <img
            title="American Express"
            style={{ height: "17px" }}
            alt=""
            src={amex}
          />
        );
      case "applepay":
        return (
          <img
            title="Apple Pay"
            style={{ height: "17px" }}
            alt=""
            src={applepay}
          />
        );
      default:
        return (
          <img title="Checking" style={{ height: "19px" }} alt="" src={bank} />
        );
    }
  }

  @action
  setCurrency(value) {
    this.currency = value;
  }

  @action
  async getSettingsCurrencyPaypoint() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_API}Paypoint/settings/${this.entryPoint}`,
        {
          headers: {
            requestToken: process.env.REACT_APP_TOKEN,
          },
        }
      );
      let currency = "";
      let thisObj = this;
      res.data.general.map(function (item) {
        if (item.key === "currency") {
          currency = item.value;
          return false;
        }
        if (item.key === "sendApprovedReceipt" && item.value !== "true") {
          thisObj.setSendApprovedReceipt(false);
        }

        if (item.key === "sendDeclinedReceipt" && item.value !== "true") {
          thisObj.setSendDeclinedReceipt(false);
        }
        return false;
      });
      this.setCurrency(currency);

      //wallets
      let isApplePayEnabled = false;
      if (Array.isArray(res.data.forWallets)) {
        res.data.forWallets.forEach(function (item) {
          if (item.key === "isApplePayEnabled") {
            isApplePayEnabled = item.value === "true" ? true : false;
            thisObj.settingsWallet.applePay = isApplePayEnabled;
          }
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  @action
  async getSettingsPaypoint() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL_API}Paypoint/settings/${this.entryPoint}`,
        {
          headers: {
            requestToken: process.env.REACT_APP_TOKEN,
          },
        }
      );
      return res.data;
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  @action
  setSendApprovedReceipt(value) {
    this.sendApprovedReceipt = value;
  }

  @action
  setSendDeclinedReceipt(value) {
    this.sendDeclinedReceipt = value;
  }

  @action
  async makePayment(entry) {
    if (entry) {
      this.setEntryPoint(entry);
    }
    this.setLoading(true);
    await this.getSettingsCurrencyPaypoint();

    if (this.isAutopay) {
      if (this.getPaymentMethod.toLowerCase() === "card") {
        return axios
          .post(
            process.env.REACT_APP_URL_API + "Subscription/add",
            this.paymentCardDataRecurring,
            {
              headers: this.validationCode
                ? {
                    requestToken: this.getPageToken(),
                    validationCode: this.getValidationCodeInput,
                  }
                : { requestToken: this.getPageToken() },
            }
          )
          .then((response) => {
            this.setPaymentResponse(response.data.responseText);
            this.setPaymentResponseData(response.data.responseData);
            this.sendReceipt(response.data);

            this.setLoading(false);
          })
          .catch((reason) => {
            Sentry.captureException(reason);
            this.setLoading(false);
            throw reason;
          });
      } else if (this.getPaymentMethod.toLowerCase() === "ach") {
        return axios
          .post(
            process.env.REACT_APP_URL_API + "Subscription/add",
            this.paymentACHDataRecurring,
            {
              headers: this.validationCode
                ? {
                    requestToken: this.getPageToken(),
                    validationCode: this.getValidationCodeInput,
                  }
                : { requestToken: this.getPageToken() },
            }
          )
          .then((response) => {
            this.setPaymentResponse(response.data.responseText);
            this.setPaymentResponseData(response.data.responseData);
            this.sendReceipt(response.data);
            this.setLoading(false);
          })
          .catch((reason) => {
            Sentry.captureException(reason);
            this.setLoading(false);
            throw reason;
          });
      }
    } else {
      if (this.getPaymentMethod.toLowerCase() === "card") {
        return axios
          .post(
            process.env.REACT_APP_URL_API + "MoneyIn/getpaid",
            this.paymentCardData,
            {
              headers: this.validationCode
                ? {
                    requestToken: this.getPageToken(),
                    validationCode: this.getValidationCodeInput,
                  }
                : { requestToken: this.getPageToken() },
              params: {
                async: false,
              },
            }
          )
          .then((response) => {
            this.setPaymentResponse(response.data.responseData);
            this.setPaymentResponseData(response.data.responseData);
            this.sendReceipt(response.data);
            this.setLoading(false);
          })
          .catch((reason) => {
            Sentry.captureException(reason);
            this.setLoading(false);
            throw reason;
          });
      } else if (this.getPaymentMethod.toLowerCase() === "ach") {
        return axios
          .post(
            process.env.REACT_APP_URL_API + "MoneyIn/getpaid",
            this.paymentACHData,
            {
              headers: this.validationCode
                ? {
                    requestToken: this.getPageToken(),
                    validationCode: this.getValidationCodeInput,
                  }
                : { requestToken: this.getPageToken() },
              params: {
                async: false,
              },
            }
          )
          .then((response) => {
            this.setPaymentResponse(response.data.responseData);
            this.setPaymentResponseData(response.data.responseData);
            this.sendReceipt(response.data);
            this.setLoading(false);
          })
          .catch((reason) => {
            Sentry.captureException(reason);
            this.setLoading(false);
            throw reason;
          });
      }
    }
  }

  sendReceipt(data) {
    if (
      data.pageIdentifier &&
      data.responseData &&
      data.responseData.referenceId
    ) {
      if (
        data.responseData.resultCode === 1 &&
        this.sendApprovedReceipt !== true
      ) {
        this.setPaymentResponsePageIdentifier(data.pageIdentifier);
        return;
      }

      if (
        data.responseData.resultCode !== 1 &&
        this.sendDeclinedReceipt !== true
      ) {
        this.setPaymentResponsePageIdentifier(data.pageIdentifier);
        return;
      }

      return axios
        .get(
          process.env.REACT_APP_URL_API +
            "MoneyIn/sendreceipt/" +
            data.responseData.referenceId,
          {
            headers: {
              requestToken: data.pageIdentifier,
            },
          }
        )
        .then((response) => {
          if (response.data.pageIdentifier) {
            this.setPaymentResponsePageIdentifier(response.data.pageIdentifier);
            /*if(!this.isAutopay && this.savePaymentMethodForFuture && data.responseData.customerId){
							if(this.getPaymentMethod.toLowerCase() === 'ach'){
								this.addPaymentMethod(data.responseData.customerId, this.paymentACHData);
							}else if(this.getPaymentMethod.toLowerCase() === 'card'){
								this.addPaymentMethod(data.responseData.customerId, this.paymentCardData);
							}	
						}*/
          }
        })
        .catch((error) => {
          this.setPaymentResponsePageIdentifier(
            error?.response?.data?.pageIdentifier
          );

          Sentry.captureException(error);
          throw error;
        });
    }
  }

  downloadInvoice() {
    let invoiceId =
      this.paymentPage.invoices &&
      this.paymentPage.invoices.invoiceList[0] &&
      this.paymentPage.invoices.invoiceList[0].invoiceId
        ? this.paymentPage.invoices.invoiceList[0].invoiceId
        : null;
    if (invoiceId) {
      axios
        .get(process.env.REACT_APP_URL_API + "Export/invoicePdf/" + invoiceId, {
          responseType: "blob",
          headers: {
            requestToken: this.getPageToken(),
          },
        })
        .then((res) => {
          FileDownload(res.data, "Invoice.pdf");
        })
        .catch((error) => {
          Sentry.captureException(error);
          throw error;
        });
    }
  }

  capitalizeFirstLetter(string) {
    if (typeof string === "string" && string !== "") {
      string = string.toLowerCase();
      return string[0].toUpperCase() + string.slice(1);
    }
    return "";
  }

  setPaymentResponsePageIdentifier(pageIdentifier) {
    if (pageIdentifier) {
      this.setIdentifier(pageIdentifier);
    }
  }

  @action
  setDefaultPaymentMethodActiveKey(key) {
    this.defaultPaymentMethodActiveKey = key;
  }

  @action
  setSavePaymentMethodForFuture(value) {
    this.savePaymentMethodForFuture = value;
  }

  numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  @action
  formatDecimalDynamics(x) {
    let dec = 2;
    if (Math.floor(x) !== x && x.toString().includes(".")) {
      let temp = x.toString().split(".")[1].length;
      if (temp > 4) {
        dec = 4;
      } else if (temp > 2) {
        dec = temp;
      }
    }
    return dec;
  }

  @action
  addPaymentMethod(customerId, paymentData) {
    let payorData = { customerId: parseInt(customerId) };
    return axios
      .post(
        process.env.REACT_APP_URL_API + "TokenStorage/add",
        {
          paymentMethod: paymentData.paymentMethod,
          customerData: payorData,
          entryPoint: this.entryPoint,
        },
        {
          headers: { requestToken: this.getPageToken() },
        }
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        Sentry.captureException(error);
        throw error;
      });
  }

  @computed
  get getValidationCodeInput() {
    return this.validationCodeInput;
  }

  @computed
  get amountCategories() {
    return this.paymentPage.amount.categories;
  }

  @computed
  get payerFields() {
    return this.paymentPage.payor.fields ?? [];
  }

  @computed
  get getPaymentMethod() {
    return this.paymentMethod;
  }

  @computed
  get getPaymentPageSubdomain() {
    return this.paymentPage.subdomain;
  }

  @computed
  get getPaymentPageEntry() {
    return this.entryPoint;
  }

  @computed
  get getApplePayButtonType() {
    return this.applePayButton;
  }

  @computed
  get isLoading() {
    return this.loading;
  }

  @computed
  get hasCards() {
    return (
      this.paymentPage.paymentMethods.methods.visa ||
      this.paymentPage.paymentMethods.methods.mastercard ||
      this.paymentPage.paymentMethods.methods.discover ||
      this.paymentPage.paymentMethods.methods.amex
    );
  }

  @computed
  get hasECheck() {
    return this.paymentPage.paymentMethods.methods.eCheck;
  }

  @computed
  get hasApplePay() {
    return (
      this.settingsWallet.applePay &&
      this.paymentPage.paymentMethods.methods.applePay
    );
  }

  @computed
  get getPaymentPageErrors() {
    return this.paymentPageErrors;
  }

  @computed
  get isAutopay() {
    return this.paymentPage.autopay.enabled;
  }

  @action
  setAutopay(value) {
    this.paymentPage.autopay.enabled = value;
  }

  @action
  setEcheckActive(value) {
    this.paymentPage.paymentMethods.methods.eCheck = value;
  }

  @action
  setApplePayActive(value) {
    this.paymentPage.paymentMethods.methods.applePay = value;
  }

  @action
  setCardsActive(value) {
    this.paymentPage.paymentMethods.methods.visa = value;
    this.paymentPage.paymentMethods.methods.mastercard = value;
    this.paymentPage.paymentMethods.methods.discover = value;
    this.paymentPage.paymentMethods.methods.amex = value;
  }

  @computed
  get hasCCError() {
    return (
      this.paymentPageErrors.paymentMethodsCardNumberError ||
      this.paymentPageErrors.paymentMethodsCardExpirationDateError ||
      this.paymentPageErrors.paymentMethodsCardCvvError ||
      this.paymentPageErrors.paymentMethodsCardZipcodeError
    );
  }

  @computed
  get allowedCards() {
    let returnArray = [];

    if (this.paymentPage.paymentMethods.methods.visa === true) {
      returnArray.push("visa");
    }
    if (this.paymentPage.paymentMethods.methods.mastercard === true) {
      returnArray.push("mastercard");
    }
    if (this.paymentPage.paymentMethods.methods.discover === true) {
      returnArray.push("discover");
    }
    if (this.paymentPage.paymentMethods.methods.amex === true) {
      returnArray.push("amex");
    }

    return returnArray;
  }

  @computed
  get getCredentialsPermissions() {
    let credentials = Object.entries(toJS(this.credentials));

    let availableCredentials = {
      card: { onetime: false, recurring: false, moneyout: false },
      ach: { onetime: false, recurring: false, moneyout: false },
      check: { onetime: false, recurring: false, moneyout: false },
      cash: { onetime: false, recurring: false, moneyout: false },
      wallet: { onetime: false, recurring: false, moneyout: false },
      cloud: { onetime: false, recurring: false, moneyout: false },
      vcard: { onetime: false, recurring: false, moneyout: false },
      managed: { onetime: false, recurring: false, moneyout: false },
    };
    credentials.forEach(function (item, index) {
      switch (item[1].Service.toLowerCase()) {
        case "card":
          if (item[1].Mode === 1) {
            availableCredentials.card.recurring = true;
          } else if (item[1].Mode === 0) {
            availableCredentials.card.onetime = true;
          } else {
            availableCredentials.card.moneyout = true;
          }
          break;
        case "vcard":
          if (item[1].Mode === 1) {
            availableCredentials.vcard.recurring = true;
          } else if (item[1].Mode === 0) {
            availableCredentials.vcard.onetime = true;
          } else {
            availableCredentials.vcard.moneyout = true;
          }
          break;
        case "managed":
          if (item[1].Mode === 1) {
            availableCredentials.managed.recurring = true;
          } else if (item[1].Mode === 0) {
            availableCredentials.managed.onetime = true;
          } else {
            availableCredentials.managed.moneyout = true;
          }
          break;
        case "ach":
          if (item[1].Mode === 1) {
            availableCredentials.ach.recurring = true;
          } else if (item[1].Mode === 0) {
            availableCredentials.ach.onetime = true;
          } else {
            availableCredentials.ach.moneyout = true;
          }
          break;
        case "check":
          if (item[1].Mode === 1) {
            availableCredentials.check.recurring = false;
          } else if (item[1].Mode === 0) {
            availableCredentials.check.onetime = true;
          } else {
            availableCredentials.check.moneyout = true;
          }
          break;
        case "cash":
          if (item[1].Mode === 1) {
            availableCredentials.cash.recurring = false;
          } else if (item[1].Mode === 0) {
            availableCredentials.cash.onetime = true;
          } else {
            availableCredentials.cash.moneyout = true;
          }
          break;
        case "wallet":
          if (item[1].Mode === 1) {
            availableCredentials.wallet.recurring = true;
          } else if (item[1].Mode === 0) {
            availableCredentials.wallet.onetime = true;
          } else {
            availableCredentials.wallet.moneyout = true;
          }
          break;
        case "cloud":
          if (item[1].Mode === 1) {
            availableCredentials.cloud.recurring = true;
          } else if (item[1].Mode === 0) {
            availableCredentials.cloud.onetime = true;
          } else {
            availableCredentials.cloud.moneyout = true;
          }
          break;
        default:
          break;
      }
    });
    return availableCredentials;
  }

  @action
  validateCredentialsMaxMinTicketError() {
    let categories = Object.entries(toJS(this.paymentPage.amount.categories));
    let credentials = Object.entries(toJS(this.credentials));
    let paymentMethod = this.getPaymentMethod;
    let netAmount = 0;
    let fee = 0;
    let objStore = this;

    categories.forEach(function (item, index) {
      if (item[1].optionalPay === true && !item[1].optionalPayChecked) {
        return;
      }
      netAmount =
        netAmount + parseFloat(item[1].value) * parseFloat(item[1].quantity);
    });

    credentials.forEach(function (item, index) {
      let isAutopayVar = objStore.isAutopay ? 3 : 2;
      if (
        (paymentMethod.toLowerCase() === "card" ||
          paymentMethod.toLowerCase() === "savedcard") &&
        item[1].Service.toLowerCase() === "card" &&
        item[1].Mode === isAutopayVar
      ) {
        fee =
          item[1].CfeeFix + (netAmount * parseFloat(item[1].CfeeFloat)) / 100;
        if (
          fee + netAmount > item[1].Maxticket ||
          fee + netAmount < item[1].Minticket
        ) {
          objStore.setPaymentPageError("credentialsMaxMinTicketError", true);
        } else {
          objStore.setPaymentPageError("credentialsMaxMinTicketError", false);
        }

        return;
      }

      if (
        (paymentMethod.toLowerCase() === "ach" ||
          paymentMethod.toLowerCase() === "savedach") &&
        item[1].Service.toLowerCase() === "ach" &&
        item[1].Mode === isAutopayVar
      ) {
        fee =
          item[1].CfeeFix + (netAmount * parseFloat(item[1].CfeeFloat)) / 100;
        if (
          fee + netAmount > item[1].Maxticket ||
          fee + netAmount < item[1].Minticket
        ) {
          objStore.setPaymentPageError("credentialsMaxMinTicketError", true);
        } else {
          objStore.setPaymentPageError("credentialsMaxMinTicketError", false);
        }

        return;
      }

      if (
        paymentMethod.toLowerCase() === "papercheck" &&
        item[1].Service.toLowerCase() === "check" &&
        item[1].Mode === isAutopayVar
      ) {
        fee =
          item[1].CfeeFix + (netAmount * parseFloat(item[1].CfeeFloat)) / 100;
        if (
          fee + netAmount > item[1].Maxticket ||
          fee + netAmount < item[1].Minticket
        ) {
          objStore.setPaymentPageError("credentialsMaxMinTicketError", true);
        } else {
          objStore.setPaymentPageError("credentialsMaxMinTicketError", false);
        }

        return;
      }

      if (
        paymentMethod.toLowerCase() === "vcard" &&
        item[1].Service.toLowerCase() === "vcard" &&
        item[1].Mode === isAutopayVar
      ) {
        fee =
          item[1].CfeeFix + (netAmount * parseFloat(item[1].CfeeFloat)) / 100;
        if (
          fee + netAmount > item[1].Maxticket ||
          fee + netAmount < item[1].Minticket
        ) {
          objStore.setPaymentPageError("credentialsMaxMinTicketError", true);
        } else {
          objStore.setPaymentPageError("credentialsMaxMinTicketError", false);
        }

        return;
      }
    });
  }

  getRoundUpDecimal2(pNumber) {
    let numberFloat = parseFloat(pNumber);
    return Math.ceil(numberFloat * 100) / 100;
  }

  sortFields(inputArr) {
    let len = inputArr.length;
    for (let i = 0; i < len; i++) {
      for (let j = 0; j < len - 1; j++) {
        if (inputArr[j][1].order > inputArr[j + 1][1].order) {
          let tmp = inputArr[j];
          inputArr[j] = inputArr[j + 1];
          inputArr[j + 1] = tmp;
        }
      }
    }
    return inputArr;
  }

  getPaymentMethodName(paymentMethod) {
    switch (
      paymentMethod ? paymentMethod : this.getPaymentMethod.toLowerCase()
    ) {
      case "vcard":
        return "Virtual Card";
      case "ach":
        return "Bank Account";
      case "card":
        return "Card";
      case "papercheck":
        return "Paper Check";
      case "digitalcheck":
        return "Digital Check";
      case "managed":
        return "Managed";
      case "wallet":
        return "Wallet";
      default:
        return "";
    }
  }

  @computed
  get totalAmount() {
    var categories = Object.entries(toJS(this.paymentPage.amount.categories));
    var credentials = Object.entries(toJS(this.credentials));
    var paymentMethod = this.getPaymentMethod;
    var netAmount = 0;
    var fee = 0;
    var objStore = this;

    if (paymentMethod.toLowerCase() === "applepay") {
      paymentMethod = "wallet";
    }

    categories.forEach(function (item, index) {
      if (item[1].optionalPay === true && !item[1].optionalPayChecked) {
        return;
      }
      netAmount =
        netAmount + parseFloat(item[1].value) * parseFloat(item[1].quantity);
    });

    //Calculating fee
    credentials.forEach(function (item, index) {
      let isAutopayVar = objStore.isAutopay ? 1 : 0;
      if (
        paymentMethod.toLowerCase() === "card" &&
        item[1].Service.toLowerCase() === "card" &&
        item[1].Mode === isAutopayVar
      ) {
        fee =
          item[1].CfeeFix +
          objStore.getRoundUpDecimal2(
            (netAmount * parseFloat(item[1].CfeeFloat)) / 100
          );
        if (item[1].CfeeMin !== 0 && fee < item[1].CfeeMin) {
          fee = item[1].CfeeMin;
        }
        if (item[1].CfeeMax !== 0 && fee > item[1].CfeeMax) {
          fee = item[1].CfeeMax;
        }
        return;
      }

      if (
        paymentMethod.toLowerCase() === "ach" &&
        item[1].Service.toLowerCase() === "ach" &&
        item[1].Mode === isAutopayVar
      ) {
        fee =
          item[1].CfeeFix +
          objStore.getRoundUpDecimal2(
            (netAmount * parseFloat(item[1].CfeeFloat)) / 100
          );
        if (item[1].CfeeMin !== 0 && fee < item[1].CfeeMin) {
          fee = item[1].CfeeMin;
        }
        if (item[1].CfeeMax !== 0 && fee > item[1].CfeeMax) {
          fee = item[1].CfeeMax;
        }
        return;
      }

      if (
        paymentMethod.toLowerCase() === "wallet" &&
        item[1].Service.toLowerCase() === "wallet" &&
        item[1].Mode === isAutopayVar
      ) {
        fee =
          item[1].CfeeFix +
          objStore.getRoundUpDecimal2(
            (netAmount * parseFloat(item[1].CfeeFloat)) / 100
          );
        if (item[1].CfeeMin !== 0 && fee < item[1].CfeeMin) {
          fee = item[1].CfeeMin;
        }
        if (item[1].CfeeMax !== 0 && fee > item[1].CfeeMax) {
          fee = item[1].CfeeMax;
        }
        return;
      }
    });

    netAmount = isNaN(netAmount) ? 0 : netAmount;
    fee = isNaN(fee) ? 0 : fee;

    var total = {
      netAmount: netAmount,
      fee: fee,
      totalAmount: netAmount + fee,
    };
    return total;
  }

  @computed
  get paymentCardData() {
    var paymentData = {
      entryPoint: this.entryPoint,
      subdomain: this.getPaymentPageSubdomain,
      source: this.paymentPage.invoices ? "Payment Link" : "Payment Page",
      orderDescription: this.paymentPage.notes.enabled
        ? this.paymentPage.notes.value
        : "",
      paymentMethod: {
        method: "card",
        cardnumber: this.paymentPage.paymentMethods.cardNumber,
        cardexp: this.paymentPage.paymentMethods.cardExpirationDate,
        cardcvv: this.paymentPage.paymentMethods.cardCvv,
        cardzip: this.paymentPage.paymentMethods.cardZipcode,
        cardHolder: this.paymentPage.paymentMethods.cardHolderName,
        saveIfSuccess:
          !this.isAutopay && this.savePaymentMethodForFuture ? true : false,
      },
      paymentDetails: {
        totalAmount: Number(
          parseFloat(this.totalAmount.totalAmount).toFixed(2)
        ),
        serviceFee: Number(parseFloat(this.totalAmount.fee).toFixed(2)),
        currency: this.currency,
        categories: this.getCategoriesFieldsToPay(),
      },
      customerData: this.getRequiredPayorFieldsToPay(),
      invoiceData: this.getInvoiceData(),
    };
    return paymentData;
  }

  @computed
  get paymentACHData() {
    var paymentData = {
      entryPoint: this.entryPoint,
      subdomain: this.getPaymentPageSubdomain,
      source: this.paymentPage.invoices ? "Payment Link" : "Payment Page",
      orderDescription: this.paymentPage.notes.enabled
        ? this.paymentPage.notes.value
        : "",
      paymentMethod: {
        method: "ach",
        achRouting: this.paymentPage.paymentMethods.achRouting,
        achAccount: this.paymentPage.paymentMethods.achAccount,
        achAccountType: this.paymentPage.paymentMethods.achAccountType,
        achHolder: this.paymentPage.paymentMethods.achAccountHolderName,
        saveIfSuccess:
          !this.isAutopay && this.savePaymentMethodForFuture ? true : false,
      },
      paymentDetails: {
        totalAmount: Number(
          parseFloat(this.totalAmount.totalAmount).toFixed(2)
        ),
        serviceFee: Number(parseFloat(this.totalAmount.fee).toFixed(2)),
        currency: this.currency,
        categories: this.getCategoriesFieldsToPay(),
      },
      customerData: this.getRequiredPayorFieldsToPay(),
      invoiceData: this.getInvoiceData(),
    };
    return paymentData;
  }

  @computed
  get paymentACHDataRecurring() {
    let frequency = this.paymentPage.autopay.frequencySelected;
    let startDate = this.dateStringFormat(this.paymentPage.autopay.startDate);
    let endDate = this.getAutopayFinishString();

    if (this.paymentPage.autopay.frequencySelected === "onetime") {
      endDate = startDate;
    }

    var paymentData = {
      entryPoint: this.entryPoint,
      subdomain: this.getPaymentPageSubdomain,
      source: this.paymentPage.invoices ? "Payment Link" : "Payment Page",
      orderDescription: this.paymentPage.notes.enabled
        ? this.paymentPage.notes.value
        : "",
      paymentMethod: {
        method: "ach",
        achRouting: this.paymentPage.paymentMethods.achRouting,
        achAccount: this.paymentPage.paymentMethods.achAccount,
        achAccountType: this.paymentPage.paymentMethods.achAccountType,
        achHolder: this.paymentPage.paymentMethods.achAccountHolderName,
      },
      paymentDetails: {
        totalAmount: Number(
          parseFloat(this.totalAmount.totalAmount).toFixed(2)
        ),
        serviceFee: Number(parseFloat(this.totalAmount.fee).toFixed(2)),
        currency: this.currency,
        categories: this.getCategoriesFieldsToPay(),
      },
      customerData: this.getRequiredPayorFieldsToPay(),
      scheduleDetails: {
        startDate: startDate,
        endDate: endDate,
        frequency: frequency,
      },
      invoiceData: this.getInvoiceData(),
    };
    return paymentData;
  }

  @computed
  get paymentCardDataRecurring() {
    let frequency = this.paymentPage.autopay.frequencySelected;
    let startDate = this.dateStringFormat(this.paymentPage.autopay.startDate);
    let endDate = this.getAutopayFinishString();

    if (this.paymentPage.autopay.frequencySelected === "onetime") {
      endDate = startDate;
    }

    let paymentData = {
      entryPoint: this.entryPoint,
      subdomain: this.getPaymentPageSubdomain,
      source: this.paymentPage.invoices ? "Payment Link" : "Payment Page",
      orderDescription: this.paymentPage.notes.enabled
        ? this.paymentPage.notes.value
        : "",
      paymentMethod: {
        method: "card",
        cardnumber: this.paymentPage.paymentMethods.cardNumber,
        cardexp: this.paymentPage.paymentMethods.cardExpirationDate,
        cardcvv: this.paymentPage.paymentMethods.cardCvv,
        cardzip: this.paymentPage.paymentMethods.cardZipcode,
        cardHolder: this.paymentPage.paymentMethods.cardHolderName,
        saveIfSuccess:
          !this.isAutopay && this.savePaymentMethodForFuture ? true : false,
      },
      paymentDetails: {
        totalAmount: Number(
          parseFloat(this.totalAmount.totalAmount).toFixed(2)
        ),
        serviceFee: Number(parseFloat(this.totalAmount.fee).toFixed(2)),
        currency: this.currency,
        categories: this.getCategoriesFieldsToPay(),
      },
      customerData: this.getRequiredPayorFieldsToPay(),
      scheduleDetails: {
        startDate: startDate,
        endDate: endDate,
        frequency: frequency,
      },
      invoiceData: this.getInvoiceData(),
    };
    return paymentData;
  }

  @computed
  get getPaymentPageLogo() {
    let urlLogo = "";
    let encryptStorage = EncryptStorage(process.env.REACT_APP_SECRET_KEY);

    if (this.previewLogo) {
      urlLogo = this.previewLogo;
    } else {
      urlLogo =
        this.paymentPageSettings.pageLogo &&
        this.paymentPageSettings.pageLogo.furl
          ? this.paymentPageSettings.pageLogo.furl
          : encryptStorage.getItem("pImg")
          ? encryptStorage.getItem("pImg")
          : payabli;
      if (
        this.paymentPageSettings.pageLogo &&
        this.paymentPageSettings.pageLogo.furl &&
        (this.paymentPageSettings.pageLogo.furl.startsWith("http://") ||
          this.paymentPageSettings.pageLogo.furl.startsWith("https://"))
      ) {
        urlLogo = this.paymentPageSettings.pageLogo.furl;
      }
    }

    let type = "png";
    let urlLogoArray = urlLogo.split(".");
    if (urlLogoArray.length > 1) {
      type = urlLogo.split(".").pop();
    }

    return { furl: urlLogo, ftype: type };
  }

  canUseApplePay() {
    return (
      window.ApplePaySession &&
      window.ApplePaySession.canMakePayments() &&
      window.ApplePaySession.supportsVersion(3)
    );
  }
}

const mainStore = new MainStore();
export default mainStore;
