import React from 'react';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
class Layout extends React.Component {

    render() {
        
        return (
            <div>
                {this.props.store.isLoading &&
                <div id="main-loading-layer" className="d-flex justify-content-center align-items-center">
                   <div style={{color:"#cccccc"}} className="spinner-border" role="status">
                    </div>
                </div>
                }
                <div className="main-body3">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export { Layout };