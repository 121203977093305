import { observable, makeObservable , action, computed, toJS} from "mobx";
import mainStore from "./MainStore";
// import axios from 'axios';
// import { EncryptStorage } from 'encrypt-storage';
// import visa from '../assets/images/visa.svg';
// import mastercard from '../assets/images/mastercard.svg';
// import amex from '../assets/images/amex.svg';
// import discover from '../assets/images/discover.svg';
// import bank from '../assets/images/bank.svg';
// import jcb from '../assets/images/jcb.svg';
// import payabli from '../assets/images/payabli.png';
//const FileDownload = require('js-file-download');

class BillStore {

    constructor(mainStore) {
        makeObservable(this)
        this.mainStore = mainStore;
    }

    entryPoint = '';

    @observable
    paymentResponse = null;

    @observable
    paymentMethod = 'ach';

    @observable
    paymentPageErrors = {
        autopayStartDateError: false,
        autopayFrequencyError: false,
        autopayFinishError: false,
        notesError: false,
        
        paymentMethodsAchAccountHolderNameError: false,
        paymentMethodsAchAccountTypeError: false,
        paymentMethodsAchRoutingError: false,
        paymentMethodsAchAccountError: false,
        
        paymentMethodsCardNumberError: false,
        paymentMethodsCardExpirationDateError: false,
        paymentMethodsCardCvvError: false,
        paymentMethodsCardZipcodeError: false,
        paymentMethodsCardHolderNameError: false,

        credentialsMaxMinTicketError: false
    }

    @observable
    loading = false;
    
    @observable
    credentials = [
        {
            "Service": "card",
            "Mode": 0,
            "Minticket": 0,
            "Maxticket": 2000,
            "CfeeFix": 0,
            "CfeeFloat": 0,
            "CfeeMin": 0,
            "CfeeMax": 0,
            "ReferenceId": 9,
            "AccountId": null
        },
        {
            "Service": "ach",
            "Mode": 0,
            "Minticket": 0,
            "Maxticket": 1000,
            "CfeeFix": 1,
            "CfeeFloat": 0,
            "CfeeMin": 0,
            "CfeeMax": 0,
            "ReferenceId": 11,
            "AccountId": null
        }
    ];

    

    @observable
    validationCode = null;

    @observable
    paymentPageSettings = {
        color: '#4b8f38',
        colorPayabli: '#4b8f38',
        paymentButton: {
            label: 'Pay Now',
            size: 'sm'
        },
        customCssUrl: '',
        language: 'en',
        customUrl: '',
        redirectAfterApprove: false,
        redirectAfterApproveUrl: '',
        notificationEmail: '',
        allApprovedPaymentNotification: false,
        dailyBatchReportNotification: false,
        pageLogo: {
            furl:''
        }
    }
    
    @observable
    paymentPageSettings2 = {
        color: '#0070e0',
        colorPayabli: '#0070e0',
        paymentButton: {
            label: 'Pay Now',
            size: 'sm'
        },
        customCssUrl: '',
        language: 'en',
        customUrl: '',
        redirectAfterApprove: false,
        redirectAfterApproveUrl: '',
        notificationEmail: '',
        allApprovedPaymentNotification: false,
        dailyBatchReportNotification: false,
        pageLogo: {
            furl:''
        }
    }
   
    @observable
    paymentPageSettings3 = {
        color: '#6789ba',
        colorPayabli: '#6789ba',
        paymentButton: {
            label: 'Pay Now',
            size: 'sm'
        },
        customCssUrl: '',
        language: 'en',
        customUrl: '',
        redirectAfterApprove: false,
        redirectAfterApproveUrl: '',
        notificationEmail: '',
        allApprovedPaymentNotification: false,
        dailyBatchReportNotification: false,
        pageLogo: {
            furl:''
        }
    }

    @observable
    paymentPage= {
        subdomain: '',
        name: '',
        logo:{
            enabled: true,
            order: 0
        },
        page:{
            header: 'Your Claim is Being Paid',
            description: 'For the amount of',
            enabled: true,
            order: 1
        },
        amount:{
            categories: [
                {
                  name : "1666896863018",
                  type : "customer",
                  label : "Item Name",
                  value : "550.10",
                  description : "Item description (optional)",
                  order : 1,
                  quantity : 1,
                  showDescription : false,
                  optionalPay : false
                }
            ],
            enabled: true,
            order: 2
        },
        autopay:{
            header: '',
            finish: {
                untilCancelled: true,
                calendar: false,
            },
            frequency: {
                weekly: false,
                every2Weeks: false,
                every6Months: false,
                monthly: false,
                every3Months: false,
                annually: false
            },
            startDate:'',
            frequencySelected:'',
            finishSelected:'',
            enabled: false,
            order: 3
        },
        payor: {
            header: '',
            enabled: false,
            order: 4,
            fields: []
        },
        paymentMethods:{
            header: 'How would you like to get paid?',
            methods: {
                visa: true,
                mastercard: true,
                discover: true,
                amex: true,
                eCheck: true,
                applePay: true,
                googlePay: true,
                payPal: true,
                bitPay: true,
            },
            enabled: true,
            allMethodsChecked: true,
            
            achAccountHolderName: '',
            achAccountType: 'checking',
            achRouting: '',
            achAccount: '',
            
            cardNumber: '',
            cardExpirationDate: '',
            cardCvv: '',
            cardZipcode: '',
            
            order: 5
        },
        notes:{
            header: '',
            placeholder: '',
            enabled: false,
            value: '',
            order: 6
        },
        review:{
            header: '',
            enabled: true,
            order: 7
        },
        messageBeforePaying:{
            label: '',
            enabled: true,
            order: 8
        },
        paymentButton:{
            label: 'Get Paid',
            order: 9
        },
        contactUs:{
            header: '',
            emailLabel: 'info@huntington.com',
            phoneLabel: '+1 (123) 456-7890',
            paymentIcons: true,
            enabled: true,
            order: 10
        },
        
        invoices: {
        invoiceList: [
        {
            invoiceId: 940,
            invoiceNumber: "QA-1666714488",
            invoiceDate: "2022-10-27T00:00:00",
            invoiceDueDate: "2022-11-24T00:00:00",
            invoiceSentDate: null,
            invoiceEndDate: null,
            lastPaymentDate: null,
            createdAt: "2022-10-25T16:14:48",
            invoiceStatus: 1,
            invoiceType: 0,
            frequency: null,
            paymentTerms: "N30",
            termsConditions: null,
            notes: null,
            tax: 0,
            discount: 0,
            invoiceAmount: 4.5,
            invoicePaidAmount: 0,
            purchaseOrder: null,
            firstName: "Lisandra Lucia",
            lastName: "Sosa",
            company: "Sunshine LLC",
            shippingAddress1: "",
            shippingAddress2: "",
            shippingCity: "",
            shippingState: "",
            shippingZip: "",
            shippingCountry: "",
            shippingEmail: "lisandra@payabli.com",
            shippingPhone: "8636700248",
            summaryCommodityCode: null,
            items: [
                {
                itemTotalAmount: 550.10,
                itemTaxAmount: 0,
                itemTaxRate: 0,
                itemProductCode: null,
                itemProductName: "Car Rental",
                itemDescription: "amount",
                itemCommodityCode: null,
                itemUnitOfMeasure: "quantity",
                itemCost: 550.10,
                itemQty: 1,
                itemMode: null,
                itemCategories: null
                }
            ],
            customerId: 224
            }
        ],
        invoiceLink: {
            label: "View invoice",
            enabled: true,
            order: 9
        },
        viewInvoiceDetails: {
            label: "View claim details",
            enabled: true,
            order: 10
        },
        enabled: true,
        order: 3
        }
    }

    @observable
    paymentPage2= {
        subdomain: '',
        name: '',
        logo:{
            enabled: true,
            order: 0
        },
        page:{
            header: 'Your Invoices is Being Paid',
            description: 'For the amount of',
            enabled: true,
            order: 1
        },
        amount:{
            categories: [
                {
                  name : "1666896863018",
                  type : "customer",
                  label : "Item Name",
                  value : "550.10",
                  description : "Item description (optional)",
                  order : 1,
                  quantity : 1,
                  showDescription : false,
                  optionalPay : false
                }
            ],
            enabled: true,
            order: 2
        },
        autopay:{
            header: '',
            finish: {
                untilCancelled: true,
                calendar: false,
            },
            frequency: {
                weekly: false,
                every2Weeks: false,
                every6Months: false,
                monthly: false,
                every3Months: false,
                annually: false
            },
            startDate:'',
            frequencySelected:'',
            finishSelected:'',
            enabled: false,
            order: 3
        },
        payor: {
            header: '',
            enabled: false,
            order: 4,
            fields: []
        },
        paymentMethods:{
            header: 'How would you like to get paid?',
            methods: {
                visa: true,
                mastercard: true,
                discover: true,
                amex: true,
                eCheck: true,
                applePay: true,
                googlePay: true,
                payPal: true,
                bitPay: true,
            },
            enabled: true,
            allMethodsChecked: true,
            
            achAccountHolderName: '',
            achAccountType: 'checking',
            achRouting: '',
            achAccount: '',
            
            cardNumber: '',
            cardExpirationDate: '',
            cardCvv: '',
            cardZipcode: '',
            
            order: 5
        },
        notes:{
            header: '',
            placeholder: '',
            enabled: false,
            value: '',
            order: 6
        },
        review:{
            header: '',
            enabled: true,
            order: 7
        },
        messageBeforePaying:{
            label: '',
            enabled: true,
            order: 8
        },
        paymentButton:{
            label: 'Get Paid',
            order: 9
        },
        contactUs:{
            header: '',
            emailLabel: 'info@payhoa.com',
            phoneLabel: '+1 (123) 456-7890',
            paymentIcons: true,
            enabled: true,
            order: 10
        },
        
        invoices: {
        invoiceList: [
        {
            invoiceId: 940,
            invoiceNumber: "QA-1666714488",
            invoiceDate: "2022-10-27T00:00:00",
            invoiceDueDate: "2024-11-30T00:00:00",
            invoiceSentDate: null,
            invoiceEndDate: null,
            lastPaymentDate: null,
            createdAt: "2022-10-25T16:14:48",
            invoiceStatus: 1,
            invoiceType: 0,
            frequency: null,
            paymentTerms: "N30",
            termsConditions: null,
            notes: null,
            tax: 0,
            discount: 0,
            invoiceAmount: 4.5,
            invoicePaidAmount: 0,
            purchaseOrder: null,
            firstName: "Lisandra Lucia",
            lastName: "Sosa",
            company: "Sunshine LLC",
            shippingAddress1: "",
            shippingAddress2: "",
            shippingCity: "",
            shippingState: "",
            shippingZip: "",
            shippingCountry: "",
            shippingEmail: "lisandra@payabli.com",
            shippingPhone: "8636700248",
            summaryCommodityCode: null,
            items: [
                {
                itemTotalAmount: 550.10,
                itemTaxAmount: 0,
                itemTaxRate: 0,
                itemProductCode: null,
                itemProductName: "Landscaping",
                itemDescription: "amount",
                itemCommodityCode: null,
                itemUnitOfMeasure: "quantity",
                itemCost: 550.10,
                itemQty: 1,
                itemMode: null,
                itemCategories: null
                }
            ],
            customerId: 224
            }
        ],
        invoiceLink: {
            label: "View invoice",
            enabled: true,
            order: 9
        },
        viewInvoiceDetails: {
            label: "View Invoice Details",
            enabled: true,
            order: 10
        },
        enabled: true,
        order: 3
        }
    }
    
    @observable
    paymentPage3= {
        subdomain: '',
        name: '',
        logo:{
            enabled: true,
            order: 0
        },
        page:{
            header: 'Your Invoices is Being Paid',
            description: 'For the amount of',
            enabled: true,
            order: 1
        },
        amount:{
            categories: [
                {
                  name : "1666896863018",
                  type : "customer",
                  label : "Item Name",
                  value : "360.00",
                  description : "Item description (optional)",
                  order : 1,
                  quantity : 1,
                  showDescription : false,
                  optionalPay : false
                }
            ],
            enabled: true,
            order: 2
        },
        autopay:{
            header: '',
            finish: {
                untilCancelled: true,
                calendar: false,
            },
            frequency: {
                weekly: false,
                every2Weeks: false,
                every6Months: false,
                monthly: false,
                every3Months: false,
                annually: false
            },
            startDate:'',
            frequencySelected:'',
            finishSelected:'',
            enabled: false,
            order: 3
        },
        payor: {
            header: '',
            enabled: false,
            order: 4,
            fields: []
        },
        paymentMethods:{
            header: 'How would you like to get paid?',
            methods: {
                visa: true,
                mastercard: true,
                discover: true,
                amex: true,
                eCheck: true,
                applePay: true,
                googlePay: true,
                payPal: true,
                bitPay: true,
            },
            enabled: true,
            allMethodsChecked: true,
            
            achAccountHolderName: '',
            achAccountType: 'checking',
            achRouting: '',
            achAccount: '',
            
            cardNumber: '',
            cardExpirationDate: '',
            cardCvv: '',
            cardZipcode: '',
            
            order: 5
        },
        notes:{
            header: '',
            placeholder: '',
            enabled: false,
            value: '',
            order: 6
        },
        review:{
            header: '',
            enabled: true,
            order: 7
        },
        messageBeforePaying:{
            label: '',
            enabled: true,
            order: 8
        },
        paymentButton:{
            label: 'Get Paid',
            order: 9
        },
        contactUs:{
            header: '',
            emailLabel: 'info@seaworld.com',
            phoneLabel: '+1 (123) 456-7890',
            paymentIcons: true,
            enabled: true,
            order: 10
        },
        
        invoices: {
        invoiceList: [
        {
            invoiceId: 940,
            invoiceNumber: "QA-1666714488",
            invoiceDate: "2022-10-27T00:00:00",
            invoiceDueDate: "2024-11-30T00:00:00",
            invoiceSentDate: null,
            invoiceEndDate: null,
            lastPaymentDate: null,
            createdAt: "2022-10-25T16:14:48",
            invoiceStatus: 1,
            invoiceType: 0,
            frequency: null,
            paymentTerms: "N30",
            termsConditions: null,
            notes: null,
            tax: 0,
            discount: 0,
            invoiceAmount: 4.5,
            invoicePaidAmount: 0,
            purchaseOrder: null,
            firstName: "Lisandra Lucia",
            lastName: "Sosa",
            company: "Sunshine LLC",
            shippingAddress1: "",
            shippingAddress2: "",
            shippingCity: "",
            shippingState: "",
            shippingZip: "",
            shippingCountry: "",
            shippingEmail: "lisandra@payabli.com",
            shippingPhone: "8636700248",
            summaryCommodityCode: null,
            items: [
                {
                itemTotalAmount: 360.00,
                itemTaxAmount: 0,
                itemTaxRate: 0,
                itemProductCode: null,
                itemProductName: "Landscaping",
                itemDescription: "amount",
                itemCommodityCode: null,
                itemUnitOfMeasure: "quantity",
                itemCost: 360.00,
                itemQty: 1,
                itemMode: null,
                itemCategories: null
                }
            ],
            customerId: 224
            }
        ],
        invoiceLink: {
            label: "View invoice",
            enabled: true,
            order: 9
        },
        viewInvoiceDetails: {
            label: "View Invoice Details",
            enabled: true,
            order: 10
        },
        enabled: true,
        order: 3
        }
    }

    @action
    setPaymentMethod(method) {
        this.paymentMethod = method;
    }

    @action
    setDefaultPaymentMethodActiveKey(key) {
        this.defaultPaymentMethodActiveKey = key;
    }

    @action
    handleCategoryTextChangeMask(field, key ,value) {
        this.paymentPage.amount.categories[field][key]= value;
    }

    getValidationCode(){
        return this.validationCode;
    }

    creditCardType(number) {
        if (!number || number === '') {
            return 'unknown';
        }

        if(number.length > 14 ){
            if(this.luhnCheck(number) === false){
                return 'unknown';
            }
        }

        let creditCardType = require("credit-card-type");
        let visaCards = creditCardType(number);
        if (visaCards[0]) {
            return visaCards[0].type;
        }
        else {
            return 'unknown';
        }
    }

    @computed
    get amountCategories(){
        return this.paymentPage.amount.categories; 
    }

    @computed
    get amountCategoriesGeneric(){
        return this.paymentPage3.amount.categories; 
    }

    @computed
    get getPaymentPageErrors(){
        return this.paymentPageErrors;
    }

    @computed
    get totalAmount(){
        var categories = Object.entries(toJS(this.paymentPage.amount.categories));
        var credentials = Object.entries(toJS(this.credentials));
        var paymentMethod = this.getPaymentMethod;
        var netAmount = 0;
        var fee = 0;
        var objStore = this;
        var mainStore = this.mainStore;

        categories.forEach(function (item, index) {
            if(item[1].optionalPay === true && !item[1].optionalPayChecked)
            {
                return;
            }
            netAmount = netAmount + (parseFloat(item[1].value) * parseFloat(item[1].quantity) );
        });

        //Calculating fee
        credentials.forEach(function (item, index) {
            let isAutopayVar = objStore.isAutopay ? 1 : 0;
            if(paymentMethod.toLowerCase() === "card" && item[1].Service.toLowerCase() === 'card' && item[1].Mode === isAutopayVar){
                fee = item[1].CfeeFix + mainStore.getRoundUpDecimal2((netAmount * parseFloat(item[1].CfeeFloat)) / 100);
                if(item[1].CfeeMin !== 0 && fee < item[1].CfeeMin){
                    fee = item[1].CfeeMin;
                }
                if(item[1].CfeeMax !== 0 && fee > item[1].CfeeMax){
                    fee = item[1].CfeeMax;
                }
                return;
            }

            if(paymentMethod.toLowerCase() === "ach" && item[1].Service.toLowerCase() === 'ach' && item[1].Mode === isAutopayVar){
                fee = item[1].CfeeFix + mainStore.getRoundUpDecimal2((netAmount * parseFloat(item[1].CfeeFloat)) / 100);
                if(item[1].CfeeMin !== 0 && fee < item[1].CfeeMin){
                    fee = item[1].CfeeMin;
                }
                if(item[1].CfeeMax !== 0 && fee > item[1].CfeeMax){
                    fee = item[1].CfeeMax;
                }
                return;
            }
            
        });

        netAmount = isNaN(netAmount) ? 0 : netAmount;
        fee = isNaN(fee) ? 0 : fee;

        var total = {
            netAmount: netAmount,
            fee: fee,
            totalAmount: netAmount + fee
        }
        return total;
    }


    @computed
    get getPaymentMethod(){
        return this.paymentMethod; 
    }

    @computed
    get payerFields(){
        return this.paymentPage.payor.fields; 
    }

    @computed
    get hasECheck(){
        return this.paymentPage.paymentMethods.methods.eCheck;
    }

    @computed
    get getCredentialsPermissions(){
        let credentials = Object.entries(toJS(this.credentials));
        let availableCredentials = {
            card : {onetime: false, recurring: false},
            ach : {onetime: false, recurring: false},
            wallet : {onetime: false, recurring: false},
            cloud : {onetime: false, recurring: false}
        }
        credentials.forEach(function (item, index) {
            switch(item[1].Service.toLowerCase()){
                case "card":
                    if(item[1].Mode === 1){
                        availableCredentials.card.recurring = true;
                    }
                    else{
                        availableCredentials.card.onetime = true;
                    }
                    break;
                case "ach":
                    if(item[1].Mode === 1){
                        availableCredentials.ach.recurring = true;
                    }
                    else{
                        availableCredentials.ach.onetime = true;
                    }
                    break;
                case "wallet":
                    if(item[1].Mode === 1){
                        availableCredentials.wallet.recurring = true;
                    }
                    else{
                        availableCredentials.wallet.onetime = true;
                    }
                    break;
                case "cloud":
                    if(item[1].Mode === 1){
                        availableCredentials.cloud.recurring = true;
                    }
                    else{
                        availableCredentials.cloud.onetime = true;
                    }
                    break;
                default:
                    break;
            }
            
        });
        return availableCredentials;
    }

    @computed
    get hasCards(){
        return this.paymentPage.paymentMethods.methods.visa || 
        this.paymentPage.paymentMethods.methods.mastercard || 
        this.paymentPage.paymentMethods.methods.discover || 
        this.paymentPage.paymentMethods.methods.amex
        ;
    }

}

const billStore = new BillStore(mainStore);
export default billStore;