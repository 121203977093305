import React from 'react';
import PlacesAutocomplete, {
	geocodeByAddress,
} from 'react-places-autocomplete';
import { observer } from 'mobx-react';
import { GrLocation } from 'react-icons/gr'
import * as Sentry from "@sentry/react";

@observer
class LocationSearchInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			address: '',
			address2: '',
			city: '',
			state: '',
			zipcode: '',
			country: '',
		};
		this.handleTextChange = this.handleTextChange.bind(this);
		this.handleChangeAddress = this.handleChangeAddress.bind(this);
		this.handleTextChangeMaskedInput =
			this.handleTextChangeMaskedInput.bind(this);
	}

	componentDidMount() {
		this.setState({
			address: this.props.address,
			address2: this.props.address2,
			city: this.props.city,
			state: this.props.state,
			zipcode: this.props.zipcode,
			country: this.props.country,
		});
	}

	componentDidUpdate(prevProps, prevState){
		if (this.props.address !== prevProps.address) {
			this.setState({address: this.props.address});
		}
	}

	handleTextChange(e, parentStateValue) {
		console.log(e.target.value);
		this.setState({ [e.target.id]: e.target.value });
		if (this.props.handleTextChangeKeys && this.props.handleTextChange) {
			this.props.handleTextChange(parentStateValue, e.target.value);
		}
	}
	handleTextChangeMaskedInput(id, value, parentStateValue) {
		this.setState({ [id]: value });
		if (this.props.handleTextChangeKeys && this.props.handleTextChange) {
			this.props.handleTextChange(parentStateValue, value);
		}
	}

	handleChangeAddress = (address) => {
		this.setState({ address });
		if (this.props.handleTextChangeKeys && this.props.handleTextChange) {
			this.props.handleTextChange(this.props.handleTextChangeKeys[0], address);
		}
	};

	handleSelect = (address) => {
		if (address) {
			geocodeByAddress(address)
				.then((results) => {
					if (results[0] && results[0].address_components) {
						let address_components = results[0].address_components;
						let city = '';
						let state = '';
						let address2 = '';
						let zipcode = '';
						let country = '';

						address_components.forEach(function (item) {
							if (item.types[0] === 'locality') {
								city = item.short_name;
							}
							if (item.types[0] === 'administrative_area_level_1') {
								state = item.short_name;
							}
							if (item.types[0] === 'administrative_area_level_2') {
								address2 = item.short_name;
							}
							if (item.types[0] === 'postal_code') {
								zipcode = item.short_name;
							}
							if (item.types[0] === 'country') {
								country = item.short_name;
							}
						});

						//cleaning address
						let name_address =
							results[0].address_components[0].long_name +
							' ' +
							results[0].address_components[1].long_name;
						let nameAddressShort = name_address;

						if (this.props.onlyAddress) {
							name_address = [
								name_address,
								address2,
								city,
								state,
								zipcode,
								country,
							].join(' ');
						}
						
						this.setState({
							address: name_address,
							city: city,
							address2: address2,
							state: state,
							zipcode: zipcode,
							country: country,
							addressShort: nameAddressShort
						});

						if (this.props.onChange) {
							this.props.onChange(
								this.props.handleTextChangeKeys[0],
								name_address,
								address2,
								city,
								state,
								zipcode,
								country,
								nameAddressShort
							);
						}
					}
				})
				.catch((error) => Sentry.captureException(error) || console.error('Error', error));
		}
	};

	render() {
		return (
			<>
				<PlacesAutocomplete
					value={this.state.address}
					onChange={this.handleChangeAddress}
					onSelect={this.handleSelect}
				>
					{({
						getInputProps,
						suggestions,
						getSuggestionItemProps,
						loading,
					}) => (
						<div className="form-floating" style={{ position: 'relative' }}>
							<input
								{...getInputProps({
									placeholder: this.props.placeholder,
									className: `${this.props.className} mb-3`,
								})}
								autoComplete="address"
								name={this.props.handleTextChangeKeys[0]}
								id={this.props.handleTextChangeKeys[0]}
							/>
							{suggestions.length > 0 && (
								<div className="places-autocomplete-dropdown-container">
									{loading && <div className="suggestion-item">Loading...</div>}
									{suggestions.map((suggestion, i) => {
										const className = suggestion.active
											? 'suggestion-item-active'
											: 'suggestion-item';

										return (
											<div
												key={i}
												{...getSuggestionItemProps(suggestion, {
													className,
												})}
											>
												<span><GrLocation /> {suggestion.description}</span>
											</div>
										);
									})}
								</div>
							)}
							<label htmlFor={this.props.handleTextChangeKeys[0]}>
								{this.props.placeholder}
							</label>
						</div>
					)}
				</PlacesAutocomplete>
				{/* {!this.props.onlyAddress && (
					<div className="row">
						<div className="col-sm-12 mb-3">
							<div className="form-floating">
								<input
									autoComplete="address2"
									name="address2"
									id="address2"
									onChange={(e) =>
										this.handleTextChange(e, this.props.handleTextChangeKeys[1])
									}
									value={this.state.address2}
									className={'form-control'}
									placeholder="Billing Address 2"
								/>
								<label htmlFor="address2">
									Address 2 (ex: apt, suite, building, etc)
								</label>
							</div>
						</div>
						<div className="col-sm-4 mb-3">
							<div className="form-floating">
								<input
									value={this.state.city}
									onChange={(e) =>
										this.handleTextChange(e, this.props.handleTextChangeKeys[2])
									}
									id="city"
									className={'form-control'}
									placeholder="City"
								/>
								<label htmlFor="city">City</label>
							</div>
						</div>
						<div className="col-sm-4 mb-3">
							<div className="form-floating mb-3">
								<select
									value={this.state.state}
									onChange={(e) =>
										this.handleTextChange(e, this.props.handleTextChangeKeys[3])
									}
									className="form-select form-control"
									id="state"
									aria-label="state"
								>
									<option value=""> Select... </option>
									{this.props.global.getUSAStates().map((record, i) => (
										<option key={i} value={record.value}>
											{record.text}
										</option>
									))}
								</select>
								<label htmlFor="state">State</label>
							</div>
						</div>
						<div className="col-sm-4 mb-3">
							<div className="form-floating">
								<IMaskInput
									mask={this.props.global.maskValidator('zipcode')}
									id="zipcode"
									value={this.state.zipcode}
									unmask={true}
									onAccept={(value, mask) =>
										this.handleTextChangeMaskedInput(
											'zipcode',
											value,
											this.props.handleTextChangeKeys[4]
										)
									}
									className="form-control"
									placeholder="Zipcode"
								/>
								<label htmlFor="zipcode">Zipcode</label>
							</div>
						</div>
					</div>
				)} */}
			</>
		);
	}
}

export { LocationSearchInput };
