import React from 'react';
import { inject, observer } from 'mobx-react';
import { Accordion , Card, Modal, Button } from 'react-bootstrap';
import { BiCheck, BiChevronDown, BiChevronRight, BiFile, BiLockOpen, BiMessageSquareError, BiPrinter } from "react-icons/bi";
import { PaymentMethodCCForm } from '../components/PaymentMethodCCForm';
import { PaymentMethodECheck } from '../components/PaymentMethodECheck';
import { PaymentMethodsAccepted } from '../components/PaymentMethodsAccepted';
import {IMaskInput} from 'react-imask';
import {Helmet} from "react-helmet";
import { PaymentPageLogo } from '../components/PaymentPageLogo';
import achIcon from '../assets/images/ach.svg';
import cardIcon from '../assets/images/card.svg';
import check from '../assets/images/paper-check.svg';
import visa from '../assets/images/visa.svg';
import sentryVcard from '../assets/images/sentryVCard.png';
import { AmountCategories } from '../components/AmountCategories';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Sentry from "@sentry/react";

@inject('store', 'billStore')
@observer
class VendorPayLink extends React.Component {

    constructor(props) {
        super(props);
        this.renderSortBlocks = this.renderSortBlocks.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.setPaymentMethod = this.setPaymentMethod.bind(this);
        this.handleAutopay = this.handleAutopay.bind(this);
        this.closeInfoModal = this.closeInfoModal.bind(this);
        this.openInfoModal = this.openInfoModal.bind(this);
        this.updateCredentialsPermissionsUI = this.updateCredentialsPermissionsUI.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.openConfirmModal = this.openConfirmModal.bind(this);
        this.reviewPayment = this.reviewPayment.bind(this);
        this.viewInvoiceDetail = this.viewInvoiceDetail.bind(this);
        this.printPage = this.printPage.bind(this);
        this.endDateAutopayCalendar = React.createRef();
        this.showVcardModal = this.showVcardModal.bind(this);
        this.closeVcardModal = this.closeVcardModal.bind(this);
        this.selectHowVerify = this.selectHowVerify.bind(this);
        this.handleTextChangeMask = this.handleTextChangeMask.bind(this);
        this.showVcardModalImage = this.showVcardModalImage.bind(this);
        this.closeVcardModalImage = this.closeVcardModalImage.bind(this);
        this.schedulePaymentMoneyOut = this.schedulePaymentMoneyOut.bind(this);

        this.state = {
            infoModalIsOpen: true,
            phoneError: false,
            infoMessageInModal: '',
            infoModalReload: false,
            inputQtyError: false,
            invoiceDetailsOpen: false,
            selectVcardModeModalOpen: false,
            howToVerify: "",
            pinNumber: "",
            vcardImageModalOpen: false
        };
    }

    closeInfoModal(){
        this.setState({ infoModalIsOpen: true });

        if(this.state.infoModalReload === true){
            window.location.reload();
        }
    }

    selectHowVerify(e){
        this.setState({howToVerify:e.target.value})
    }
    
    showVcardModal(){
        this.setPaymentMethod('vcard',"2");
        this.setState({
            selectVcardModeModalOpen: true,
            howToVerify: "",
            pinNumber: ""
        });
    }

    showVcardModalImage(){
        this.closeVcardModal();
        this.setState({
            vcardImageModalOpen: true
        });
    }
    
    closeVcardModalImage(){
        this.setState({
            vcardImageModalOpen: false
        });
    }
    
    closeVcardModal(){
        this.setState({selectVcardModeModalOpen: false});
    }

    closeConfirmModal(info){
        this.setState({ confirmModalIsOpen: false });
    }

    openConfirmModal(info){
        this.setState({ confirmModalIsOpen: true });
    }

    viewInvoiceDetail(){
        this.setState({invoiceDetailsOpen: !this.state.invoiceDetailsOpen});
    }
    
    printPage(){
        window.print();

    }

    openInfoModal(info, reload){
        this.setState({ infoModalIsOpen: false, infoMessageInModal: info, infoModalReload : reload === true ? true : false });
    }

    handleAutopay(event){
        event.preventDefault()
        this.props.store.handleAutopay();
        this.updateCredentialsPermissionsUI();
    }

    reviewPayment(){
        this.validateFields();
        
        if(this.props.store.getPaymentPageErrors.credentialsMaxMinTicketError){
             this.openInfoModal('Sorry, the amount to be paid is greater or less than what is allowed.');
             return;
        }

        if(this.props.store.totalAmount.netAmount === 0){
            this.openInfoModal('Sorry, the amount must be greater than $0.00');
            return;
        }
        else{
            if(!this.props.store.hasPaymentPageErrors()){
                
                if(!this.props.store.isAllowedPaymentMethod()){
                    this.openInfoModal('Sorry, the selected payment method is not allowed.');
                    return;
                }
                this.openConfirmModal();
            
            }
        }
    }


    clearCardErrors(){
        this.props.store.setPaymentPageError('paymentMethodsCardNumberError',false);
        this.props.store.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
        this.props.store.setPaymentPageError('paymentMethodsCardCvvError',false);
        this.props.store.setPaymentPageError('paymentMethodsCardZipcodeError',false);
        this.props.store.setPaymentPageError('paymentMethodsCardHolderNameError',false);
    }

    clearACHErrors(){
        this.props.store.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
        this.props.store.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
        this.props.store.setPaymentPageError('paymentMethodsAchRoutingError',false);
        this.props.store.setPaymentPageError('paymentMethodsAchAccountError',false);
    }

    validateFields(){
        
        var paymentPage = this.props.store.paymentPage;
        var validators = this.props.store.validators;

        // validating ach form
        if(this.props.store.getPaymentMethod === "ach"){

            this.clearCardErrors();
            this.props.store.setPaymentPageError('paymentMethodsPapercheckAddressError',false);
            this.props.store.setPaymentPageError('paymentMethodsDigitalcheckEmailError',false);

            if(validators.isEmpty(paymentPage.paymentMethods.achAccountHolderName) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountHolderName))
            {
                this.props.store.setPaymentPageError('paymentMethodsAchAccountHolderNameError',true);
            }
            else{
                this.props.store.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achAccountType) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountType))
            {
                this.props.store.setPaymentPageError('paymentMethodsAchAccountTypeError',true);
            }
            else{
                this.props.store.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achRouting) || !this.props.store.isValidRouting(paymentPage.paymentMethods.achRouting) || validators.isMaxLength(9, paymentPage.paymentMethods.achRouting) || validators.stringValidator('routing', paymentPage.paymentMethods.achRouting))
            {
                this.props.store.setPaymentPageError('paymentMethodsAchRoutingError',true);
            }
            else{
                this.props.store.setPaymentPageError('paymentMethodsAchRoutingError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achAccount) ||  validators.stringValidator('numbers', paymentPage.paymentMethods.achAccount) || validators.isMinLength(4, paymentPage.paymentMethods.achAccount) || validators.isMaxLength(17, paymentPage.paymentMethods.achAccount))
            {
                this.props.store.setPaymentPageError('paymentMethodsAchAccountError',true);
            }
            else{
                this.props.store.setPaymentPageError('paymentMethodsAchAccountError',false);
            }
        }
        
        // validating cc form
        if(this.props.store.getPaymentMethod === "card"){
            
            this.clearACHErrors();
            this.props.store.setPaymentPageError('paymentMethodsPapercheckAddressError',false);
            this.props.store.setPaymentPageError('paymentMethodsDigitalcheckEmailError',false);

            if(validators.isEmpty(paymentPage.paymentMethods.cardNumber) || validators.isMaxLength(16, paymentPage.paymentMethods.cardNumber) || validators.stringValidator('card', paymentPage.paymentMethods.cardNumber) || !this.props.store.luhnCheck(paymentPage.paymentMethods.cardNumber))
            {
                this.props.store.setPaymentPageError('paymentMethodsCardNumberError',true);
            }
            else{
                this.props.store.setPaymentPageError('paymentMethodsCardNumberError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardExpirationDate) || validators.isMaxLength(4, paymentPage.paymentMethods.cardExpirationDate) || validators.stringValidator('exp', paymentPage.paymentMethods.cardExpirationDate))
            {
                this.props.store.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
            }
            else{
                let expDateYear = paymentPage.paymentMethods.cardExpirationDate.substr(-2);
                let expDateMonth = paymentPage.paymentMethods.cardExpirationDate.substr(0,2);
                let currentYear =new Date().getFullYear().toString().substr(-2);
                let currentMonth =new Date().getMonth().toString();

                if(parseInt(expDateYear) < parseInt(currentYear)){
                    this.props.store.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                }
                else{
                    this.props.store.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
                }


                if(((parseInt(expDateYear) === parseInt(currentYear)) && ((parseInt(expDateMonth) < parseInt(currentMonth)+1) || (parseInt(expDateMonth) > 12)))){
                    this.props.store.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                }
            }

            let ccType = this.props.store.creditCardType(paymentPage.paymentMethods.cardNumber);
            if(validators.isEmpty(paymentPage.paymentMethods.cardCvv) || (ccType === "american-express" ? validators.stringValidator('cvvamex', paymentPage.paymentMethods.cardCvv) : validators.stringValidator('cvv', paymentPage.paymentMethods.cardCvv)))
            {
                this.props.store.setPaymentPageError('paymentMethodsCardCvvError',true);
            }
            else{
                this.props.store.setPaymentPageError('paymentMethodsCardCvvError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardZipcode) || validators.isMaxLength(7, paymentPage.paymentMethods.cardZipcode) || validators.stringValidator('zipcode', paymentPage.paymentMethods.cardZipcode))
            {
                this.props.store.setPaymentPageError('paymentMethodsCardZipcodeError',true);
            }
            else{
                this.props.store.setPaymentPageError('paymentMethodsCardZipcodeError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardHolderName) || validators.stringValidator('alpha', paymentPage.paymentMethods.cardHolderName))
            {
                this.props.store.setPaymentPageError('paymentMethodsCardHolderNameError',true);
            }
            else{
                this.props.store.setPaymentPageError('paymentMethodsCardHolderNameError',false);
            }
        }

        // validating papercheck form
        if(this.props.store.getPaymentMethod === "papercheck"){

            this.clearCardErrors();
            this.clearACHErrors();
            this.props.store.setPaymentPageError('paymentMethodsDigitalcheckEmailError',false);

            if(validators.isEmpty(paymentPage.bills?.billList[0]?.vendorAddress1))
            {
                this.props.store.setPaymentPageError('paymentMethodsPapercheckAddressError',true);
            }
            else
            {
                this.props.store.setPaymentPageError('paymentMethodsPapercheckAddressError',false);
            }
        }
       
        // validating digitalcheck form
        if(this.props.store.getPaymentMethod === "digitalcheck"){

            this.clearCardErrors();
            this.clearACHErrors();
            this.props.store.setPaymentPageError('paymentMethodsPapercheckAddressError',false);


            if(validators.isEmpty(paymentPage.bills?.billList[0]?.vendorEmail))
            {
                this.props.store.setPaymentPageError('paymentMethodsDigitalcheckEmailError',true);
            }
            else
            {
                this.props.store.setPaymentPageError('paymentMethodsDigitalcheckEmailError',false);
            }
        }

        this.props.store.validateCredentialsMaxMinTicketError();

        setTimeout(function(){ 
        try{
        let inputs =  document.getElementsByClassName('input-error');
        if(inputs[0]){
            let objRect = inputs[0].getBoundingClientRect()
            window.scrollTo({
                top: parseInt(objRect.top + window.scrollY - 120),
                behavior: 'smooth'
            });
            inputs[0].focus();
        }}
        catch(e){
            console.log(e);
        }
        }, 200);
    }

    sortFields(inputArr){
        let len = inputArr.length;
        for (let i = 0; i < len ; i++) {
            for (let j = 0; j < len - 1 ; j++) {
                if (inputArr[j][1].order > inputArr[j + 1][1].order) {
                    let tmp = inputArr[j];
                    inputArr[j] = inputArr[j + 1];
                    inputArr[j + 1] = tmp;
                }
            }
        }
        return inputArr;
    }

    sortBlocks(inputArr){
        let len = inputArr.length;
        for (let i = 0; i < len ; i++) {
            for (let j = 0; j < len - 1 ; j++) {
                if (inputArr[j].order > inputArr[j + 1].order) {
                    let tmp = inputArr[j];
                    inputArr[j] = inputArr[j + 1];
                    inputArr[j + 1] = tmp;
                }
            }
        }
        return inputArr;
    }
    
    handleTextChangeMask(e, value){
        this.setState({ [e]: value });
    }
    
    setPaymentMethod(method, activeKey){
        this.props.store.setPaymentMethod(method);
        if(activeKey!==null){
            this.props.store.setDefaultPaymentMethodActiveKey(activeKey);
        }
    }

    handleTextChange(block, key ,event) {
        this.props.store.handleTextChange(block, key ,event);
    }

    componentDidMount() {
        //this.props.store.setCurrentPage('payment-page');
        this.props.store.setDefaultPaymentMethodActiveKey('');
        this.props.store.setPaymentMethod('');
        let tokenid = this.props.match.params.tokenid;
        if (tokenid) {
            this.props.store.getPaymentPageLinkFromApi(tokenid).then(res => {
                
            })
            .catch(error => {
                Sentry.captureException(error);
                this.props.history.push("/");
            });
        }
        else{
           this.props.history.push("/");
        }

        this.setState({infoModalReload : false});
    }


    updateCredentialsPermissionsUI(){
        let credentialsPermissions = this.props.store.getCredentialsPermissions;
        if((credentialsPermissions.card.onetime && !this.props.store.isAutopay) || (credentialsPermissions.card.recurring && this.props.store.isAutopay)){
            this.props.store.setDefaultPaymentMethodActiveKey("0");
            this.setPaymentMethod('card',"0")
        }

        if((credentialsPermissions.ach.onetime && !this.props.store.isAutopay) || (credentialsPermissions.ach.recurring && this.props.store.isAutopay)){
            this.props.store.setDefaultPaymentMethodActiveKey("1");
            this.setPaymentMethod('ach', "1")
        }

        if(credentialsPermissions.ach.recurring || credentialsPermissions.card.recurring || credentialsPermissions.wallet.recurring || credentialsPermissions.cloud.recurring){
            this.props.store.setAutopay(this.props.store.isAutopay);
            //this.props.store.setAutopay(false);
        }
        else{
            this.props.store.setAutopay(false);
        }
    }

    renderSortBlocks(){

        let credentialsPermissions = this.props.store.getCredentialsPermissions;
        const btnTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
        };

        const linkTheme = {
            color: this.props.store.paymentPageSettings.color,
        };

        const bgTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
        };

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        
        let nextTwoDays = new Date();
        nextTwoDays.setDate(nextTwoDays.getDate() + 2);
        if(this.props.store.paymentPage.autopay.startDate){
            nextTwoDays = new Date(this.props.store.paymentPage.autopay.startDate);
            nextTwoDays.setDate(nextTwoDays.getDate() + 1);
        }
        

        const years = [];
        const yearsEndDate = [];
        let year = new Date().getFullYear();
        for (let i = year; i <= year + 2; i++) {
            years.push(i);
        }
        for (let i = year; i <= year + 20; i++) {
            yearsEndDate.push(i);
        }
        
        var blocksUI = [
                {
                    elements: <>{ this.props.store.paymentPage.logo.enabled &&
                        <li className="ui-state-default dragable-panel" id="blocklogo" style={{paddingBottom: 0}}>
                        <div className="text-center">
                            <PaymentPageLogo/>
                        </div>
                        </li>
                        }</> ,
                    order: this.props.store.paymentPage.logo.order ? this.props.store.paymentPage.logo.order : 0
                },

                {
                elements: <> {this.props.store.paymentPage.page.enabled && 
                    <li className="ui-state-default dragable-panel" id="blockpage" style={{paddingTop: 0}}>
                         <div className="row">
                             <div className="col-md-8 offset-md-2 progress-steps mb-3">
                                 <div className="row">
                                     <div style={bgTheme} className="col active">
                                         &nbsp;
                                     </div>
                                     <div className="col">
                                         &nbsp;
                                     </div>
                                 </div>
                             </div>
                         </div>
                         <div className="row">
                             <div className="col-12">
                                 <h3 className="card-title text-center">{'Your Invoice is being Paid'}</h3>
                                 <p className="small small-grey text-center m0">For the amount of</p>
                             </div>
                         </div>
                    </li>
                    }</> ,
                order: this.props.store.paymentPage.page.order ? this.props.store.paymentPage.page.order : 0
                },

                {
                elements:  <li className="ui-state-default dragable-panel" id="blockamount">
                <div className="payment-categories mb-1">
                    <ul className="sorted" ref={this.sortableCategories}>
                        <AmountCategories/>
                    </ul>
                </div>
                {this.props.store.switchAutopay &&
                    <>
                    { (this.props.store.isAutopay && (credentialsPermissions.ach.onetime || credentialsPermissions.card.onetime || credentialsPermissions.wallet.onetime || credentialsPermissions.cloud.onetime )) && 
                    <p className="text-center"><a style={linkTheme} href="/" onClick={(e) => this.handleAutopay(e)}>Click here</a> to make One-Time Payment</p>
                    }
                    { (!this.props.store.isAutopay && (credentialsPermissions.ach.recurring || credentialsPermissions.card.recurring || credentialsPermissions.wallet.recurring || credentialsPermissions.cloud.recurring )) &&
                    <p className="text-center"><a style={linkTheme} href="/" onClick={(e) => this.handleAutopay(e)}>Click here</a> to make an Autopay</p>
                    }
                    </>
                }
                </li>,
                order: this.props.store.paymentPage.amount.order ? this.props.store.paymentPage.amount.order : 0
                },

                {
                elements: <li className="ui-state-default dragable-panel" id="blockpaymentMethods">
                <h6 className="sub-header mb-3">{'How would you like to get paid?'}</h6>
                
               
                
                <Accordion activeKey={this.props.store.defaultPaymentMethodActiveKey}>

                    { (credentialsPermissions.vcard.moneyout)  && 
                        <Card className={"card card-in mb-3 card-hover " + ( this.props.store.getPaymentMethod === "vcard" ? 'active' : '')}>
                            <Accordion.Toggle as={Card.Header} className="card-body" eventKey="2" onClick={(e) => this.setPaymentMethod('vcard',"2")}>
                                <div className="row">
                                    <div className="col-2 text-center">
                                        <img alt="Card" style={{width: '46px'}} className="grey-icon-v2" src={cardIcon}/>
                                    </div>
                                    <div className="col-10">
                                    Virtual Pre Paid Card
                                    <p className="small small-grey-m0">
                                    Receive an emailed digital card
                                    </p>
                                    <div className="card-brands accordion-right-corner">
                                    <img alt="" src={visa} />
                                    </div>
                                    </div>
                                </div>
                            </Accordion.Toggle>
                        
                        </Card>
                    }
                    
                    { (credentialsPermissions.card.moneyout) && 
                    <Card className={"card card-in mb-3 card-hover " + ( this.props.store.getPaymentMethod === "card" ? 'active' : '')}>
                        <Accordion.Toggle as={Card.Header} className="card-body" eventKey="0" onClick={(e) => this.setPaymentMethod('card',"0")}>
                            <div className="row">
                                <div className="col-2 text-center">
                                    <img alt="Card" style={{width: '46px'}} className="grey-icon-v2" src={cardIcon}/>
                                </div>
                                <div className="col-10">
                                Instant Funds to your Bank Account
                                <p className="small small-grey-m0">
                                Deposit using you ATM card
                                </p>
                                <div className="card-brands accordion-right-corner">
                                {/*<PaymentMethodsAccepted/>*/}
                                </div>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <PaymentMethodCCForm history={this.props.history} />
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    }
                    
                    { (credentialsPermissions.ach.moneyout) && 
                    <Card className={"card card-in mb-3 card-hover " + ( this.props.store.getPaymentMethod === "ach" ? 'active' : '')}>
                        <Accordion.Toggle as={Card.Header}  className="card-body" eventKey="1" onClick={(e) => this.setPaymentMethod('ach', "1")}>
                            <div className="row">
                                <div className="col-2 text-center">
                                    <img alt="ACH" style={{width: '44px'}} className="grey-icon-v2" src={achIcon}/>
                                </div>
                                <div className="col-10">
                                    Bank Transfer (ACH)
                                    <p className="small small-grey-m0">
                                    Get paid to your bank account with an ACH transfer
                                    </p>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <PaymentMethodECheck history={this.props.history} />
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    }

                    { (credentialsPermissions.digitalcheck?.moneyout) && 
                    <Card className={"card card-in mb-3 card-hover " + ( this.props.store.getPaymentMethod === "digitalcheck" ? 'active' : '')}>
                        <Accordion.Toggle as={Card.Header} className="card-body" eventKey="3" onClick={(e) => this.setPaymentMethod('digitalcheck',"3")}>
                            <div className="row">
                                <div className="col-2 text-center">
                                    <img alt="Card" style={{width: '31px', marginTop: "8px"}} className="grey-icon-v2" src={check}/>
                                </div>
                                <div className="col-10">
                                Digital check
                                <p className="small small-grey-m0">
                                Receive a digital check to your email on file
                                </p>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <div className="row">
                                <div className="col-2">
                                
                                </div>
                                <div className="col-10">
                                    <div className="form-floating">
                                        <input readOnly autoComplete="off" value={this.props.store.paymentPage?.bills?.billList[0]?.vendorEmail} className={"form-control"} placeholder="emailedCheck" />
                                        <label htmlFor="emailedCheck">Remittance email on file</label>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    }

                    { (credentialsPermissions.check.moneyout) && 
                    <Card className={"card card-in mb-3 card-hover " + ( this.props.store.getPaymentMethod === "papercheck" ? 'active' : '')}>
                        <Accordion.Toggle as={Card.Header} className="card-body" eventKey="4" onClick={(e) => this.setPaymentMethod('papercheck',"4")}>
                            <div className="row">
                                <div className="col-2 text-center">
                                    <img alt="Card" style={{width: '31px', marginTop: "8px"}} className="grey-icon-v2" src={check}/>
                                </div>
                                <div className="col-10">
                                Paper check
                                <p className="small small-grey-m0">
                                Receive a paper check to your mailing address on file
                                </p>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                        <Card.Body>
                            <div className="row">
                                <div className="col-2">
                                
                                </div>
                                <div className="col-10">
                                    <div className="form-floating">
                                        <input 
                                            readOnly 
                                            autoComplete="off" 
                                            value={this.props.store.getBillVendorAddress()} 
                                            className={this.props.store.getPaymentPageErrors.paymentMethodsPapercheckAddressError ? "form-control input-error" : "form-control" }
                                            placeholder="paperCheck" 
                                        />
                                        <label htmlFor="paperCheck">Remittance address on file</label>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    }
                    </Accordion>
                    
                </li>,
                order: this.props.store.paymentPage.paymentMethods.order ? this.props.store.paymentPage.paymentMethods.order : 3
                },

                {
                    elements:  <> { (this.props.store.paymentPage.bills && this.props.store.paymentPage.bills.enabled) &&
                        <li className="ui-state-default dragable-panel" id="blockinvoices">
                            <div className="small mb-2">
                                {this.props.store.paymentPage.bills.billList.map((invoice, i) => (
                                    <div key={i} >
                                    <div className="row mb-2">
                                        <div className="offset-sm-2 col-sm-4 mb-2 text-center">
                                            <b>Invoice Number</b><br/>
                                            {invoice.invoiceNumber}
                                        </div>
                                        <div className="col-sm-4 mb-2 text-center">
                                            <b>Due Date</b><br/>
                                            {this.props.store.stringDateFormat(invoice.dueDate)}
                                        </div>
                                    </div>
    
                                    <div className="text-center">
                                        <span onClick={(e) => this.viewInvoiceDetail(e)} style={linkTheme} className="cursor-pointer">{"View invoice details"} {this.state.invoiceDetailsOpen === false ? <BiChevronRight style={{fontSize: "18px"}} /> : <BiChevronDown style={{fontSize: "18px"}} /> }</span>
                                    </div>
                                    {this.state.invoiceDetailsOpen === true &&
                                    <div>
                                        <div className="mb-3 text-right">
                                            <BiFile onClick={(e)=>this.props.store.downloadInvoice(e)} className="cursor-pointer mr-1" style={{fontSize: "16px"}}/>
                                            <BiPrinter onClick={(e)=>this.printPage(e)} className="cursor-pointer" style={{fontSize: "16px"}}/>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-3"><b>Product Service</b></div>
                                            <div className="col-3 text-right"><b>Unit Price</b></div>
                                            <div className="col-3 text-right"><b>Qty</b></div>
                                            <div className="col-3 text-right"><b>Amount</b></div>
                                        </div>
    
                                        {invoice.billItems.map((item, x) => (
                                            <div key={x} className="row top-border-grey-v2" style={{fontSize: '12px'}} >
                                                <div className="col-3" style={{marginTop: '2px'}}>{item.itemProductName ? item.itemProductName : "-"}</div>
                                                <div className="col-3 text-right" style={{marginTop: '2px'}}>
                                                    ${ item.itemCost ? (this.props.store.numberWithCommas(parseFloat(item.itemCost).toFixed(this.props.store.formatDecimalDynamics(item.itemCost)))) : "0.00" }
                                                </div>   
                                                <div className="col-3 text-right" style={{marginTop: '2px'}}>
                                                    { item.itemQty ? item.itemQty : "1" }
                                                </div>
                                                <div className="col-3 text-right" style={{marginTop: '2px'}}>
                                                    ${ item.itemTotalAmount ? (this.props.store.numberWithCommas(parseFloat(item.itemTotalAmount).toFixed(this.props.store.formatDecimalDynamics(item.itemTotalAmount)))) : "0.00" }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    }
                                </div>
                                ))}
                            </div>
                          
                            
                        </li>
                        } </>,
                    order: (this.props.store.paymentPage.invoices && this.props.store.paymentPage.invoices.order) ? this.props.store.paymentPage.invoices.order : 2
                },

                {/*
                elements:  <> { this.props.store.paymentPage.notes.enabled &&
                    <li className="ui-state-default dragable-panel" id="blocknotes">
                         <div className="text-center small">
                         {this.props.store.paymentPage.notes.value ? this.props.store.paymentPage.notes.value : ''}
                         </div>
                    </li>
                    } </>,
                order: this.props.store.paymentPage.notes.order ? this.props.store.paymentPage.notes.order : 0
                */},
                {
                elements:  <>{ this.props.store.paymentPage.review.enabled && 
                    <li className="ui-state-default dragable-panel" id="blockreview">
                        <div className="review-total">
                            <h6 className="sub-header-line mb-3">{/*this.props.store.paymentPage.review.header ? this.props.store.paymentPage.review.header : ''*/}</h6>
                            {/*<div className="row">
                            <div className="col">Net Amount:</div>
                            <div className="col">${this.props.store.numberWithCommas(parseFloat(this.props.store.totalAmount['netAmount']).toFixed(2))}</div>
                            </div>
                            <div className="row">
                            <div className="col">Fee:</div>
                            <div className="col">${this.props.store.numberWithCommas(parseFloat(this.props.store.totalAmount['fee']).toFixed(2))}</div>
                            </div>*/}

                            <div className="row total-amount">
                            <div className="col">Total Amount:</div>
                            <div className="col">${this.props.store.numberWithCommas(parseFloat(this.props.store.totalAmount['totalAmount']).toFixed(2))}</div>
                            </div>
                        </div>
                    </li>
                     }</>,
                order: this.props.store.paymentPage.review.order ? this.props.store.paymentPage.review.order : 0
                },

                {/*
                elements:  <>{ this.props.store.paymentPage.messageBeforePaying.enabled &&
                    <li className="ui-state-default dragable-panel text-center" id="blockmessageBeforePaying">
                         <p className="small" style={{margin:0}} dangerouslySetInnerHTML={{__html: this.props.store.paymentPage.messageBeforePaying.label ? this.props.store.paymentPage.messageBeforePaying.label : '' }}>
                         </p>
                    </li>
                     }</>,
                order: this.props.store.paymentPage.messageBeforePaying.order ? this.props.store.paymentPage.messageBeforePaying.order : 0
                */},
    
                {
                elements:   <li className="ui-state-default dragable-panel" id="blockpaymentButton">
                    <div className="btn-group full-w">
                        <button disabled={this.props.store.defaultPaymentMethodActiveKey !== '' ? false : true} style={btnTheme} type="button" onClick={this.reviewPayment} className="btn btn-success btn-lg">{this.props.store.paymentPage.paymentButton.label} ${this.props.store.numberWithCommas(parseFloat(this.props.store.totalAmount['totalAmount']).toFixed(2))}</button>
                    </div>
                </li>,
                order: this.props.store.paymentPage.paymentButton.order ? this.props.store.paymentPage.paymentButton.order : 0
                },

                {
                elements:  <>{ this.props.store.paymentPage.contactUs.enabled && 
                        <li className="ui-state-default dragable-panel" id="blockcontactUs">
                            <p className="small-grey-m0 text-center ">
                                <b>{this.props.store.paymentPage.contactUs.header ? this.props.store.paymentPage.contactUs.header : ''}</b>
                                <br/>
                                {this.props.store.paymentPage.contactUs.emailLabel ? this.props.store.paymentPage.contactUs.emailLabel : ''} | {this.props.store.paymentPage.contactUs.phoneLabel ? this.props.store.paymentPage.contactUs.phoneLabel : ''}
                            </p>

                        </li>
                        }
                </>,
                order: this.props.store.paymentPage.contactUs.order ? this.props.store.paymentPage.contactUs.order : 0
                },
                    
            ]

        return this.sortBlocks(blocksUI);
    }

    async schedulePaymentMoneyOut(){
        this.props.store.setLoading(true);
        let data = {}
        let authResponse={};
        await this.props.store.schedulePaymentMoneyOut(this.props.match.params.entry).then(res => {
            this.props.store.setLoading(false);
            this.closeConfirmModal(false);
            data = {
                invoiceNumber: this.props.store.paymentPage.bills.billList[0]?.invoiceNumber,
                netAmount: this.props.store.paymentPage.bills.billList[0]?.netAmount,
                paymentMethod: this.props.store.getPaymentMethod
                
            };
            authResponse = res;
        })
        .catch(error => {
            Sentry.captureException(error);
            this.closeConfirmModal(false);
            this.props.store.setLoading(false);
            let errorMessage = "Something is Wrong!";
            if(error.response.data.responseData && error.response.data.responseData.todoAction && error.response.data.responseData.explanation){
                errorMessage = error.response.data.responseData.explanation + ". "+ error.response.data.responseData.todoAction;
            }
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });

        // capture & send vcard link

        try {
            if (this.props.store.getPaymentMethod.toLowerCase() === 'vcard') {
                const authPageIdentifier = authResponse?.data?.pageIdentifier;
                const transId = authResponse?.data?.responseData?.referenceId;
    
                if (transId && authPageIdentifier) {
                    // capture
                    const resCapture = await this.props.store.captureTransactionOut(transId, authPageIdentifier);
                    const capturePageIdentifier = resCapture?.data?.pageIdentifier;
    
                    if (!capturePageIdentifier) {
                        throw new Error('We do not have enough information to send a Virtual Card link.');
                    }
    
                    // Send the VCard link
                    await this.props.store.sendVCardLink(transId, capturePageIdentifier);
    
                } else {
                    throw new Error('We do not have enough information to capture this transaction.');
                }
            }
        } catch (error) {
            toast.error(error, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        }

        this.props.history.push("/vendorpaymentlink/test/4851-f10f99c3-7aaf-4465-aa4d-145793df8ac0-2498/receipt/" + encodeURIComponent(window.btoa(JSON.stringify(data))));
        
    }

    render() {
        const bgTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
            height: "50%"
        };

        const linkTheme = {
            color: this.props.store.paymentPageSettings.color,
        };

        const btnTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
            border: "none",
        };

       return (
           <div>

                <Modal style={{textAlign: "center"}} show={this.state.confirmModalIsOpen} onHide={()=>this.closeConfirmModal()}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body>
                        <BiCheck className="icon-modal"/>
                        <h5>Confirm</h5>
                        <p className="small">Confirm that you want to schedule a payment using <br/><b>{this.props.store.getPaymentMethodName()}?</b></p>
                        
                        <div className='row'>
                            <div className='col-sm-6 mb-3'>
                                <button className="btn cancel-btn full-w" onClick={() => this.closeConfirmModal()}>
                                Cancel
                                </button>
                            </div>
                            <div className='col-sm-6 mb-3'>
                                <button onClick={()=>this.schedulePaymentMoneyOut()} className="btn btn-primary full-w">
                                    Accept
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            
                <Modal style={{textAlign: "center"}} show={!this.state.infoModalIsOpen} onHide={this.closeInfoModal}  size="sm" centered>
                <Modal.Body>
                    <BiMessageSquareError className="icon-modal"/>
                    <h5>Info</h5>
                    <p className="small">{this.state.infoMessageInModal}</p>
                    <Button className="btn" style={btnTheme} onClick={this.closeInfoModal}>
                    Close
                    </Button>
                </Modal.Body>
                </Modal>

                

                <Modal style={{textAlign: "center"}} show={this.state.selectVcardModeModalOpen} onHide={this.closeVcardModal}  size="sm" centered>
                <Modal.Body>
                    <BiLockOpen className="icon-modal"/>
                    <h5>Verify</h5>
                    <p className="small">Please select how to verify yourself</p>
                    
                        <div className="row mb-3">
                            <div className="col-4">
                                <div className="icheck-primary">
                                    <input
                                        name="howVerify"
                                        type="radio"
                                        id="howToVerifyMfa"
                                        value="mfa"
                                        checked={this.state.howToVerify === "mfa" ? true : false}
                                        onChange={(e) => this.selectHowVerify(e)}
                                    />
                                    <label htmlFor="howToVerifyMfa">MFA</label>
                                </div>
                            </div>
                            <div className="col-8">
                                 <div className="icheck-primary">
                                    <input
                                        name="howToVerify"
                                        type="radio"
                                        id="howToVerifyPin"
                                        value="pin"
                                        checked={this.state.howToVerify === "pin" ? true : false}
                                        onChange={(e) => this.selectHowVerify(e)}
                                    />
                                    <label htmlFor="howToVerifyPin">PIN number</label>
                                </div>
                            </div>
                        </div>

                        {this.state.howToVerify === "pin" &&
                        <div className="mb-3">
                            <div className="form-floating">                                
                                <IMaskInput
                                    mask={this.props.store.maskValidator('numbers')}
                                    name="pinNumberInput"
                                    value={ this.state.pinNumber }
                                    unmask={true}
                                    onAccept={
                                        (value, mask) => this.handleTextChangeMask('pinNumber', value)
                                    }
                                    placeholder="PIN Number"
                                    className="form-control"
                                    id="pinNumberInput"
                                    maxLength={6}
                                />
                                <label htmlFor="pinNumberInput">PIN Number</label>
                            </div>
                        </div>
                        }


                        <div className="row">
                            <div className="col-6">
                                <button className="btn btn-light full-w" type="button" onClick={this.closeVcardModal}>
                                    Close
                                </button>
                            </div>
                           
                            <div className="col-6">
                                <Button disabled={(this.state.howToVerify === "mfa" || (this.state.howToVerify === "pin" && this.state.pinNumber.length === 6)) ? false : true} className="btn full-w" style={btnTheme} onClick={this.showVcardModalImage}>
                                    Verify
                                </Button>
                            </div>
                        </div>
                   
                </Modal.Body>
                </Modal>
                
                
                <Modal style={{textAlign: "center"}} show={this.state.vcardImageModalOpen} onHide={this.closeVcardModalImage}  size="md" centered>
                <Modal.Body>
                    <h5 className="mt-3">Hey, Walt Disney</h5>
                    <p style={{fontSize: "10px"}}>For security purposes, this page will disappear in 5 minutes. <br/>
                    Please see below your Virtual Commercial Prepaid Visa Card.
                    </p>
                    <h2 className="mb-3 mt-3">${this.props.store.numberWithCommas(parseFloat(this.props.store.totalAmount['totalAmount']).toFixed(2))}</h2>
                    <img className="mb-3" title="VCard" src={sentryVcard} style={{width: "100%", maxWidth: "400px"}} alt=""/>
                    <button className="btn btn-light full-w" onClick={(e)=>this.closeVcardModalImage()}>Close</button>
                </Modal.Body>
                </Modal>



            <div className="bg-decorator" style={bgTheme}>&nbsp;</div>
           <div className="mt-body3">

           <div className="d-flex flex-column justify-content-center align-items-center">

               <div id="vterminal-container" className="card">
                   <div className="card-body  main-cont">
                       <ul className="sorted" ref={this.sortablePayer}>
                        { this.renderSortBlocks().map((block, i) => (
                            (<div key={i}>
                            {block.elements}
                            </div>)
                        ))}

                       </ul>
                   </div>

                   { this.props.store.paymentPage.contactUs.paymentIcons && 
                   <div className="card-brands text-center bottom-grey mt-3">
                       <PaymentMethodsAccepted/>
                   </div>
                   }
               </div>
               <p className="small mt-5">Powered by  <a rel="noreferrer" style={linkTheme} target="_blank" href="https://www.payabli.com/" className="small no-underline">Payabli</a></p>

           </div>

           </div>
           {(this.props.store && this.props.store.paymentPageSettings.customCssUrl) &&
            <Helmet>
            <link rel="stylesheet" href={this.props.store.paymentPageSettings.customCssUrl}/>
            </Helmet>
            }
            <ToastContainer transition={Bounce} />
           </div>
       );
   }
}

export { VendorPayLink };