import React from 'react';
import { inject, observer } from 'mobx-react';
import { IMaskInput } from 'react-imask';
import { BiInfoCircle } from 'react-icons/bi';
import { OverlayTrigger, Tooltip, Col } from "react-bootstrap";
import { BiCheckCircle } from 'react-icons/bi';
import * as Sentry from "@sentry/react";

@inject('store')
@observer
class PhoneInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      name: '',
      component: '',
      error: false,
      errorText: '',
      isSuccess: false,
      isNewScreen: true,
      mouseIn: false,
      iconLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.builderInput = this.builderInput.bind(this);
    this.focus = this.focus.bind(this);
    this.mouseIn = this.mouseIn.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
  }

  mouseIn() {
    this.setState({
      mouseIn: true,
    });
  }

  mouseOut() {
    this.setState({
      mouseIn: false,
    });
  }

  focus(e) {
    e.target.focus();
    e.target.setSelectionRange(0, 1000000000);
  }

  componentDidMount() {
    const validators = this.props.store.validators;
    const error = (this.props.iRequired && validators.isEmpty(this.props.iValue));
    this.setState({
      component: this.props.iComponent,
      value: this.props.iValue,
      name: this.props.iName,
      errorText: '',
      error: error,
      isSuccess: false,
    }, () => {
      // this.props.boarding.setTemplateData(this.state.name, this.state.value);
      // this.props.boarding.setErrorData(this.state.name, this.state.error);
      // this.props.store.setPaymentPageError(this.state.name, this.state.error);
    });
    if (this.props.iValue) {
      this.setState({ iconLoading: true, errorText: '', isSuccess: false, });
    //   this.validatePhone(this.props.iValue).then((result) => {
    //     const responseData = result?.responseData;
    //     this.setState({
    //       error: responseData?.status !== "400" && responseData?.status === 'Active' ? false : true,
    //       errorText: responseData?.status === "400" ? (responseData?.errors.length && responseData?.errors[0]) || 'Number must be a valid phone number.' : '',
    //       isSuccess: responseData?.status !== "400" && result.isSuccess,
    //       iconLoading: false
    //     }, () => {
    //       // this.props.boarding.setTemplateData(this.state.name, this.state.value);
    //       // this.props.boarding.setErrorData(this.state.name, this.state.error);
    //       // this.props.store.setPaymentPageError(this.state.name, this.state.error);
    //     });
    //   });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.value !== undefined && prevState.value !== this.state.value) || (this.props.iValue !== prevProps.iValue) ) {
      if (this.state.value?.length === 14 || this.props.iValue?.length === 14 || this.state.value?.length === 10 || this.props.iValue?.length === 10 ){
          this.setState({value: this.props.iValue !== prevProps.iValue ? this.props.iValue : this.state.value}, ()=> {
            if( this.props.iOnChange) this.props.iOnChange("phone", this.state.value);
            this.handleBlur();
          })
      }
    }
  }

  handleChange(name, event, value) {
    const validators = this.props.store.validators;
    const error = validators.isEmpty(value.value) || (value.value.length > 0 && value.value.length < 14);
    this.setState(
      {
        value: value.value,
        isNewScreen: false,
        errorText: '',
        isSuccess: false,
        error: error
      }, () => {
        // this.props.boarding.setTemplateData(this.state.name, this.state.value);
        // this.props.boarding.setErrorData(this.state.name, this.state.error);
        this.props.store.setPaymentPageError(this.state.name, this.state.error);
      }
    );
  }
  handleBlur() {
    this.setState({ iconLoading: true, errorText: '', isSuccess: false });
    // this.validatePhone(this.state.value).then((result) => {
    //   const responseData = result?.responseData;
    //   this.setState({
    //     error: responseData?.status !== "400" && responseData?.status === 'Active' ? false : true,
    //     errorText: responseData?.status === "400" ? (responseData?.errors?.length && responseData?.errors[0]) || 'Number must be a valid phone number.' : '',
    //     isSuccess: responseData?.status !== "400" && result.isSuccess,
    //     iconLoading: false
    //   }, () => {
    //     if(this.props.iSetError) this.props.iSetError(this.state.error);
    //     this.props.store.setPaymentPageError(this.state.name, this.state.error);
    //   });
    // });
  }
  async validatePhone(value){
    let token = process.env.REACT_APP_TOKEN;
    let host = process.env.REACT_APP_URL_API;
    var myHeaders = new Headers();
    myHeaders.append("requestToken", token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(`+1${value}`);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    var result = {}
    await fetch(`${host}Tools/validatePhone`, requestOptions)
    .then(response => response.text())
    .then(res => {
        result = JSON.parse(res)
    })
    .catch(error => {
        Sentry.captureException(error);
        result = {
            status: 400,
            data: error.message
        }
    });
    return result;
  }
  builderInput() {
    const error = this.state.error=== true;
    return (
      <>
        {!this.props.iCustomClass && (this.props.iToolTip || this.state.errorText !== '') && (!this.state.iconLoading) &&
          <OverlayTrigger placement="top" overlay={
            <Tooltip>
              {this.state.errorText !== '' ? this.state.errorText : this.props.iToolTip}</Tooltip>
            }>
            <BiInfoCircle color={(this.state.errorText !== '' && 'red') || 'black'} className='info-icon in-input inner-addon'/>
          </OverlayTrigger>
        }
        {!this.props.iCustomClass && (this.state.errorText === '' && this.state.isSuccess) &&
          <BiCheckCircle color='limegreen' className='info-icon in-input inner-addon' />
        }
        <div className={error || this.state.error  ? 'form-floating loading' : 'form-floating loading'} >
          <IMaskInput
            mask={
              '(000) 000-0000'
            }
            name={this.props.iName}
            value={this.state.value ? String(this.state.value) : ''}
            autoComplete='off'
            defaultValue=''
            type={this.props.iType ? this.props.iType : 'text'}
            unmask={true}
            onAccept={(event, value) => this.handleChange(this.props.iName, event, value)}
            placeholder={this.props.iTitle + (this.props.iRequired === true ? ' *' : '')}
            className={(error && !this.state.isNewScreen) || (this.state.error && this.props.store.isClickedPay) ? 'form-control input-error' : 'form-control'}
            readOnly={this.props.iReadonly}
            id={this.props.iName}
            onMouseEnter={this.mouseIn}
            onMouseLeave={this.mouseOut}
          />
          <label htmlFor={this.props.iName}>{this.props.iTitle} {[null, true].includes(this.props.iRequired) ?  " *": ""}</label>
          {this.props.iCustomClass && 
            <div className='icon-container-10'>
              {this.props.iCustomClass && (this.props.iToolTip || this.state.errorText !== '') && (!this.state.iconLoading) &&
                <OverlayTrigger placement="top" overlay={
                  <Tooltip>
                    {this.state.errorText !== '' ? this.state.errorText : this.props.iToolTip}</Tooltip>
                  }>
                  <BiInfoCircle color={(this.state.errorText !== '' && 'red') || 'black'} />
                </OverlayTrigger>
              }
              {this.props.iCustomClass && (this.state.errorText === '' && this.state.isSuccess) &&
                <BiCheckCircle color='limegreen' />
              }
            </div>
          }
          {this.state.iconLoading &&
            <div className='icon-container'>
              <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
          }
        </div>
      </>
    )
  }
  render() {
    return (
      <>
        <Col style={{position: "relative"}} md={this.props.full ? "12" : "6"}>
            <div className="mb-4">
                {this.builderInput()} 
            </div>
        </Col>

      </>
    );
  }
}

export default PhoneInput;

