import React from 'react';
import { inject, observer } from 'mobx-react';
import { PaymentMethodsAccepted } from '../components/PaymentMethodsAccepted';
import { BiMessageSquareCheck, BiMessageSquareX} from "react-icons/bi";
import calendar from '../assets/images/calendar.svg';
import {Helmet} from "react-helmet";
import { toJS } from 'mobx';
import { PaymentPageLogo } from '../components/PaymentPageLogo';

@inject('store')
@observer
class Receipt extends React.Component {

    constructor(props) {
        super(props);
        this.finalRedirect = this.finalRedirect.bind(this);
        this.serializeToUrl = this.serializeToUrl.bind(this);
    }

    prevent(e) {
        e.preventDefault();
    } 
    
    serializeToUrl(obj){
        var str = [];
        for (var p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
        return str.join("&");
    }

    componentDidMount() {
        this.props.store.setCurrentPage('payment-page-receipt');
        let subdomain = this.props.match.params.subdomain;      
        let entrypoint = this.props.match.params.entry;
        let tokenid = this.props.match.params.tokenid;
        if (subdomain && entrypoint) {
            if(this.props.store.paymentResponse === null){
                this.props.history.push("/"+entrypoint+"/"+subdomain);
            }
            //this.props.store.getPaymentPageFromApi(subdomain);
        }else if(tokenid){
            if(this.props.store.paymentResponse === null){
                this.props.history.push("/");
            }
        }
        else{
            this.props.history.push("/");
        }
    }

    finalRedirect(){
        let urlToRedirect = "";
        
        let dataInjectedBase64 = this.props.match.params.datainjectedjson;
        let datainjectedQueryString = this.props.location.search;
        let queryStringParams = new URLSearchParams(datainjectedQueryString);
        let urlToRedirectQueryString = queryStringParams.get("callbackUrl") ? queryStringParams.get("callbackUrl") : null;

        if(dataInjectedBase64 || urlToRedirectQueryString !== null){
            let jsonDataInject = {};
            try{

                if(dataInjectedBase64){
                    dataInjectedBase64 = dataInjectedBase64.replaceAll('.', '+');
                    dataInjectedBase64 = dataInjectedBase64.replaceAll('_', '/');
                    jsonDataInject = JSON.parse(window.atob(dataInjectedBase64).toString(), 1);
                    if(jsonDataInject.callbackUrl){
                        urlToRedirect = String(jsonDataInject.callbackUrl)+ "?" + this.serializeToUrl(toJS(this.props.store.paymentResponse));
                    }
                }else if(urlToRedirectQueryString){
                    urlToRedirect = String(urlToRedirectQueryString)+ "?" + this.serializeToUrl(toJS(this.props.store.paymentResponse));
                }
                
            }
            catch(e){
                console.log(e);
                console.log("JSON parameter is malformed")
            }
        }
        else{
            if(this.props.store.paymentPageSettings.redirectAfterApprove && this.props.store.paymentPageSettings.redirectAfterApproveUrl){
                urlToRedirect = this.props.store.paymentPageSettings.redirectAfterApproveUrl + "?" + this.serializeToUrl(toJS(this.props.store.paymentResponse));
            }
            else{
                let subdomain = this.props.match.params.subdomain;      
                let entrypoint = this.props.match.params.entry;
                if (subdomain && entrypoint) {
                    let subdomain = this.props.match.params.subdomain;      
                    let entrypoint = this.props.match.params.entry;
                    urlToRedirect = window.location.origin + "/" + entrypoint + "/" + subdomain;
                    
                }
            }
        }


        
        window.location.href = urlToRedirect;
    }

    sortBlocks(inputArr){
        let len = inputArr.length;
        for (let i = 0; i < len ; i++) {
            for (let j = 0; j < len - 1 ; j++) {
                if (inputArr[j].order > inputArr[j + 1].order) {
                    let tmp = inputArr[j];
                    inputArr[j] = inputArr[j + 1];
                    inputArr[j + 1] = tmp;
                }
            }
        }
        return inputArr;
    }
    fieldsInPaymentPage(){
        let payorFields = this.props.store.paymentPage.payor.fields;
        let receiptFields = this.props.store.paymentReceipt.settings.fields;
        payorFields = payorFields?.filter(field => field.value !== "" && field.value !== null);
        if(!payorFields || !receiptFields) return [];
        let resultFields = payorFields.filter(a => receiptFields.some(b => a.name === b.name && b.display === true ));
        return resultFields;
    }
    renderSortBlocks(){
        const bgTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
        };
        const btnTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
        };
        
        var blocksUI = [
                {
                    elements: <>{ this.props.store.paymentReceipt.logo.enabled &&
                        <li className="ui-state-default dragable-panel" id="blocklogo" style={{paddingBottom: 0}}> 
                        <div className="text-center">
                            <PaymentPageLogo/>
                        </div>
                        </li>
                        }</> ,
                    order: this.props.store.paymentReceipt.logo.order ? this.props.store.paymentReceipt.logo.order : 0
                },

                {
                    elements: <>{ this.props.store.paymentReceipt.page.enabled &&
                        <li className="ui-state-default dragable-panel" id="blockpage" style={{paddingTop: 0}}>
                            <div className={ !this.props.store.paymentReceipt.page.enabled ? 'opacity-panel' : ''}>
                                <div className="row mb-3">
                                    <div className="col-md-8 offset-md-2 progress-steps mb-3">
                                        <div className="row">
                                            <div style={bgTheme} className="col active">
                                                &nbsp;
                                            </div>
                                            <div style={bgTheme} className="col active">
                                                &nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="card-title text-center">{this.props.store.paymentReceipt.page.header ? this.props.store.paymentReceipt.page.header : ''}</h3>
                                        <p className="small small-grey text-center m0" dangerouslySetInnerHTML={{__html: this.props.store.paymentReceipt.page.description ? this.props.store.paymentReceipt.page.description : '' }}></p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        }</> ,
                    order: this.props.store.paymentReceipt.page.order ? this.props.store.paymentReceipt.page.order : 0
                },

                {
                    elements: <li className="ui-state-default dragable-panel text-center" id="blockamount">
                                    <div className="big-amount">${this.props.store.numberWithCommas(parseFloat(this.props.store.totalAmount['totalAmount']).toFixed(2))}</div>
                                    { this.props.store.isAutopay ?
                                        <>
                                        {(this.props.store.paymentResponse && this.props.store.paymentResponse.toLowerCase() === "success") &&
                                        <p><BiMessageSquareCheck className="mb-3" style={{fontSize: '80px', color: "rgb(136,201,82)"}}/> <br/><b>Payment Approved</b></p>
                                        }
                                        {(this.props.store.paymentResponse && this.props.store.paymentResponse.toLowerCase() !== "success") &&
                                        <p><BiMessageSquareX className="mb-1" style={{fontSize: '80px', color: "rgb(255, 100, 82)"}}/> <br/><b>Payment Declined</b></p>
                                        }
                                        
                                        {(this.props.store.paymentResponseData && this.props.store.paymentResponseData.resultText) &&
                                            <p className="small mb-1">{this.props.store.paymentResponseData.resultText}</p>
                                        }
                                        {(this.props.store.paymentResponseData && this.props.store.paymentResponseData.referenceId) &&
                                            <p className="small-grey mb-1 mt-0">Reference Id: {this.props.store.paymentResponseData.referenceId}</p>
                                        }
                                        {(this.props.store.paymentResponse && this.props.store.paymentResponse.toLowerCase() !== "success" && this.props.store.paymentResponseData && 
                                            this.props.store.paymentResponseData.avsResponseText && this.props.store.paymentResponseData.avsResponseText !== '') &&
                                            <p className="small-grey mb-1 mt-0">AVS: {this.props.store.paymentResponseData.avsResponseText}</p>
                                        }             
                                        {(this.props.store.paymentResponse && this.props.store.paymentResponse.toLowerCase() !== "success" && this.props.store.paymentResponseData && 
                                            this.props.store.paymentResponseData.cvvResponseText && this.props.store.paymentResponseData.cvvResponseText !== '') &&
                                            <p className="small-grey mb-1 mt-0">CVV: {this.props.store.paymentResponseData.cvvResponseText}</p>
                                        } 
                                        </>
                                       :
                                       <>
                                       {(this.props.store.paymentResponse && this.props.store.paymentResponse.resultCode === 1) &&
                                       <p><BiMessageSquareCheck className="mb-3" style={{fontSize: '80px', color: "rgb(136,201,82)"}}/> <br/><b>Payment Approved</b></p>
                                       }
                                       {(this.props.store.paymentResponse && this.props.store.paymentResponse.resultCode !== 1) &&
                                       <p><BiMessageSquareX className="mb-3" style={{fontSize: '80px', color: "rgb(255, 100, 82)"}}/> <br/><b>Payment Declined</b></p>
                                       }

                                        {(this.props.store.paymentResponseData && this.props.store.paymentResponseData.resultText) &&
                                            <p className="small mb-1">{this.props.store.paymentResponseData.resultText}</p>
                                        }
                                        {(this.props.store.paymentResponseData && this.props.store.paymentResponseData.referenceId) &&
                                            <p className="small-grey mb-1 mt-0">Reference Id: {this.props.store.paymentResponseData.referenceId}</p>
                                        }
                                        {(this.props.store.paymentResponseData && this.props.store.paymentResponseData.authCode) &&
                                            <p className="small-grey mb-1 mt-0">Auth Code: {this.props.store.paymentResponseData.authCode}</p>
                                        } 
                                        {(this.props.store.paymentResponse && this.props.store.paymentResponse.resultCode !== 1 && this.props.store.paymentResponseData && 
                                            this.props.store.paymentResponseData.avsResponseText && this.props.store.paymentResponseData.avsResponseText !== '') &&
                                            <p className="small-grey mb-1 mt-0">AVS: {this.props.store.paymentResponseData.avsResponseText}</p>
                                        }             
                                        {(this.props.store.paymentResponse && this.props.store.paymentResponse.resultCode !== 1 && this.props.store.paymentResponseData && 
                                            this.props.store.paymentResponseData.cvvResponseText && this.props.store.paymentResponseData.cvvResponseText !== '') &&
                                            <p className="small-grey mb-1 mt-0">CVV: {this.props.store.paymentResponseData.cvvResponseText}</p>
                                        }                            
                                       </>
                                    }
                                    
                                </li>
                    ,
                    order: this.props.store.paymentReceipt.amount.order ? this.props.store.paymentReceipt.amount.order : 0
                },

                {
                    elements: <>{ this.props.store.paymentReceipt.settings.enabled &&
                                <li className="ui-state-default dragable-panel" id="blocksettings"> 
                                {this.fieldsInPaymentPage() && 
                                    <div className="review-total">
                                        <div className="row">
                                            {this.fieldsInPaymentPage().map((block, i) => (                                           
                                                ((block.display || block.required) &&
                                                    <div className={`col-sm-12 mb-1 text-center text-muted`} key={i}>
                                                        <b>{block.label || block.name}:</b> {block.value} 
                                                    </div>
                                                )
                                            ))}
                                    </div>
                                    </div>      
                                }
                            </li>
                            }
                            </>
                    ,
                    order: this.props.store.paymentReceipt.settings.order ? this.props.store.paymentReceipt.settings.order : 0
                },

                {
                    elements: <>{ this.props.store.paymentReceipt.details.enabled &&
                            <li className="ui-state-default dragable-panel" id="blockdetails">
                            <div className="review-total">
                                <div className="row">
                                <div className="col"><b>Payment Details</b></div>
                                <div className="col"></div>
                                </div>

                                { (this.props.store.paymentPage.invoices && this.props.store.paymentPage.invoices.invoiceList[0] && this.props.store.paymentPage.invoices.invoiceList[0].invoiceNumber ) &&
                                    <div className="row mt-2">
                                    <div className="col">Invoice #:</div>
                                    <div className="col">{this.props.store.paymentPage.invoices.invoiceList[0].invoiceNumber}</div>
                                    </div>
                                }

                                <div className="row mt-2">
                                <div className="col">Payment:</div>
                                <div className="col">${this.props.store.numberWithCommas(parseFloat(this.props.store.totalAmount['netAmount']).toFixed(2))}</div>
                                </div>

                                <div className="row mt-2">
                                <div className="col">Fee:</div>
                                <div className="col">${this.props.store.numberWithCommas(parseFloat(this.props.store.totalAmount['fee']).toFixed(2))}</div>
                                </div>

                                <div className="top-border-grey mt-2">
                                <div className="row total-amount">
                                    <div className="col">Total Amount:</div>
                                    <div className="col">${this.props.store.numberWithCommas(parseFloat(this.props.store.totalAmount['totalAmount']).toFixed(2))}</div>
                                </div>
                                </div>
                            </div>
                        </li>
                            }
                            </>
                    ,
                    order: this.props.store.paymentReceipt.details.order ? this.props.store.paymentReceipt.details.order : 0
                },

                {
                    elements: <>{ this.props.store.paymentReceipt.paymentInformation.enabled &&
                            <li className="ui-state-default dragable-panel small" id="blockpaymentInformation">
                                
                            <div className="mb-3"><b>Payment Information</b></div>
                      
                            <div className="mb-2">
                            {(this.props.store.getPaymentMethod.toLowerCase() === 'card') &&
                                    <>
                                        {this.props.store.getPaymethodImg(this.props.store.creditCardType(this.props.store.paymentPage.paymentMethods.cardNumber))}
                                    &nbsp;&nbsp;&nbsp;{this.props.store.paymentPage.paymentMethods.cardNumber ? this.props.store.maskedCardNumber(this.props.store.paymentPage.paymentMethods.cardNumber, "v3") : ''}
                                    </>
                            }
                            {(this.props.store.getPaymentMethod.toLowerCase() === 'ach') &&
                                <>
                                    {this.props.store.getPaymethodImg()} &nbsp;&nbsp;&nbsp; Bank account / E-Check
                                    </>
                            }
                            {(this.props.store.getPaymentMethod.toLowerCase() === 'applepay') &&
                                <>
                                {this.props.store.getPaymethodImg('applepay')} &nbsp;&nbsp;&nbsp; Apple Pay
                                </>
                            }
                            </div>

                            <div className="bottom-border-grey">
                                <img style={{width: '18px', marginRight: "15px"}} alt="" src={calendar}/>
                                {!this.props.store.isAutopay ?
                                        <small>
                                            Paid on {this.props.store.stringDateFormat(new Date().toString())}, {this.props.store.stringTimeFormat(new Date().toString())}
                                        </small>
                                :
                                        <small>
                                            Next date {this.props.store.stringDateFormat(this.props.store.paymentPage.autopay.startDate)}
                                        </small>
                                }
                                <br/><br/>
                            </div>
                            </li>
                            }
                            </>
                    ,
                    order: this.props.store.paymentReceipt.paymentInformation.order ? this.props.store.paymentReceipt.paymentInformation.order : 0
                },

                {
                    elements: <>{ this.props.store.paymentReceipt.messageBeforeButton.enabled &&
                        <li className="ui-state-default dragable-panel" id="blockmessageBeforeButton">
                        <p className="small small-grey" style={{margin:0}} dangerouslySetInnerHTML={{__html: this.props.store.paymentReceipt.messageBeforeButton.label ? this.props.store.paymentReceipt.messageBeforeButton.label : '' }}>
                        </p>
                        </li>
                        }
                            </>
                    ,
                    order: this.props.store.paymentReceipt.messageBeforeButton.order ? this.props.store.paymentReceipt.messageBeforeButton.order : 0
                },

            {
            
                    elements: <li className="ui-state-default dragable-panel" id="blockpaymentButton">
                                <div className="btn-group full-w">
                                    {this.props.store.paymentPage.invoices ? 
                                    <button style={btnTheme} type="button" onClick={(e)=> this.props.store.downloadInvoice(e)} className="btn btn-success btn-lg">Download Invoice</button>
                                    :
                                    <button style={btnTheme} type="button" onClick={this.finalRedirect} className="btn btn-success btn-lg">{this.props.store.paymentReceipt.paymentButton.label}</button>
                                    }
                                </div>
                            </li>

                    ,
                    order: this.props.store.paymentReceipt.paymentButton.order ? this.props.store.paymentReceipt.paymentButton.order : 0
                    
                },

                {
                    elements: <>{ this.props.store.paymentReceipt.contactUs.enabled &&
                        <li className="ui-state-default dragable-panel" id="blockcontactUs">   
                            <p className="small-grey-m0 text-center ">
                                <b>{this.props.store.paymentPage.contactUs.header ? this.props.store.paymentPage.contactUs.header : ''}</b>
                                <br/>
                                {this.props.store.paymentPage.contactUs.emailLabel ? this.props.store.paymentPage.contactUs.emailLabel : ''} | {this.props.store.paymentPage.contactUs.phoneLabel ? this.props.store.paymentPage.contactUs.phoneLabel : ''}
                            </p>
                        </li>
                        }
                            </>
                    ,
                    order: this.props.store.paymentReceipt.contactUs.order ? this.props.store.paymentReceipt.contactUs.order : 0
                },
            ];

        return this.sortBlocks(blocksUI);
    }


    render() {
        const bgTheme = {
            backgroundColor: this.props.store.paymentPageSettings.color,
            height: "50%"
        };
        const linkTheme = {
            color: this.props.store.paymentPageSettings.color,
        };
       
        return (
            <div>
            <div className="bg-decorator" style={bgTheme}>&nbsp;</div>
            <div className="mt-body3">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <div id="vterminal-container" className="card">
                    <div className="card-body  main-cont">
                        <ul className="sorted" ref={this.sortablePayer}>
                            { this.renderSortBlocks().map((block, i) => (
                                (<div key={i}>
                                {block.elements}
                                </div>)
                            ))}
                       </ul>
                    </div>

                    { this.props.store.paymentPage.contactUs.paymentIcons && 
                    <div className="card-brands text-center bottom-grey mt-3">
                        <PaymentMethodsAccepted/>
                    </div>
                    }
                </div>
                <p className="small mt-5">Powered by  <a rel="noreferrer" style={linkTheme} target="_blank" href="https://www.payabli.com/" className="small no-underline">Payabli</a></p>
            </div>
            </div>
            {(this.props.store && this.props.store.paymentPageSettings.customCssUrl) &&
            <Helmet>
            <link rel="stylesheet" href={this.props.store.paymentPageSettings.customCssUrl}/>
            </Helmet>
            }
            </div>
        );
    }
}

export { Receipt };