import React from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from "mobx";
import NumberFormat from 'react-number-format';

@inject('store')
@observer
class AmountCategories extends React.Component {

    constructor(props) {
        super(props);
        this.renderAmountCategories = this.renderAmountCategories.bind(this);
        this.focus = this.focus.bind(this);
    }

    focus(e){
        e.target.focus();
        e.target.setSelectionRange(0, 1000000000);
    }

    renderAmountCategories(){
        var loopData = [];
        var categoriesConfigSort =this.props.store.sortFields(Object.entries(toJS(this.props.store.amountCategories)));
        var categoriesCount = categoriesConfigSort.length;
        var reactObject = this;
        categoriesConfigSort.forEach(function (item, index) {
            if(categoriesCount > 1) {
            
                loopData.push(
                    <li key={"keyAmountCategory"+item[0]} className="ui-state-default dragable-panel" id={"blockcategory"+item[0]} style={{position:"relative"}}>
                        <div className="form-floating form-floating-money">
                            <NumberFormat
                                onFocus={(e)=>reactObject.focus(e)} 
                                thousandsGroupStyle="thousand"
                                decimalSeparator="."
                                displayType="input"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={false}
                                decimalScale={2}
                                fixedDecimalScale={true}

                                value={item[1].value}
                                placeholder={item[1].label}
                                className={ (reactObject.props.store.getPaymentPageErrors[item[1].name]) ? "form-control input-money input-error": "form-control input-money" }
                                onValueChange={(values) => reactObject.handleCategoryTextChangeMask(item[0],"value", values.value)}
                                readOnly={ (item[1].type === 'fixed' || item[1].type === 'quantity') ? true : false }
                            />
                            <label>{item[1].label}</label>
                        </div>
                        { item[1].optionalPay  &&
                        <div className="icheck-primary optional-pay">
                            <input type="checkbox" id={"amountCategoryOptionalPay"+item[0]} onChange={(e) => reactObject.handleCategoryOptionalPay(e)} />
                            <label htmlFor={"amountCategoryOptionalPay"+item[0]}></label>
                        </div>
                        }

                        { item[1].type === 'quantity'  &&
                        <div className={ item[1].optionalPay ? 'input-group input-group-qty input-group-qty-mr' : 'input-group input-group-qty' } >
                            <button id={"amountCategoryQtySubstract"+item[0]} className="btn btn-outline-light btn-sm" type="button" onClick={(e) => reactObject.handleCategoryQtySubstract(e)}>-</button>
                            <input type="text" className="form-control" placeholder="" aria-label="Example text with button addon" readOnly value={item[1].quantity}/>
                            <button id={"amountCategoryQtyAdd"+item[0]} className="btn btn-outline-light btn-sm" type="button" onClick={(e) => reactObject.handleCategoryQtyAdd(e)}>+</button>
                        </div>
                        }

                        { (item[1].description && item[1].showDescription)  ? <p className="small-grey-m0 mt-1">{item[1].description}</p> : ''}

                    </li>
                )
            }else{
                loopData.push(
                    <li key={"keyAmountCategory"+item[0]} className="ui-state-default dragable-panel" id={"blockcategory"+item[0]}>
                    <NumberFormat
                        onFocus={(e)=>reactObject.focus(e)} 
                        thousandsGroupStyle="thousand"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        type="text"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}

                        value={item[1].value}
                        placeholder={"$"}
                        className="big-amount"
                        onValueChange={(values) => reactObject.handleCategoryTextChangeMask(item[0],"value", values.value)}
                        readOnly={(item[1].type === 'fixed' || item[1].type === 'quantity') ? true : false }
                    />
                     </li>
                )
            }
        });
        
        return loopData;
    }

    render() {
        
        return (
            <>{this.renderAmountCategories()}</>
        )
    }
}

export { AmountCategories };