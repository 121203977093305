import React from 'react';
import { inject, observer } from 'mobx-react';


@inject('store')
@observer
class PaymentPageLogo extends React.Component {

    render() {
        return (
            <>
            <img alt="" className="header-brand" src={ this.props.store.getPaymentPageLogo.furl } />
            </>
        )
    }
}

export { PaymentPageLogo };