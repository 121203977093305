import React from 'react';
import { inject, observer } from 'mobx-react';
import { Accordion , Card, Modal, Button } from 'react-bootstrap';
import { BiChevronDown, BiChevronRight, BiFile, BiLockOpen, BiMessageSquareError, BiPrinter } from "react-icons/bi";
import { PaymentMethodCCForm } from '../components/PaymentMethodCCForm';
import { PaymentMethodECheck } from '../components/PaymentMethodECheck';
import { PaymentMethodsAccepted } from '../components/PaymentMethodsAccepted';
import {IMaskInput} from 'react-imask';
import { toJS } from "mobx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Helmet} from "react-helmet";
import NumberFormat from 'react-number-format';
import achIcon from '../assets/images/ach.svg';
import cardIcon from '../assets/images/card.svg';
import check from '../assets/images/paper-check.svg';
import payhoa from '../assets/images/payhoa-logo.svg';
import visa from '../assets/images/visa.svg';
import payhoaVcard from '../assets/images/payhoaCard.png';
import PhoneInput from '../components/PhoneInput';
import * as Sentry from "@sentry/react";

@inject('store', 'billStore')
@observer
class VendorPayLinkPayabli2 extends React.Component {

    constructor(props) {
        super(props);
        this.handleCategoryTextChangeMask = this.handleCategoryTextChangeMask.bind(this);
        this.renderSortBlocks = this.renderSortBlocks.bind(this);
        this.goToReceipt = this.goToReceipt.bind(this);
        this.handleCategoryQtySubstract = this.handleCategoryQtySubstract.bind(this);
        this.handleCategoryQtyAdd = this.handleCategoryQtyAdd.bind(this);
        this.makePayment = this.makePayment.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleAutopayFrequency = this.handleAutopayFrequency.bind(this);
        this.handleAutopayEndDate = this.handleAutopayEndDate.bind(this);
        this.handleTextPayorFieldsChange = this.handleTextPayorFieldsChange.bind(this);
        this.setPaymentMethod = this.setPaymentMethod.bind(this);
        this.handleCategoryOptionalPay = this.handleCategoryOptionalPay.bind(this);
        this.handleAutopay = this.handleAutopay.bind(this);
        this.closeInfoModal = this.closeInfoModal.bind(this);
        this.openInfoModal = this.openInfoModal.bind(this);
        this.handleAutopayStartDate = this.handleAutopayStartDate.bind(this);
        this.handleAutopayCalendarEndDate = this.handleAutopayCalendarEndDate.bind(this);
        this.handleAutopayEndDateAction = this.handleAutopayEndDateAction.bind(this);
        this.updateCredentialsPermissionsUI = this.updateCredentialsPermissionsUI.bind(this);
        this.focus = this.focus.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.openConfirmModal = this.openConfirmModal.bind(this);
        this.reviewPayment = this.reviewPayment.bind(this);
        this.handleTextChangeValidationCode = this.handleTextChangeValidationCode.bind(this);
        this.viewInvoiceDetail = this.viewInvoiceDetail.bind(this);
        this.printPage = this.printPage.bind(this);
        this.endDateAutopayCalendar = React.createRef();
        this.handleItemUpValueInput = this.handleItemUpValueInput.bind(this);
        this.handleItemQtySubstract = this.handleItemQtySubstract.bind(this);
        this.handleItemQtyAdd = this.handleItemQtyAdd.bind(this);
        this.handleItemQtyInput = this.handleItemQtyInput.bind(this);
        this.handleItemQtyInputWithoutZero = this.handleItemQtyInputWithoutZero.bind(this);
        this.showVcardModal = this.showVcardModal.bind(this);
        this.closeVcardModal = this.closeVcardModal.bind(this);
        this.selectHowVerify = this.selectHowVerify.bind(this);
        this.handleTextChangeMask = this.handleTextChangeMask.bind(this);
        this.showVcardModalImage = this.showVcardModalImage.bind(this);
        this.closeVcardModalImage = this.closeVcardModalImage.bind(this);
        this.setPhoneError = this.setPhoneError.bind(this);

        this.state = {
            infoModalIsOpen: true,
            infoMessageInModal: '',
            phoneError: false,
            infoModalReload: false,
            inputQtyError: false,
            invoiceDetailsOpen: false,
            selectVcardModeModalOpen: false,
            howToVerify: "",
            pinNumber: "",
            vcardImageModalOpen: false
        };
    }
    setPhoneError(error){
        this.setState({phoneError: error});
    }

    closeInfoModal(){
        this.setState({ infoModalIsOpen: true });

        if(this.state.infoModalReload === true){
            window.location.reload();
        }
    }

    selectHowVerify(e){
        this.setState({howToVerify:e.target.value})
    }
    
    showVcardModal(){
        this.setPaymentMethod('vcard',"2");
        this.setState({
            selectVcardModeModalOpen: true,
            howToVerify: "",
            pinNumber: ""
        });
    }

    showVcardModalImage(){
        this.closeVcardModal();
        this.setState({
            vcardImageModalOpen: true
        });
    }
    
    closeVcardModalImage(){
        this.setState({
            vcardImageModalOpen: false
        });
    }
    
    closeVcardModal(){
        this.setState({selectVcardModeModalOpen: false});
    }

    closeConfirmModal(info){
        this.setState({ confirmModalIsOpen: false });
    }

    openConfirmModal(info){
        this.setState({ confirmModalIsOpen: true });
    }

    viewInvoiceDetail(){
        this.setState({invoiceDetailsOpen: !this.state.invoiceDetailsOpen});
    }
    
    printPage(){
        window.print();

    }

    openInfoModal(info, reload){
        this.setState({ infoModalIsOpen: false, infoMessageInModal: info, infoModalReload : reload === true ? true : false });
    }

    goToReceipt() {
        this.props.history.push("/paymentlink/receipt/" + this.props.match.params.tokenid);
    }

    handleAutopay(event){
        event.preventDefault()
        this.props.billStore.handleAutopay();
        this.updateCredentialsPermissionsUI();
    }

    focus(e){
        e.target.focus();
        e.target.setSelectionRange(0, 1000000000);
    }

    reviewPayment(){
		this.props.store.setIsClickedPay(true);
        return;
        /*this.validateFields();
        if(this.props.billStore.getPaymentPageErrors.credentialsMaxMinTicketError){
             this.openInfoModal('Sorry, the amount to be paid is greater or less than what is allowed.');
             return;
         }
 
         if(this.props.billStore.totalAmount.netAmount === 0){
             this.openInfoModal('Sorry, the amount must be greater than $0.00');
             return;
         }
         else{
             if(!this.props.billStore.hasPaymentPageErrors()){
 
                 if(!this.props.billStore.isAllowedPaymentMethod()){
                     this.openInfoModal('Sorry, the selected payment method is not allowed.');
                     return;
                 }
                 this.openConfirmModal();
                
                 }
         }*/
    }

    makePayment(){
        if(this.props.billStore.getValidationCode() && this.validateFieldValidationCode()){
            return;
        }
        this.closeConfirmModal();

        let entry = this.props.match.params.entry;
        this.props.billStore.makePayment(entry)
        .then((response) => {
            this.goToReceipt();
        })
        .catch((reason) => {
            Sentry.captureException(reason);
            try{
                if(JSON.parse(reason.request.response).responseText){
                    this.openInfoModal(JSON.parse(reason.request.response).responseText , true);
                }
                else{
                    this.openInfoModal('Sorry, we have a problem processing your request. Try again later.', true);
                }
            }
            catch(e){
                Sentry.captureException(e);
                this.openInfoModal('Sorry, we have a problem processing your request. Try again later.', true);
            }
        });
    }

    validateFieldValidationCode(){
        let validators = this.props.billStore.validators;
        let validationCode = this.props.billStore.getValidationCodeInput;
        if(validators.isEmpty(validationCode) || validators.isMaxLength(250, validationCode))
        {
            this.props.billStore.setPaymentPageError("validationCode",true);
            return true;
        }
        else{
            this.props.billStore.setPaymentPageError("validationCode",false);
            return false;
        } 
    }

    validateFields(){
        
        var paymentPage = this.props.billStore.paymentPage;
        var validators = this.props.billStore.validators;
        var reactObj = this;

        // validating autopay fields
        if(this.props.billStore.isAutopay){
            if(validators.isEmpty(paymentPage.autopay.startDate) || validators.isMaxLength(250, paymentPage.autopay.startDate))
            {
                this.props.billStore.setPaymentPageError('autopayStartDateError',true);
            }
            else{
                this.props.billStore.setPaymentPageError('autopayStartDateError',false);
            }

            if(validators.isEmpty(paymentPage.autopay.frequencySelected) || validators.isMaxLength(250, paymentPage.autopay.frequencySelected))
            {
                this.props.billStore.setPaymentPageError('autopayFrequencyError',true);
            }
            else{
                this.props.billStore.setPaymentPageError('autopayFrequencyError',false);
            }

            if(paymentPage.autopay.frequencySelected !== 'onetime'){
                if(validators.isEmpty(paymentPage.autopay.finishSelected) || validators.isMaxLength(250, paymentPage.autopay.finishSelected))
                {
                    this.props.billStore.setPaymentPageError('autopayFinishError',true);
                }
                else{
                    this.props.billStore.setPaymentPageError('autopayFinishError',false);
                }
            }
            else{
                this.props.billStore.setPaymentPageError('autopayFinishError',false);
            }
        }
        else{
            this.props.billStore.setPaymentPageError('autopayStartDateError',false);
            this.props.billStore.setPaymentPageError('autopayFrequencyError',false);
            this.props.billStore.setPaymentPageError('autopayFinishError',false);
        }

         // validating notes
         if(paymentPage.notes.enabled){
            if(validators.isMaxLength(250, paymentPage.notes.value))
            {
                this.props.billStore.setPaymentPageError('notesError',true);
            }
            else{
                this.props.billStore.setPaymentPageError('notesError',false);
            }
         }

         // validating payor form
         if(paymentPage.payor.enabled){
            var payerFieldsSort =Object.entries(toJS(this.props.billStore.payerFields));
            payerFieldsSort.forEach(function (item, index) {
                if(item[1].required && item[1].display ){
                    if(validators.isEmpty(item[1].value) || validators.isMaxLength(1000, item[1].value) || (item[1].validation !== 'phone' && validators.stringValidator(item[1].validation, item[1].value)))
                    {
                        reactObj.props.store.setPaymentPageError([item[1].name],true)
                    }
                    else{
                        reactObj.props.store.setPaymentPageError([item[1].name],false)
                    } 
                }else if(!item[1].required && item[1].display){
                    if(item[1].value && (validators.isMaxLength(1000, item[1].value) || (item[1].validation !== 'phone' && validators.stringValidator(item[1].validation, item[1].value))))
                    {
                        reactObj.props.store.setPaymentPageError([item[1].name],true)
                    }
                    else{
                        reactObj.props.store.setPaymentPageError([item[1].name],false)
                    } 
                }
            });
        }

        // validating ach form
        if(this.props.billStore.getPaymentMethod === "ach"){

            this.props.billStore.setPaymentPageError('paymentMethodsCardNumberError',false);
            this.props.billStore.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
            this.props.billStore.setPaymentPageError('paymentMethodsCardCvvError',false);
            this.props.billStore.setPaymentPageError('paymentMethodsCardZipcodeError',false);
            this.props.billStore.setPaymentPageError('paymentMethodsCardHolderNameError',false);

            if(validators.isEmpty(paymentPage.paymentMethods.achAccountHolderName) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountHolderName))
            {
                this.props.billStore.setPaymentPageError('paymentMethodsAchAccountHolderNameError',true);
            }
            else{
                this.props.billStore.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achAccountType) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountType))
            {
                this.props.billStore.setPaymentPageError('paymentMethodsAchAccountTypeError',true);
            }
            else{
                this.props.billStore.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achRouting) || !this.props.billStore.isValidRouting(paymentPage.paymentMethods.achRouting) || validators.isMaxLength(9, paymentPage.paymentMethods.achRouting) || validators.stringValidator('routing', paymentPage.paymentMethods.achRouting))
            {
                this.props.billStore.setPaymentPageError('paymentMethodsAchRoutingError',true);
            }
            else{
                this.props.billStore.setPaymentPageError('paymentMethodsAchRoutingError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achAccount) ||  validators.stringValidator('numbers', paymentPage.paymentMethods.achAccount) || validators.isMinLength(4, paymentPage.paymentMethods.achAccount) || validators.isMaxLength(17, paymentPage.paymentMethods.achAccount))
            {
                this.props.billStore.setPaymentPageError('paymentMethodsAchAccountError',true);
            }
            else{
                this.props.billStore.setPaymentPageError('paymentMethodsAchAccountError',false);
            }
        }else if(this.props.billStore.getPaymentMethod === "card"){
            this.props.billStore.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            this.props.billStore.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            this.props.billStore.setPaymentPageError('paymentMethodsAchRoutingError',false);
            this.props.billStore.setPaymentPageError('paymentMethodsAchAccountError',false);

            if(validators.isEmpty(paymentPage.paymentMethods.cardNumber) || validators.isMaxLength(16, paymentPage.paymentMethods.cardNumber) || validators.stringValidator('card', paymentPage.paymentMethods.cardNumber) || !this.props.billStore.luhnCheck(paymentPage.paymentMethods.cardNumber))
            {
                this.props.billStore.setPaymentPageError('paymentMethodsCardNumberError',true);
            }
            else{
                this.props.billStore.setPaymentPageError('paymentMethodsCardNumberError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardExpirationDate) || validators.isMaxLength(4, paymentPage.paymentMethods.cardExpirationDate) || validators.stringValidator('exp', paymentPage.paymentMethods.cardExpirationDate))
            {
                this.props.billStore.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
            }
            else{
                let expDateYear = paymentPage.paymentMethods.cardExpirationDate.substr(-2);
                let expDateMonth = paymentPage.paymentMethods.cardExpirationDate.substr(0,2);
                let currentYear =new Date().getFullYear().toString().substr(-2);
                let currentMonth =new Date().getMonth().toString();

                if(parseInt(expDateYear) < parseInt(currentYear)){
                    this.props.billStore.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                }
                else{
                    this.props.billStore.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
                }


                if(((parseInt(expDateYear) === parseInt(currentYear)) && ((parseInt(expDateMonth) < parseInt(currentMonth)+1) || (parseInt(expDateMonth) > 12)))){
                    this.props.billStore.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                }
                
               
            }

            let ccType = this.props.billStore.creditCardType(paymentPage.paymentMethods.cardNumber);
            if(validators.isEmpty(paymentPage.paymentMethods.cardCvv) || (ccType === "american-express" ? validators.stringValidator('cvvamex', paymentPage.paymentMethods.cardCvv) : validators.stringValidator('cvv', paymentPage.paymentMethods.cardCvv)))
            {
                this.props.billStore.setPaymentPageError('paymentMethodsCardCvvError',true);
            }
            else{
                this.props.billStore.setPaymentPageError('paymentMethodsCardCvvError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardZipcode) || validators.isMaxLength(7, paymentPage.paymentMethods.cardZipcode) || validators.stringValidator('zipcode', paymentPage.paymentMethods.cardZipcode))
            {
                this.props.billStore.setPaymentPageError('paymentMethodsCardZipcodeError',true);
            }
            else{
                this.props.billStore.setPaymentPageError('paymentMethodsCardZipcodeError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.cardHolderName) || validators.stringValidator('alpha', paymentPage.paymentMethods.cardHolderName))
            {
                this.props.billStore.setPaymentPageError('paymentMethodsCardHolderNameError',true);
            }
            else{
                this.props.billStore.setPaymentPageError('paymentMethodsCardHolderNameError',false);
            }
        }


         // validating payment categories
         if(paymentPage.amount.enabled){
            var categoriesConfig = Object.entries(toJS(this.props.billStore.amountCategories));
            categoriesConfig.forEach(function (item, index) {
                if(!item[1].optionalPay && (validators.isEmpty(item[1].value) || parseFloat(item[1].value) === 0 || isNaN(item[1].value)) ){
                    reactObj.props.store.setPaymentPageError([item[1].name],true)
                }
                else{
                    reactObj.props.store.setPaymentPageError([item[1].name],false)
                }
            });

         }

         this.props.billStore.validateCredentialsMaxMinTicketError();

         setTimeout(function(){ 
            try{
            let inputs =  document.getElementsByClassName('input-error');
            if(inputs[0]){
                let objRect = inputs[0].getBoundingClientRect()
                window.scrollTo({
                    top: parseInt(objRect.top + window.scrollY - 120),
                    behavior: 'smooth'
                });
                inputs[0].focus();
            }}
            catch(e){
                console.log(e);
            }
         }, 200);
        

    }

    sortFields(inputArr){
        let len = inputArr.length;
        for (let i = 0; i < len ; i++) {
            for (let j = 0; j < len - 1 ; j++) {
                if (inputArr[j][1].order > inputArr[j + 1][1].order) {
                    let tmp = inputArr[j];
                    inputArr[j] = inputArr[j + 1];
                    inputArr[j + 1] = tmp;
                }
            }
        }
        return inputArr;
    }

    sortBlocks(inputArr){
        let len = inputArr.length;
        for (let i = 0; i < len ; i++) {
            for (let j = 0; j < len - 1 ; j++) {
                if (inputArr[j].order > inputArr[j + 1].order) {
                    let tmp = inputArr[j];
                    inputArr[j] = inputArr[j + 1];
                    inputArr[j + 1] = tmp;
                }
            }
        }
        return inputArr;
    }

    renderFields(){
        var reactObject = this;
        var loopData = [];
        var payerFieldsSort =this.sortFields(Object.entries(toJS(this.props.billStore.payerFields)));

        payerFieldsSort.forEach(function (item, index) {
            loopData.push(
            item[1].display && (
                <div key={"keyPayer"+item[0]}  className={'col-sm-'+item[1].width}>
                    {item[1].validation === 'phone' &&
                        <PhoneInput iReadonly={item[1].fixedInjected === true ? true : false} iRequired={item[1].required} iValue={ item[1].value } iSetError={reactObject.setPhoneError} iCustomClass="inner-addon" iTitle={item[1].label} iMask="phone" iOnChange={(mask, value) => reactObject.handleTextPayorFieldsChange(item[0], value)} iName={'payorFieldInput' + item[0]} full/>
                    }
                    {item[1].validation !== 'phone' &&
                        <>
                            <div className="form-floating mb-3">
                                <IMaskInput
                                    mask={reactObject.props.store.maskValidator(item[1].validation)}
                                    name={'payorFieldInput'+item[0]}
                                    value={item[1].value}
                                    unmask={true}
                                    onAccept={
                                        (value, mask) => reactObject.handleTextPayorFieldsChange(item[0],value)
                                    }
                                    placeholder= {item[1].label}
                                    className={ (reactObject.props.store.getPaymentPageErrors[item[1].name] && reactObject.props.store.getPaymentPageErrors[item[1].name] === true )? "form-control input-error" : "form-control" }
                                    autoComplete="off"
                                    id={item[1].name}
                                    readOnly={ item[1].fixedInjected === true ? true : false }
                                />
                                <label htmlFor={'payorFieldInput'+item[0]}>{item[1].label}</label>
                            </div>
                        </>
                    }
                </div>
                )
            )
        });
        return loopData;
    }

    handleAutopayFrequency(event){
        this.props.billStore.handleAutopayFrequency(event);
    }

    handleTextChangeMask(e, value){
        this.setState({ [e]: value });
    }
    handleAutopayStartDate(date){
        let enddate = this.props.billStore.getAutopayEndDate();
        if(enddate){
            if(enddate <= date){
                enddate.setDate(date.getDate() + 1);
                this.handleAutopayEndDate(enddate);

            }
        }

        this.props.billStore.handleAutopayStartDate(date)
    }
    
    handleAutopayCalendarEndDate(date){
        this.props.billStore.handleAutopayCalendarEndDate(date)
    }
    
    handleCategoryOptionalPay(event){
        this.props.billStore.handleCategoryOptionalPay(event);
    }
    
    setPaymentMethod(method, activeKey){
        this.props.billStore.setPaymentMethod(method);
        if(activeKey!==null){
            this.props.billStore.setDefaultPaymentMethodActiveKey(activeKey);
        }
    }
    
    handleAutopayEndDate(event){
        this.props.billStore.handleAutopayEndDate(event);
    }
    
    handleAutopayEndDateAction(value){
        this.props.billStore.handleAutopayEndDateAction(value);
        this.endDateAutopayCalendar.current.setOpen(false)
    }

    handleCategoryTextChangeMask(field, key ,value) {
        this.props.billStore.handleCategoryTextChangeMask(field, key ,value);
    }

    handleTextChange(block, key ,event) {
        this.props.billStore.handleTextChange(block, key ,event);
    }
    
    handleTextPayorFieldsChange(key ,value) {
        this.props.billStore.handleTextPayorFieldsChange(key ,value);
    }

    handleTextChangeValidationCode(validationCode) {
        this.props.billStore.handleTextChangeValidationCodeInput(validationCode);
    }

    handleCategoryQtySubstract(event){
        this.props.billStore.handleCategoryQtySubstract(event);
    }

    handleCategoryQtyAdd(event){
        this.props.billStore.handleCategoryQtyAdd(event);
    }

    handleItemQtySubstract(event){
        this.props.billStore.handleItemQtySubstract(event);
    }

    handleItemQtyAdd(event){
        this.props.billStore.handleItemQtyAdd(event);
    }

    handleItemQtyInput(event){
        if(!this.state.inputQtyError){
            this.props.billStore.handleItemQtyInput(event);
        }
    }
    
    handleItemQtyInputWithoutZero(e) {
        if (e.target.value.length === 0) {
            this.setState({ inputQtyError: true });
        }else{
            this.setState({ inputQtyError: false });        
        }
    }

    handleItemUpValueInput(event, pos){
        if (event.value > 0) {
          this.props.billStore.handleInvoiceUpdateUpValueInput(event, pos);      
        }
    }

    renderAmountCategories(){
        var loopData = [];
        var categoriesConfigSort =this.sortFields(Object.entries(toJS(this.props.billStore.amountCategories)));
        var categoriesCount = categoriesConfigSort.length;
        var reactObject = this;
        categoriesConfigSort.forEach(function (item, index) {
            if(categoriesCount > 1) {
            
                loopData.push(
                    <li key={"keyAmountCategory"+item[0]} className="ui-state-default dragable-panel" id={"blockcategory"+item[0]} style={{position:"relative"}}>
                        <div className="form-floating form-floating-money">
                            <NumberFormat
                                onFocus={(e)=>reactObject.focus(e)} 
                                thousandsGroupStyle="thousand"
                                decimalSeparator="."
                                displayType="input"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={false}
                                decimalScale={2}
                                fixedDecimalScale={true}

                                value={item[1].value}
                                placeholder={item[1].label}
                                className={ (reactObject.props.store.getPaymentPageErrors[item[1].name]) ? "form-control input-money input-error": "form-control input-money" }
                                onValueChange={(values) => reactObject.handleCategoryTextChangeMask(item[0],"value", values.value)}
                                readOnly={ (item[1].type === 'fixed' || item[1].type === 'quantity') ? true : false }
                            />
                            <label>{item[1].label}</label>
                        </div>
                        { item[1].optionalPay  &&
                        <div className="icheck-primary optional-pay">
                            <input type="checkbox" id={"amountCategoryOptionalPay"+item[0]} onChange={(e) => reactObject.handleCategoryOptionalPay(e)} />
                            <label htmlFor={"amountCategoryOptionalPay"+item[0]}></label>
                        </div>
                        }

                        { item[1].type === 'quantity'  &&
                        <div className={ item[1].optionalPay ? 'input-group input-group-qty input-group-qty-mr' : 'input-group input-group-qty' } >
                            <button id={"amountCategoryQtySubstract"+item[0]} className="btn btn-outline-light btn-sm" type="button" onClick={(e) => reactObject.handleCategoryQtySubstract(e)}>-</button>
                            <input type="text" className="form-control" placeholder="" aria-label="Example text with button addon" readOnly value={item[1].quantity}/>
                            <button id={"amountCategoryQtyAdd"+item[0]} className="btn btn-outline-light btn-sm" type="button" onClick={(e) => reactObject.handleCategoryQtyAdd(e)}>+</button>
                        </div>
                        }

                        { (item[1].description && item[1].showDescription)  ? <p className="small-grey-m0 mt-1">{item[1].description}</p> : ''}

                    </li>
                )
            }else{
                loopData.push(
                    <li key={"keyAmountCategory"+item[0]} className="ui-state-default dragable-panel" id={"blockcategory"+item[0]}>
                    <NumberFormat
                        onFocus={(e)=>reactObject.focus(e)} 
                        thousandsGroupStyle="thousand"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        type="text"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}

                        value={item[1].value}
                        placeholder={"$"}
                        className="big-amount"
                        onValueChange={(values) => reactObject.handleCategoryTextChangeMask(item[0],"value", values.value)}
                        readOnly={(item[1].type === 'fixed' || item[1].type === 'quantity') ? true : false }
                    />
                     </li>
                )
            }
        });
        
        return loopData;
    }

    componentDidMount() {
        //this.props.billStore.setCurrentPage('payment-page');
        //let tokenid = this.props.match.params.tokenid;
        /*if (tokenid) {
            this.props.billStore.getPaymentPageLinkFromApi(tokenid).then(res => {
                this.updateCredentialsPermissionsUI();
            })
            .catch(error => {
                if(error.response && error.response.status && error.response.status === 400){
                    this.props.history.push("/paymentlink/paid");
                }else{
                    this.props.history.push("/");
                }
                    
            });
        }
        else{
           this.props.history.push("/");
        }

        this.setState({infoModalReload : false});*/
    }


    updateCredentialsPermissionsUI(){
        let credentialsPermissions = this.props.billStore.getCredentialsPermissions;
        if((credentialsPermissions.card.onetime && !this.props.billStore.isAutopay) || (credentialsPermissions.card.recurring && this.props.billStore.isAutopay)){
            this.props.billStore.setDefaultPaymentMethodActiveKey("0");
            this.setPaymentMethod('card',"0")
        }

        if((credentialsPermissions.ach.onetime && !this.props.billStore.isAutopay) || (credentialsPermissions.ach.recurring && this.props.billStore.isAutopay)){
            this.props.billStore.setDefaultPaymentMethodActiveKey("1");
            this.setPaymentMethod('ach', "1")
        }

        if(credentialsPermissions.ach.recurring || credentialsPermissions.card.recurring || credentialsPermissions.wallet.recurring || credentialsPermissions.cloud.recurring){
            this.props.billStore.setAutopay(this.props.billStore.isAutopay);
            //this.props.billStore.setAutopay(false);
        }
        else{
            this.props.billStore.setAutopay(false);
        }
    }

    renderSortBlocks(){

        let credentialsPermissions = this.props.billStore.getCredentialsPermissions;
        const btnTheme = {
            backgroundColor: this.props.billStore.paymentPageSettings2.colorPayabli,
        };

        const linkTheme = {
            color: this.props.billStore.paymentPageSettings2.colorPayabli,
        };

        const bgTheme = {
            backgroundColor: this.props.billStore.paymentPageSettings2.colorPayabli,
        };

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        
        let nextTwoDays = new Date();
        nextTwoDays.setDate(nextTwoDays.getDate() + 2);
        if(this.props.billStore.paymentPage2.autopay.startDate){
            nextTwoDays = new Date(this.props.billStore.paymentPage2.autopay.startDate);
            nextTwoDays.setDate(nextTwoDays.getDate() + 1);
        }
        

        const years = [];
        const yearsEndDate = [];
        const months = this.props.store.getMonths();
        let year = new Date().getFullYear();
        for (let i = year; i <= year + 2; i++) {
            years.push(i);
        }
        for (let i = year; i <= year + 20; i++) {
            yearsEndDate.push(i);
        }
        
        var blocksUI = [
                {
                    elements: <>{ this.props.billStore.paymentPage2.logo.enabled &&
                        <li className="ui-state-default dragable-panel" id="blocklogo" style={{paddingBottom: 0}}>
                        <div className="text-center mb-4">
                            {/*<PaymentPageLogo/>*/}
                            <img alt="" className="header-brand" src={payhoa} style={{maxWidth: "215px"}}/>
                        </div>
                        </li>
                        }</> ,
                    order: this.props.billStore.paymentPage2.logo.order ? this.props.billStore.paymentPage2.logo.order : 0
                },

                {
                elements: <> {this.props.billStore.paymentPage2.page.enabled && 
                    <li className="ui-state-default dragable-panel" id="blockpage" style={{paddingTop: 0}}>
                         <div className="row">
                             <div className="col-md-8 offset-md-2 progress-steps mb-3">
                                 <div className="row">
                                     <div style={bgTheme} className="col active">
                                         &nbsp;
                                     </div>
                                     <div className="col">
                                         &nbsp;
                                     </div>
                                 </div>
                             </div>
                         </div>
                         <div className="row">
                             <div className="col-12">
                                 <h3 className="card-title text-center">{this.props.billStore.paymentPage2.page.header ? this.props.billStore.paymentPage2.page.header : ''}</h3>
                                 <p className="small small-grey text-center m0" dangerouslySetInnerHTML={{__html: this.props.billStore.paymentPage2.page.description ? this.props.billStore.paymentPage2.page.description : '' }}></p>
                             </div>
                         </div>
                    </li>
                    }</> ,
                order: this.props.billStore.paymentPage2.page.order ? this.props.billStore.paymentPage2.page.order : 0
                },

                {
                elements:  <li className="ui-state-default dragable-panel" id="blockamount">
                <div className="payment-categories mb-1">
                    <ul className="sorted" ref={this.sortableCategories}>
                        {this.renderAmountCategories()}
                    </ul>
                </div>
                {this.props.billStore.switchAutopay &&
                    <>
                    { (this.props.billStore.isAutopay && (credentialsPermissions.ach.onetime || credentialsPermissions.card.onetime || credentialsPermissions.wallet.onetime || credentialsPermissions.cloud.onetime )) && 
                    <p className="text-center"><a style={linkTheme} href="/" onClick={(e) => this.handleAutopay(e)}>Click here</a> to make One-Time Payment</p>
                    }
                    { (!this.props.billStore.isAutopay && (credentialsPermissions.ach.recurring || credentialsPermissions.card.recurring || credentialsPermissions.wallet.recurring || credentialsPermissions.cloud.recurring )) &&
                    <p className="text-center"><a style={linkTheme} href="/" onClick={(e) => this.handleAutopay(e)}>Click here</a> to make an Autopay</p>
                    }
                    </>
                }
                </li>,
                order: this.props.billStore.paymentPage2.amount.order ? this.props.billStore.paymentPage2.amount.order : 0
                },

                {
                elements:  <>
                  {this.props.billStore.paymentPage2.autopay.enabled && 
                    <li className="ui-state-default dragable-panel" id="blockautopay">
                        <h6 className="sub-header mb-3">{this.props.billStore.paymentPage2.autopay.header ? this.props.billStore.paymentPage2.autopay.header : ''}</h6>                        
                        <div className="row">
                            <div className="col-sm mb-3">
                                <DatePicker
                                    customInput={
                                        <div className="form-floating">
                                            <input style={{backgroundColor: "#fff"}}  readOnly autoComplete="off" onChange={function () { }} value={this.props.billStore.paymentPage2.autopay.startDate ? this.props.billStore.dateStringFormat(this.props.billStore.paymentPage2.autopay.startDate) : ''}  name="startDateAutopayCalendar" id="startDateAutopayCalendar"  className={this.props.billStore.getPaymentPageErrors.autopayStartDateError ? "form-control input-calendar input-error input-lg" : "form-control input-calendar input-lg" } placeholder="Start Date" />
                                            <label htmlFor="startDateAutopayCalendar">Start Date</label>
                                        </div>
                                    }
                                    selected={this.props.billStore.paymentPage2.autopay.startDate ? this.props.billStore.paymentPage2.autopay.startDate : ''}
                                    onChange={date => this.handleAutopayStartDate(date)}
                                    minDate={tomorrow}
                                    dayClassName={date => "calendar-day"}
                                    popperPlacement="bottom-start"
                                    renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                        decreaseMonth,
                                        increaseMonth,
                                        prevMonthButtonDisabled,
                                        nextMonthButtonDisabled,
                                    }) => (
                                        <div
                                            style={{
                                                margin: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                {"<"}
                                            </button>
                                            <select
                                                value={date.getFullYear()}
                                                onChange={({ target: { value } }) => changeYear(value)}
                                            >
                                                {years.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <select
                                                value={months[date.getMonth()]}
                                                onChange={({ target: { value } }) =>
                                                    changeMonth(months.indexOf(value))
                                                }
                                            >
                                                {months.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>

                                            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                {">"}
                                            </button>
                                        </div>
                                    )}
                                />


                            </div>
                            <div className="col-sm">
                                <div className="form-floating mb-3">
                                <select value={this.props.billStore.paymentPage2.autopay.frequencySelected ? this.props.billStore.paymentPage2.autopay.frequencySelected : ''}  onChange={(e) => this.handleAutopayFrequency(e)} className={this.props.billStore.getPaymentPageErrors.autopayFrequencyError ? "form-select form-control input-error" : "form-select form-control" } id="floatingSelectGrid" aria-label="Frequency">
                                    <option defaultValue="select" value=""> Select... </option>
                                    { this.props.billStore.paymentPage2.autopay.frequency.onetime && ( <option value="onetime">One Time</option> )}
                                    { this.props.billStore.paymentPage2.autopay.frequency.weekly && ( <option value="weekly">Weekly</option> )}
                                    { this.props.billStore.paymentPage2.autopay.frequency.every2Weeks && ( <option  value="every2Weeks">Every 2 weeks</option> )}
                                    { this.props.billStore.paymentPage2.autopay.frequency.monthly && ( <option  value="monthly">Monthly</option> )}
                                    { this.props.billStore.paymentPage2.autopay.frequency.every3Months && ( <option  value="every3Months">Every 3 months</option> )}
                                    { this.props.billStore.paymentPage2.autopay.frequency.every6Months && ( <option  value="every6Months">Every 6 months</option> )}
                                    { this.props.billStore.paymentPage2.autopay.frequency.annually && ( <option  value="annually">Annually</option> )}
                                </select>
                                <label htmlFor="floatingSelectGrid">Frequency</label>
                                </div>
                            </div>
                            {this.props.billStore.paymentPage2.autopay.frequencySelected !== 'onetime' &&
                            <div className="col-sm">
                                { this.props.billStore.paymentPage2.autopay.finish.untilCancelled &&
                                <div className="form-floating mb-3">
                                    <select value={this.props.billStore.paymentPage2.autopay.finishSelected ? this.props.billStore.paymentPage2.autopay.finishSelected : ''}  onChange={(e) => this.handleAutopayEndDate(e)} className={this.props.billStore.getPaymentPageErrors.autopayFinishError ? "form-select form-control input-error" : "form-select form-control" } id="floatingSelectGrid" aria-label="End Date">
                                        <option value=""> Select... </option>
                                        <option value="untilcancelled">Until Cancelled</option>
                                    </select>
                                    <label htmlFor="floatingSelectGrid">End Date</label>
                                </div>
                                }

                                { this.props.billStore.paymentPage2.autopay.finish.calendar &&
                                    <>
                                    <DatePicker
                                        customInput={
                                            <div className="form-floating">
                                                <input style={{backgroundColor: "#fff"}} readOnly autoComplete="off" onChange={function () { }} value={this.props.billStore.paymentPage2.autopay.finishSelected && this.props.billStore.paymentPage2.autopay.finishSelected instanceof Date ? this.props.billStore.dateStringFormat(this.props.billStore.paymentPage2.autopay.finishSelected) : this.props.billStore.paymentPage2.autopay.finishSelected === 'untilcancelled' ? "Until Cancelled" : ''}  name="endDateAutopayCalendar" id="endDateAutopayCalendar" className={this.props.billStore.getPaymentPageErrors.autopayFinishError ? "form-control input-calendar input-error input-lg" : "form-control input-calendar input-lg" } placeholder="End Date" />
                                                <label htmlFor="endDateAutopayCalendar">End Date</label>
                                            </div>
                                        }
                                        selected={ this.props.billStore.paymentPage2.autopay.finishSelected && this.props.billStore.paymentPage2.autopay.finishSelected instanceof Date ? this.props.billStore.paymentPage2.autopay.finishSelected : ''}
                                        onChange={date => this.handleAutopayCalendarEndDate(date)}
                                        minDate={nextTwoDays}
                                        ref={this.endDateAutopayCalendar}
                                        dayClassName={date => "calendar-day"}
                                        popperPlacement="bottom-end"
                                        renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                        }) => (
                                            <div
                                                style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                    {"<"}
                                                </button>
                                                <select
                                                    value={date.getFullYear()}
                                                    onChange={({ target: { value } }) => changeYear(value)}
                                                >
                                                    {yearsEndDate.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <select
                                                    value={months[date.getMonth()]}
                                                    onChange={({ target: { value } }) =>
                                                        changeMonth(months.indexOf(value))
                                                    }
                                                >
                                                    {months.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                    {">"}
                                                </button>
                                            </div>
                                        )}
                                    >
                                        <div className="linkCalendar" onClick={(e)=>this.handleAutopayEndDateAction('untilcancelled')}>Until Cancelled</div>
                                    </DatePicker>

                                    
                                    </>
                                }

                            </div>
                            }
                        </div>
                    </li>
                    }</>,
                order: this.props.billStore.paymentPage2.autopay.order ? this.props.billStore.paymentPage2.autopay.order : 0
                },

                {
                elements:  <>{this.props.billStore.paymentPage2.payor.enabled && 
                    <li className="ui-state-default dragable-panel" id="blockpayer">           
                         <h6 className="sub-header mb-3">{ this.props.billStore.paymentPage2.payor.header ? this.props.billStore.paymentPage2.payor.header : ''}</h6>
                         <div className="row">
                             {this.renderFields()}
                         </div>
                    </li>
                    } </>,
                order: this.props.billStore.paymentPage2.payor.order
                },

                {
                elements:  <> { (this.props.billStore.paymentPage2.invoices && this.props.billStore.paymentPage2.invoices.enabled) &&
                    <li className="ui-state-default dragable-panel" id="blockinvoices">
                        <div className="small mb-2">
                            {this.props.billStore.paymentPage2.invoices.invoiceList.map((invoice, i) => (
                                <div key={i} >
                                <div className="row mb-2">
                                    <div className="offset-sm-2 col-sm-4 mb-2 text-center">
                                        <b>Invoice #</b><br/>
                                        {invoice.invoiceNumber}
                                    </div>
                                    <div className="col-sm-4 mb-2 text-center">
                                        <b>Due Date</b><br/>
                                        {this.props.store.stringDateFormat(invoice.invoiceDueDate)}
                                    </div>
                                </div>

                                <div className="text-center">
                                    <span onClick={(e) => this.viewInvoiceDetail(e)} style={linkTheme} className="cursor-pointer">{this.props.billStore.paymentPage2.invoices.viewInvoiceDetails.label} {this.state.invoiceDetailsOpen === false ? <BiChevronRight style={{fontSize: "18px"}} /> : <BiChevronDown style={{fontSize: "18px"}} /> }</span>
                                </div>
                                {this.state.invoiceDetailsOpen === true &&
                                <div>
                                    <div className="mb-3 text-right">
                                        <BiFile onClick={(e)=>this.props.store.downloadInvoice(e)} className="cursor-pointer mr-1" style={{fontSize: "16px"}}/>
                                        <BiPrinter onClick={(e)=>this.printPage(e)} className="cursor-pointer" style={{fontSize: "16px"}}/>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6"><b>Product Service</b></div>
                                        <div className="col-2 text-right"><b>Price</b></div>
                                        <div className="col-2 text-center"><b>Qty</b></div>
                                        <div className="col-2 text-right"><b>Amount</b></div>
                                    </div>

                                    {invoice.items.map((item, x) => (
                                        <div key={x} className="row top-border-grey-v2" style={{fontSize: '12px'}} >
                                            <div className="col-6" style={{marginTop: '2px'}}>{item.itemProductName ? item.itemProductName : "-"}</div>
                                            <div className="col-2 text-right" style={{marginTop: '2px'}}>
                                                ${ item.itemCost ? (this.props.store.numberWithCommas(parseFloat(item.itemCost).toFixed(this.props.store.formatDecimalDynamics(item.itemCost)))) : "0.00" }
                                            </div>   
                                            <div className="col-2 text-center" style={{marginTop: '2px'}}>
                                                { item.itemQty ? item.itemQty : "1" }
                                            </div>
                                            <div className="col-2 text-right" style={{marginTop: '2px'}}>
                                                ${ item.itemTotalAmount ? (this.props.store.numberWithCommas(parseFloat(item.itemTotalAmount).toFixed(this.props.store.formatDecimalDynamics(item.itemTotalAmount)))) : "0.00" }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                }
                            </div>
                            ))}
                        </div>
                      
                        
                    </li>
                    } </>,
                order: (this.props.billStore.paymentPage2.invoices && this.props.billStore.paymentPage2.invoices.order) ? this.props.billStore.paymentPage2.invoices.order : 0
                },

                {
                elements: <li className="ui-state-default dragable-panel" id="blockpaymentMethods">
                <h6 className="sub-header mb-3">{ this.props.billStore.paymentPage2.paymentMethods.header ? this.props.billStore.paymentPage2.paymentMethods.header : ''}</h6>
                
               
                
                <Accordion activeKey={this.props.billStore.defaultPaymentMethodActiveKey}>

                    <Card className="card card-in mb-3 card-hover">
                        <Accordion.Toggle as={Card.Header} className="card-body" eventKey="2" onClick={(e) => this.showVcardModal()}>
                            <div className="row">
                                <div className="col-2 text-center">
                                    <img alt="Card" style={{width: '46px'}} className="grey-icon-v2" src={cardIcon}/>
                                </div>
                                <div className="col-10">
                                Virtual Pre Paid Card
                                <p className="small small-grey-m0">
                                Receive an emailed digital card
                                </p>
                                <div className="card-brands accordion-right-corner">
                                <img alt="" src={visa} />
                                </div>
                                </div>
                            </div>
                        </Accordion.Toggle>
                       
                    </Card>
                    
                { (this.props.billStore.hasCards && ((credentialsPermissions.card.onetime && !this.props.billStore.isAutopay) || (credentialsPermissions.card.recurring && this.props.billStore.isAutopay)))  && 
                    <Card className="card card-in mb-3 card-hover">
                        <Accordion.Toggle as={Card.Header} className="card-body" eventKey="0" onClick={(e) => this.setPaymentMethod('card',"0")}>
                            <div className="row">
                                <div className="col-2 text-center">
                                    <img alt="Card" style={{width: '46px'}} className="grey-icon-v2" src={cardIcon}/>
                                </div>
                                <div className="col-10">
                                Instant Funds to your Bank Account
                                <p className="small small-grey-m0">
                                Deposit using you ATM card
                                </p>
                                <div className="card-brands accordion-right-corner">
                                {/*<PaymentMethodsAccepted/>*/}
                                </div>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <PaymentMethodCCForm history={this.props.history} />
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    }
                    
                    { (this.props.billStore.hasECheck && ((credentialsPermissions.ach.onetime && !this.props.billStore.isAutopay) || (credentialsPermissions.ach.recurring && this.props.billStore.isAutopay))) && 
                    <Card className="card card-in mb-3 card-hover">
                        <Accordion.Toggle as={Card.Header}  className="card-body" eventKey="1" onClick={(e) => this.setPaymentMethod('ach', "1")}>
                            <div className="row">
                                <div className="col-2 text-center">
                                    <img alt="ACH" style={{width: '44px'}} className="grey-icon-v2" src={achIcon}/>
                                </div>
                                <div className="col-10">
                                    Bank Transfer (ACH)
                                    <p className="small small-grey-m0">
                                    Get paid to your bank account with an ACH transfer
                                    </p>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <PaymentMethodECheck history={this.props.history} />
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    }

                    <Card className="card card-in mb-3 card-hover">
                        <Accordion.Toggle as={Card.Header} className="card-body" eventKey="3" onClick={(e) => this.setPaymentMethod('digitalcheck',"3")}>
                            <div className="row">
                                <div className="col-2 text-center">
                                    <img alt="Card" style={{width: '31px', marginTop: "8px"}} className="grey-icon-v2" src={check}/>
                                </div>
                                <div className="col-10">
                                Digital check
                                <p className="small small-grey-m0">
                                Receive a digital check to your email on file
                                </p>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <div className="row">
                                <div className="col-2">
                                
                                </div>
                                <div className="col-10">
                                    <div className="form-floating">
                                        <input readOnly autoComplete="off" value={"walt@disneyworld.com"} className={"form-control"} placeholder="emailedCheck" />
                                        <label htmlFor="emailedCheck">Remittance email on file</label>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    <Card className="card card-in mb-3 card-hover">
                        <Accordion.Toggle as={Card.Header} className="card-body" eventKey="4" onClick={(e) => this.setPaymentMethod('papercheck',"4")}>
                            <div className="row">
                                <div className="col-2 text-center">
                                    <img alt="Card" style={{width: '31px', marginTop: "8px"}} className="grey-icon-v2" src={check}/>
                                </div>
                                <div className="col-10">
                                Paper check
                                <p className="small small-grey-m0">
                                Receive a paper check to your mailing address on file
                                </p>
                                </div>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                        <Card.Body>
                            <div className="row">
                                <div className="col-2">
                                
                                </div>
                                <div className="col-10">
                                    <div className="form-floating">
                                        <input readOnly autoComplete="off" value={"1375 E Buena Vista Dr, Orlando, FL 32830"} className={"form-control"} placeholder="paperCheck" />
                                        <label htmlFor="paperCheck">Remittance address on file</label>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    
                    </Accordion>

                    

                    
                </li>,
                order: this.props.billStore.paymentPage2.paymentMethods.order ? this.props.billStore.paymentPage2.paymentMethods.order : 0
                },
                

                {
                elements:  <> { this.props.billStore.paymentPage2.notes.enabled &&
                    <li className="ui-state-default dragable-panel" id="blocknotes">
                         <div className="text-center small">
                         {this.props.billStore.paymentPage2.notes.value ? this.props.billStore.paymentPage2.notes.value : ''}
                         </div>
                    </li>
                    } </>,
                order: this.props.billStore.paymentPage2.notes.order ? this.props.billStore.paymentPage2.notes.order : 0
                },
                {
                elements:  <>{ this.props.billStore.paymentPage2.review.enabled && 
                    <li className="ui-state-default dragable-panel" id="blockreview">
                        <div className="review-total">
                            <h6 className="sub-header-line mb-3">{ this.props.billStore.paymentPage2.review.header ? this.props.billStore.paymentPage2.review.header : ''}</h6>
                            <div className="row">
                            <div className="col">Net Amount:</div>
                            <div className="col">${this.props.store.numberWithCommas(parseFloat(this.props.billStore.totalAmount['netAmount']).toFixed(2))}</div>
                            </div>
                            <div className="row">
                            <div className="col">Fee:</div>
                            <div className="col">${this.props.store.numberWithCommas(parseFloat(this.props.billStore.totalAmount['fee']).toFixed(2))}</div>
                            </div>

                            <div className="row total-amount">
                            <div className="col">Total Amount:</div>
                            <div className="col">${this.props.store.numberWithCommas(parseFloat(this.props.billStore.totalAmount['totalAmount']).toFixed(2))}</div>
                            </div>
                        </div>
                    </li>
                     }</>,
                order: this.props.billStore.paymentPage2.review.order ? this.props.billStore.paymentPage2.review.order : 0
                },

                {
                elements:  <>{ this.props.billStore.paymentPage2.messageBeforePaying.enabled &&
                    <li className="ui-state-default dragable-panel text-center" id="blockmessageBeforePaying">
                         <p className="small" style={{margin:0}} dangerouslySetInnerHTML={{__html: this.props.billStore.paymentPage2.messageBeforePaying.label ? this.props.billStore.paymentPage2.messageBeforePaying.label : '' }}>
                         </p>
                    </li>
                     }</>,
                order: this.props.billStore.paymentPage2.messageBeforePaying.order ? this.props.billStore.paymentPage2.messageBeforePaying.order : 0
                },
    
                {
                elements:   <li className="ui-state-default dragable-panel" id="blockpaymentButton">
                    <div className="btn-group full-w">
                        <button style={btnTheme} type="button" onClick={this.reviewPayment} className="btn btn-success btn-lg">{this.props.billStore.paymentPage2.paymentButton.label} ${this.props.store.numberWithCommas(parseFloat(this.props.billStore.totalAmount['totalAmount']).toFixed(2))}</button>
                    </div>
                </li>,
                order: this.props.billStore.paymentPage2.paymentButton.order ? this.props.billStore.paymentPage2.paymentButton.order : 0
                },

                {
                elements:  <>{ this.props.billStore.paymentPage2.contactUs.enabled && 
                        <li className="ui-state-default dragable-panel" id="blockcontactUs">
                            <p className="small-grey-m0 text-center ">
                                <b>{this.props.billStore.paymentPage2.contactUs.header ? this.props.billStore.paymentPage2.contactUs.header : ''}</b>
                                <br/>
                                {this.props.billStore.paymentPage2.contactUs.emailLabel ? this.props.billStore.paymentPage2.contactUs.emailLabel : ''} | {this.props.billStore.paymentPage2.contactUs.phoneLabel ? this.props.billStore.paymentPage2.contactUs.phoneLabel : ''}
                            </p>
                        </li>
                        }
                </>,
                order: this.props.billStore.paymentPage2.contactUs.order ? this.props.billStore.paymentPage2.contactUs.order : 0
                },
                    
            ]

        return this.sortBlocks(blocksUI);
    }

    render() {
        const bgTheme = {
            backgroundColor: this.props.billStore.paymentPageSettings2.colorPayabli,
            height: "50%"
        };

        const linkTheme = {
            color: this.props.billStore.paymentPageSettings2.colorPayabli,
        };

        const btnTheme = {
            backgroundColor: this.props.billStore.paymentPageSettings2.colorPayabli,
            border: "none",
        };

       return (
           <div>
            
                <Modal style={{textAlign: "center"}} show={!this.state.infoModalIsOpen} onHide={this.closeInfoModal}  size="sm" centered>
                <Modal.Body>
                    <BiMessageSquareError className="icon-modal"/>
                    <h5>Info</h5>
                    <p className="small">{this.state.infoMessageInModal}</p>
                    <Button className="btn" style={btnTheme} onClick={this.closeInfoModal}>
                    Close
                    </Button>
                </Modal.Body>
                </Modal>

                <Modal show={this.state.confirmModalIsOpen} onHide={() => this.closeConfirmModal()}  size="md" centered >
                <Modal.Body className="popover-body">
                        <h6 className="sub-header-line mb-3">Payment Information</h6>
                        
                        <div className="row mb-3">
                            <div className="col-7">
                                { (this.props.billStore.getPaymentMethod.toLowerCase() === 'card' || this.props.billStore.getPaymentMethod.toLowerCase() === 'savedcard' ) ?
                                    this.props.billStore.getPaymentMethod.toLowerCase() === 'card' ?
                                    <>
                                    {this.props.store.getPaymethodImg(this.props.billStore.creditCardType(this.props.billStore.paymentPage2.paymentMethods.cardNumber))}
                                    &nbsp;&nbsp;&nbsp;{this.props.billStore.paymentPage2.paymentMethods.cardNumber ? this.props.billStore.maskedCardNumber(this.props.billStore.paymentPage2.paymentMethods.cardNumber, "v3"): ''}
                                    </>
                                    :
                                    <>
                                    {this.props.billStore.getCustomerSelected && this.props.billStore.paymentMethodSavedPosition && this.props.billStore.getCustomerSelected.StoredMethods && this.props.billStore.getCustomerSelected.StoredMethods[this.props.billStore.paymentMethodSavedPosition] ? this.props.store.getPaymethodImg(this.props.billStore.getCustomerSelected.StoredMethods[this.props.billStore.paymentMethodSavedPosition].Descriptor) : ''}
                                    &nbsp;&nbsp;&nbsp;
                                    {this.props.billStore.getCustomerSelected && this.props.billStore.paymentMethodSavedPosition && this.props.billStore.getCustomerSelected.StoredMethods && this.props.billStore.getCustomerSelected.StoredMethods[this.props.billStore.paymentMethodSavedPosition] ? this.props.billStore.maskedCardNumber(this.props.billStore.getCustomerSelected.StoredMethods[this.props.billStore.paymentMethodSavedPosition].MaskedAccount, "v3") : ''}
                                    </>
                             
                                :
                                    <>
                                    {this.props.store.getPaymethodImg()} &nbsp;&nbsp;&nbsp; Bank account / E-Check
                                    </>
                                }
                            </div>
                            <div className="col-5 text-right">
                                {!this.props.billStore.isAutopay ?
                                <small>
                                Paid on {this.props.store.stringDateFormat(new Date().toString())}
                                </small>
                                :
                                <small>
                                Next date {this.props.store.stringDateFormat(this.props.billStore.paymentPage2.autopay.startDate)}
                                </small>
                                }
                            </div>
                        </div>
                        
                        <div className="review-total">
                            <h6 className="sub-header-line mb-3">Review & Send Payment</h6>
                            <div className="row">
                            <div className="col">Net Amount:</div>
                            <div className="col">${this.props.store.numberWithCommas(parseFloat(this.props.billStore.totalAmount['netAmount']).toFixed(2))}</div>
                            </div>
                            <div className="row">
                            <div className="col">Fee:</div>
                            <div className="col">${this.props.store.numberWithCommas(parseFloat(this.props.billStore.totalAmount['fee']).toFixed(2))}</div>
                            </div>

                            <div className="row total-amount">
                            <div className="col">Total Amount:</div>
                            <div className="col">${this.props.store.numberWithCommas(parseFloat(this.props.billStore.totalAmount['totalAmount']).toFixed(2))}</div>
                            </div>
                        </div>
                        
                        {this.props.billStore.getValidationCode() && 
                        <>
                        <div className="mb-3 mt-3 text-center" style={{backgroundColor: "#e7e6e6cc", padding: "5px"}}>
                            <img style={{width: "70%"}} src={"data:image/png;base64, " + this.props.billStore.getValidationCode()} alt="" />
                        </div>
                        <div className="mb-4">
                        <div className="form-floating">
                            <input 
                                name="validationCode"
                                value={this.props.billStore.getValidationCodeInput}
                                placeholder="Enter validation Code"
                                className={this.props.billStore.getPaymentPageErrors.validationCode ? "form-control input-error" : "form-control" }
                                id="validationCode"
                                autoComplete="off"
                                onChange={(e)=>this.handleTextChangeValidationCode(e.target.value)}
                            />
                            <label htmlFor="validationCode" style={{padding:"8px .75rem"}}>Enter validation Code</label>
                        </div>
                        </div>
                        </>
                        }

                        <button type="button" onClick={(e) => this.makePayment()} className="btn btn-success btn-lg full-w">Pay ${this.props.store.numberWithCommas(parseFloat(this.props.billStore.totalAmount['totalAmount']).toFixed(2))}</button>
                        <div className="text-center mt-3">
                        <button className="btn btn-default" onClick={() => this.closeConfirmModal()}>Cancel</button>
                        </div>
                </Modal.Body>
                </Modal>

                <Modal style={{textAlign: "center"}} show={this.state.selectVcardModeModalOpen} onHide={this.closeVcardModal}  size="sm" centered>
                <Modal.Body>
                    <BiLockOpen className="icon-modal"/>
                    <h5>Verify</h5>
                    <p className="small">Please select how to verify yourself</p>
                    
                        <div className="row mb-3">
                            <div className="col-4">
                                <div className="icheck-primary">
                                    <input
                                        name="howVerify"
                                        type="radio"
                                        id="howToVerifyMfa"
                                        value="mfa"
                                        checked={this.state.howToVerify === "mfa" ? true : false}
                                        onChange={(e) => this.selectHowVerify(e)}
                                    />
                                    <label htmlFor="howToVerifyMfa">MFA</label>
                                </div>
                            </div>
                            <div className="col-8">
                                 <div className="icheck-primary">
                                    <input
                                        name="howToVerify"
                                        type="radio"
                                        id="howToVerifyPin"
                                        value="pin"
                                        checked={this.state.howToVerify === "pin" ? true : false}
                                        onChange={(e) => this.selectHowVerify(e)}
                                    />
                                    <label htmlFor="howToVerifyPin">PIN number</label>
                                </div>
                            </div>
                        </div>

                        {this.state.howToVerify === "pin" &&
                        <div className="mb-3">
                            <div className="form-floating">                                
                                <IMaskInput
                                    mask={this.props.store.maskValidator('numbers')}
                                    name="pinNumberInput"
                                    value={ this.state.pinNumber }
                                    unmask={true}
                                    onAccept={
                                        (value, mask) => this.handleTextChangeMask('pinNumber', value)
                                    }
                                    placeholder="PIN Number"
                                    className="form-control"
                                    id="pinNumberInput"
                                    maxLength={6}
                                />
                                <label htmlFor="pinNumberInput">PIN Number</label>
                            </div>
                        </div>
                        }


                        <div className="row">
                            <div className="col-6">
                                <button className="btn btn-light full-w" type="button" onClick={this.closeVcardModal}>
                                    Close
                                </button>
                            </div>
                           
                            <div className="col-6">
                                <Button disabled={(this.state.howToVerify === "mfa" || (this.state.howToVerify === "pin" && this.state.pinNumber.length === 6)) ? false : true} className="btn full-w" style={btnTheme} onClick={this.showVcardModalImage}>
                                    Verify
                                </Button>
                            </div>
                        </div>
                   
                </Modal.Body>
                </Modal>
                
                
                <Modal style={{textAlign: "center"}} show={this.state.vcardImageModalOpen} onHide={this.closeVcardModalImage}  size="md" centered>
                <Modal.Body>
                    <h5 className="mt-3">Hey, John Smith</h5>
                    <p style={{fontSize: "10px"}}>For security purposes, this page will disappear in 5 minutes. <br/>
                    Please see below your Virtual Commercial Prepaid Visa Card.
                    </p>
                    <h2 className="mb-3 mt-3">${this.props.store.numberWithCommas(parseFloat(this.props.billStore.totalAmount['totalAmount']).toFixed(2))}</h2>
                    <img className="mb-3" title="VCard" src={payhoaVcard} style={{width: "100%", maxWidth: "400px"}} alt=""/>
                    <button className="btn btn-light full-w" onClick={(e)=>this.closeVcardModalImage()}>Close</button>
                </Modal.Body>
                </Modal>



            <div className="bg-decorator" style={bgTheme}>&nbsp;</div>
           <div className="mt-body3">

           <div className="d-flex flex-column justify-content-center align-items-center">

               <div id="vterminal-container" className="card">
                   <div className="card-body  main-cont">
                       <ul className="sorted" ref={this.sortablePayer}>
                        { this.renderSortBlocks().map((block, i) => (
                            (<div key={i}>
                            {block.elements}
                            </div>)
                        ))}

                       </ul>
                   </div>

                   { this.props.billStore.paymentPage2.contactUs.paymentIcons && 
                   <div className="card-brands text-center bottom-grey mt-3">
                       <PaymentMethodsAccepted/>
                   </div>
                   }
               </div>
               <p className="small mt-5">Powered by  <a rel="noreferrer" style={linkTheme} target="_blank" href="https://www.payabli.com/" className="small no-underline">Payabli</a></p>

           </div>

           </div>
           {(this.props.store && this.props.billStore.paymentPageSettings2.customCssUrl) &&
            <Helmet>
            <link rel="stylesheet" href={this.props.billStore.paymentPageSettings2.customCssUrl}/>
            </Helmet>
            }
           </div>
       );
   }
}

export { VendorPayLinkPayabli2 };