import React from 'react';
import { inject, observer } from 'mobx-react';
import { MdRefresh } from 'react-icons/md';

@inject('store')
@observer
class Captcha extends React.Component {

    render() {
        const subdomain = this.props.subdomain;
		const entrypoint = this.props.entry;
        return (
            <>
            {(this.props.subdomain && this.props.entry) &&
            <div className="mb-3 mt-3 text-center" style={{backgroundColor: "#e7e6e6cc", padding: "5px"}}>
                <img style={{width: "70%"}} src={"data:image/png;base64, " + this.props.store.getValidationCode()} alt="" />
                <span className='refresh-icon' onClick={() => { this.props.store.handleRefreshValidationCode(subdomain, entrypoint) }}><MdRefresh /></span>
            </div>
            }
            </>
        );
    }
}

export { Captcha };